import { Config } from "../../node_modules/@cvx/cal"

const calConfig: Config = {
  autoSignIn: true,
  popupForLogin: false,
  cacheLocation: "localStorage",
  instance: "https://login.microsoftonline.com/",
  tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  clientId: "ebb0bada-7c6d-4156-8ff6-c705b50ff201",
  //redirectUri: "https://policy30dev.azure.chevron.com",
  redirectUri: "https://localhost:44300",
  oidcScopes: ["openid", "profile", "email","User.Read","offline_access"],
  graphScopes: ["GroupMember.Read.All", "Directory.Read.All", "Group.Read.All", "User.Read", "User.Read.All"]
}

export const environment = {
  calConfig: calConfig,
  production: false,
  sendExternalEmail:true,
  msGraphUrl: "https://graph.microsoft.com/v1.0/",
  //apiEndpoint: "https://plc30dev-api.azurewebsites.net/",
  apiEndpoint: "http://127.0.0.1:8000/",
  //webUrl: "https://policy30dev.azure.chevron.com"
  webUrl: "https://localhost:44300"
};
