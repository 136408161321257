import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { CalAngularService } from '@cvx/cal-angular';
import { User, UserDetails } from '../interfaces/User';
import { MsGraphService } from './ms-graph';
import { ADCoordinatorGroupName,
         COORDINATOR, IFCGOVERNANCE, IFCLEGAL, IFCMEMBER, IFCSECRETARY, IFCTAX, IFCTREASURY, REQUESTOR, REQUEST_ID, SV_USER_ROLE, IFCCOMPTROLLERS, ADRequestorGroupName, ADIFCLegalGroupName, ADIFCTreasuryGroupName, ADIFCTaxGroupName, ADIFCTaxGroupName2, ADIFCControllersGroupName, ADIFCGovernanceGroupName, ADIFCSecretaryGroupName, VIEWERS, ADMIN, 
         LOCALLEGAL,
         LOCALTREASURY,
         LOCALTAX,
         LOCALCOMPTROLLERS,
         LOCALIFCMEMBER} from 'src/app/constants/constants';
import { P30APIService } from './api';
import { Status } from '../interfaces/Statuses';
import { ProposalType } from '../interfaces/ProposalTypes';
import { UserRole } from '../interfaces/UserRole';
import { Proposal } from '../interfaces/Proposal';
import { LegalEntity } from '../interfaces/LegalEntities';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  proposalId!: string;
  requestId: any;
  proposalType: any;
  currentUser: any;
  currentUserPhoto: any;
  currentUserId: any;
  userRoles: UserRole[] = [];
  statuses: Status[] = [];
  user!: User;
  proposalTypes: ProposalType[] = [];
  aqGeneralDetailsForm: any;
  contactsForm: any;
  requestedForUser: UserDetails[] = [];
  ifcMember: string[] = [IFCLEGAL,IFCTREASURY,IFCTAX,IFCCOMPTROLLERS,IFCGOVERNANCE]
  token: any;
  roleTitle: any;
  listOfProposals: Proposal[] = [];
  legalEntities:LegalEntity[] = [];
  selectedLegalEntities:LegalEntity[] = [];
  allIFCConcured$ = new BehaviorSubject<number>(0);
  selectedSurvingLegalEntities:LegalEntity[] = [];

  constructor(private session: SessionStorageService, private authService: CalAngularService,
    private graph: MsGraphService,
    private apiService: P30APIService) { }


  allIFCConcuredStatus(state: number) {
    this.allIFCConcured$.next(state);
  }

  getAccessToken() {
    this.authService.getAADToken().subscribe(result => {
      this.session.setItem('token', JSON.parse(JSON.stringify(result)));
      while (this.session.getItem('token') === undefined) {
        this.session.setItem('token', JSON.parse(JSON.stringify(result)));
      }
    });
  }

  getAuthorizationToken(): Promise<boolean> {
    return new Promise<boolean>((resolved) => {
      this.authService.getAADToken().subscribe(result => {
        this.session.setItem('token', JSON.parse(JSON.stringify(result)));
        resolved(true);
      });
    });
  }


  setVarRequestId(tempProposalId: any) {
    let arrParts = tempProposalId.split("-");
    this.requestId = arrParts[2];
    this.session.setItem(REQUEST_ID, arrParts[2]);
  }

  callSearchUser(value: string, resolved:any) {
    this.graph.searchUser(value).subscribe(arr => {
      arr['value'].forEach((element: any) => {
        if (element.mail) {
          if (element.mail.includes('@chevron.com')) {
            if (this.requestedForUser.filter(e => e.mail === element.mail).length === 0) {
              this.requestedForUser.push({
                objectId: element.id,
                displayName: element.displayName,
                mail: element.mail,
                fullName: `${element.givenName} ${element.surname}`
              });
            }
          }
          resolved(true);
        }
      });
    });
  }

  async searchUser(value: string): Promise<UserDetails[]> {
    if (value) {
      this.requestedForUser = [];
      const result = await new Promise<boolean>((resolved) => {
        if(value.endsWith('@chevron.com')) {
          this.graph.getUserByEmail(value).subscribe(event => {
            if (this.requestedForUser.filter(e => e.mail === event.mail).length === 0) {
              this.requestedForUser.push({
                objectId: event.id,
                displayName: event.displayName,
                mail: event.mail,
                fullName: `${event.givenName} ${event.surname}`
              });
              resolved(true);
            }
          });
        } else if(this.hasWhiteSpace(value) || !this.hasWhiteSpace(value)) {
          this.callSearchUser(value,resolved);
        }

      }
      );
      switch (result) {
        case true: {
          return [... new Set(this.requestedForUser)];
        }
        case false: {
          return this.requestedForUser;
        }
      }
    } else {
      return this.requestedForUser;
    }
  }

  hasWhiteSpace(s: string) {
    return s.indexOf(' ') >= 0;
  }

  getStatuses() {
    this.apiService.getStatuses().subscribe({
      next: (out) => {
        this.statuses = [...out.body];
      },
      error: (err) => {
        console.log("Unable to retrieve statuses", err);
      },
      complete: () => {

      }
    });
  }
  getProposalTypes() {
    this.apiService.getProposalTypes().subscribe({
      next: (out) => {
        this.proposalTypes = [...out.body];
      },
      error: (err) => {
        console.log("Unable to retrieve proposal types", err);
      },
      complete: () => {
      }
    });
  }
  getUserRoles() {
    this.apiService.getAllUserRoles().subscribe({
      next: (out) => {
        this.userRoles = [...out.body];
      },
      error: (err) => {
        console.log("Unable to retrieve user roles", err);
      },
      complete: () => {
      }
    });
  }

  setUserRole(id: number): any {
    let role = this.userRoles.find(usr => usr.userRoleId == id);
    return this.session.setItem(SV_USER_ROLE, role);
  }

  getUserRoleTitle(roleTitle:any): any {
    switch(roleTitle){
      case REQUESTOR:{
        this.roleTitle = REQUESTOR;
        break;
      }
      case COORDINATOR:{
        this.roleTitle = COORDINATOR;
        break;
      }
      case IFCSECRETARY:{
        this.roleTitle = IFCSECRETARY;
        break;
      }
      case IFCLEGAL:{
        this.roleTitle = IFCMEMBER;
        break;
      }
      case IFCTREASURY:{
        this.roleTitle = IFCMEMBER;
        break;
      }
      case IFCTAX:{
        this.roleTitle = IFCMEMBER;
        break;
      }
      case IFCGOVERNANCE:{
        this.roleTitle = IFCMEMBER;
        break;
      }
      case IFCCOMPTROLLERS:{
        this.roleTitle = IFCMEMBER;
        break;
      }
      case VIEWERS:{
        this.roleTitle = VIEWERS;
        break;
      }
      case ADMIN:{
        this.roleTitle = ADMIN;
        break;
      }
      case ADMIN:{
        this.roleTitle = ADMIN;
        break;
      }
      case LOCALLEGAL:{
        this.roleTitle = LOCALIFCMEMBER;
        break;
      }
      case LOCALTREASURY:{
        this.roleTitle = LOCALIFCMEMBER;
        break;
      }
      case LOCALTAX:{
        this.roleTitle = LOCALIFCMEMBER;
        break;
      }
      case LOCALCOMPTROLLERS:{
        this.roleTitle = LOCALIFCMEMBER;
        break;
      }
      default:{
        break;
      }
    }
  }

  saveHistory(objectId:string,proposalId:string,action: string){
    this.apiService.getUser(objectId).subscribe(output =>{
      if(output.status==200){
        const data = {
          "user": `${output.body.firstName} ${output.body.lastName}`,
          "userEmail": output.body.emailAddress,
          "userRoleId": output.body.userRoleId,
          "action": action,
          "date": new Date().toLocaleString("en-US")
        }
        this.apiService.createHistory(proposalId,data).subscribe({
          next: (event)=>{

          },
          error: (err) => {
            console.log("Unable to save history",err);
          },
          complete: () => {

          }
        })
      }else{
        console.log("Unable to retrieve user information");
      }
    })
  }

  // get all groups name, associated with the project, to validate the user and assign the Role-ID
  getAllGroupsName() {
    // ADGroupName
    let listOfGroups = [
      ADRequestorGroupName,
      ADCoordinatorGroupName,
      ADIFCLegalGroupName,
      ADIFCTreasuryGroupName,
      ADIFCTaxGroupName,
      ADIFCTaxGroupName2,
      ADIFCControllersGroupName,
      ADIFCGovernanceGroupName,
      ADIFCSecretaryGroupName
    ];
    return listOfGroups;
  }

}
