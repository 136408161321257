import { Injectable } from "@angular/core"
import { EmailNotification, ProposalEvent } from "../interfaces/EmailNotification"
import { EMAIL_SUBJECT_SUBMIT_PROPOSAL, EMAIL_SUBJECT_PENDING_GOV_REVIEW, EMAIL_SUBJECT_CONCURED_IFC_NOT_REQUIRED, EMAIL_SUBJECT_DECLINE_PROPOSAL, EMAIL_SUBJECT_ALL_IFC_ROLES_CONCUR, EMAIL_SUBJECT_COMPLETE_PROPOSAL, EMAIL_SUBJECT_WITHDRAW, EMAIL_SUBJECT_CONCURED_IFC_REQUIRED, EMAIL_SUBJECT_CONCURRENCE_RESET, EMAIL_SUBJECT_SEND_REMINDER_EMAIL, EMAIL_SUBJECT_CONCURED_Local_IFC_REQUIRED, EMAIL_SUBJECT_ALL_Local_IFC_ROLES_CONCUR, LocalLegalGroupName, LocalComptrollersGroupName, LocalTaxGroupName, LocalTreasuryGroupName, IFCComptrollersGroupName, IFCTreasuryGroupName, IFCGovernanceGroupName, IFCLegalGroupName, IFCTaxGroupName } from "src/app/constants/constants"
import { P30APIService } from "./api"
import { HttpResponse } from "@angular/common/http"
import { Observable, map } from 'rxjs';
import { environment } from "src/environments/environment";
import { IFCGroupsDTO } from "../interfaces/ifcReviewers"

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  govEmailId = ''
  ifcSecretary = ''
  email_cc:any[] = [];

  constructor(private apiService: P30APIService) {
    this.get_reviewer_by_id(5);// 5 is IFC Governance
    this.get_ifc_secretary();
  }
 
  async sent_email_notification(proposalNotification: EmailNotification): Promise<Observable<HttpResponse<any>>> {
    let email_recipient = this.get_email_recipients(proposalNotification)
    await this.get_email_cc(proposalNotification)
    let email_subject = this.get_email_subject(proposalNotification)
    let email_body = this.get_email_body(proposalNotification)

    const data = {
      recipient: email_recipient,
      cc: this.email_cc,
      subject: email_subject,
      body: email_body
    }

    return this.apiService.sendEmailNotification(data).pipe(
      map(emailResponse => {
        return emailResponse;
      })
    );
  }


  get_email_subject(proposalNotification: EmailNotification) {
    let email_subject = ""
    if (proposalNotification.event == ProposalEvent.Submit_Proposal)
      email_subject = this.formatString(EMAIL_SUBJECT_SUBMIT_PROPOSAL, proposalNotification.proposalId, proposalNotification.title)
    if (proposalNotification.event == ProposalEvent.Return_To_Requestor)
      email_subject = this.formatString(EMAIL_SUBJECT_PENDING_GOV_REVIEW, proposalNotification.proposalId, proposalNotification.title)
    if (proposalNotification.event == ProposalEvent.Concur_IFC_Not_Required)
      email_subject = this.formatString(EMAIL_SUBJECT_CONCURED_IFC_NOT_REQUIRED, proposalNotification.proposalId, proposalNotification.title)
    if (proposalNotification.event == ProposalEvent.Concur_Local_IFC_Required)
      email_subject = this.formatString(EMAIL_SUBJECT_CONCURED_Local_IFC_REQUIRED, proposalNotification.proposalId, proposalNotification.title)
    if (proposalNotification.event == ProposalEvent.Concur_IFC_Required)
      email_subject = this.formatString(EMAIL_SUBJECT_CONCURED_IFC_REQUIRED, proposalNotification.proposalId, proposalNotification.title)
    if (proposalNotification.event == ProposalEvent.Concurrence_Reset)
      email_subject = this.formatString(EMAIL_SUBJECT_CONCURRENCE_RESET, proposalNotification.proposalId, proposalNotification.title)
    if (proposalNotification.event == ProposalEvent.Send_Reminder_Email)
      email_subject = this.formatString(EMAIL_SUBJECT_SEND_REMINDER_EMAIL, proposalNotification.proposalId, proposalNotification.title)
    if (proposalNotification.event == ProposalEvent.Decline_Proposal)
      email_subject = this.formatString(EMAIL_SUBJECT_DECLINE_PROPOSAL, proposalNotification.proposalId, proposalNotification.title)
    if (proposalNotification.event == ProposalEvent.All_IFC_Roles_Concur)
      email_subject = this.formatString(EMAIL_SUBJECT_ALL_IFC_ROLES_CONCUR, proposalNotification.proposalId, proposalNotification.title)
    if (proposalNotification.event == ProposalEvent.All_Local_IFC_Roles_Concur)
      email_subject = this.formatString(EMAIL_SUBJECT_ALL_Local_IFC_ROLES_CONCUR, proposalNotification.proposalId, proposalNotification.title)
    if (proposalNotification.event == ProposalEvent.Complete_Proposal)
      email_subject = this.formatString(EMAIL_SUBJECT_COMPLETE_PROPOSAL, proposalNotification.proposalId, proposalNotification.title)
    if (proposalNotification.event == ProposalEvent.Withdraw)
      email_subject = this.formatString(EMAIL_SUBJECT_WITHDRAW, proposalNotification.proposalId, proposalNotification.title)

    // Add for other proposal

    return email_subject
  }

  get_email_cc(proposalNotification: EmailNotification): Promise<void> { 
    return new Promise<void>((resolved) => {
      this.email_cc = [];
      let ifc_emails: string[] = [];
      let approvalsid: IFCGroupsDTO[] = [];
      this.apiService.getApprovals(proposalNotification.proposalId).subscribe({
        next: (result) => {
          if (result.body) {
            approvalsid = result.body;
            if (proposalNotification.event == ProposalEvent.Concur_Local_IFC_Required) {
              approvalsid = approvalsid?.filter(x => (x.type.toString().toLocaleLowerCase() == LocalLegalGroupName || x.type.toString().toLocaleLowerCase() == LocalComptrollersGroupName || x.type.toString().toLocaleLowerCase() == LocalTaxGroupName || x.type.toString().toLocaleLowerCase() == LocalTreasuryGroupName));
            }
            if (proposalNotification.event == ProposalEvent.Concur_IFC_Required) {
              approvalsid = approvalsid?.filter(x => (x.type.toString().toLocaleLowerCase() == IFCComptrollersGroupName || x.type.toString().toLocaleLowerCase() == IFCGovernanceGroupName || x.type.toString().toLocaleLowerCase() == IFCTreasuryGroupName || x.type.toString().toLocaleLowerCase() == IFCLegalGroupName || x.type.toString().toLocaleLowerCase() == IFCTaxGroupName));
            }
            for (let approval of approvalsid) {
              let approval_email: string;
              approval_email = approval.approverEmail;
              if (approval_email.includes(';')) {
              {
                  const indi_emails = approval_email.split(';');
                  for (let email of indi_emails) {
                    ifc_emails.push(email);
                  }
              }
            }
            else {
              console.log('approval_email', approval_email);
              ifc_emails.push(approval_email);
            }
          }
          
            if (environment.sendExternalEmail) {
              if (proposalNotification.event == ProposalEvent.Submit_Proposal) {
                this.email_cc.push(this.govEmailId)//Gov MailBox
                proposalNotification.requestedForEmail == proposalNotification.requestedByEmail ? this.email_cc.push(proposalNotification.requestedForEmail) : this.email_cc.push([proposalNotification.requestedByEmail, proposalNotification.requestedForEmail])
              }
              if (proposalNotification.event == ProposalEvent.Return_To_Requestor) {
                proposalNotification.coordinatorEmail.forEach(email => {
                  this.email_cc.push(email);
                });
                this.email_cc.push(this.govEmailId)
              }
              if (proposalNotification.event == ProposalEvent.Concur_IFC_Not_Required) {
                proposalNotification.coordinatorEmail.forEach(email => {
                  this.email_cc.push(email);
                });
                if (proposalNotification.additionalCcEmail) {
                  proposalNotification.additionalCcEmail.forEach(email => {
                    if(email != "")
                    {
                      this.email_cc.push(email);
                    }
                  });
                }
                this.email_cc.push(this.govEmailId);
              }
              if (proposalNotification.event == ProposalEvent.Concur_IFC_Required) {
               proposalNotification.coordinatorEmail.forEach(email => {
                this.email_cc.push(email);
              });
                this.email_cc.push(this.ifcSecretary);
                this.email_cc.push(this.govEmailId);
               
                this.email_cc.push(...ifc_emails);
              }
              if (proposalNotification.event == ProposalEvent.Send_Reminder_Email) {
                proposalNotification.coordinatorEmail.forEach(email => {
                  this.email_cc.push(email);
                });
                this.email_cc.push(this.govEmailId);
              }
              if (proposalNotification.event == ProposalEvent.Concurrence_Reset) {
                proposalNotification.coordinatorEmail.forEach(email => {
                  this.email_cc.push(email);
                });
                this.email_cc.push(this.govEmailId)
              }
              if (proposalNotification.event == ProposalEvent.Decline_Proposal) {
                this.email_cc.push(this.govEmailId);
                // add respective ifc user who declined
              }
              if (proposalNotification.event == ProposalEvent.All_IFC_Roles_Concur) {
                if (proposalNotification.additionalCcEmail) {
                  proposalNotification.additionalCcEmail.forEach(email => {
                    if(email != "")
                    {
                      this.email_cc.push(email);
                    }
                  });
                }
                this.email_cc.push(this.govEmailId);
                this.email_cc.push(this.ifcSecretary);
                this.email_cc.push(...ifc_emails);
              }
              if (proposalNotification.event == ProposalEvent.All_Local_IFC_Roles_Concur) {
                if (proposalNotification.additionalCcEmail) {
                  proposalNotification.additionalCcEmail.forEach(email => {
                    this.email_cc.push(email);
                  });
                }
                this.email_cc.push(this.govEmailId);
                this.email_cc.push(this.ifcSecretary);
                this.email_cc.push(...ifc_emails);
              }
              if (proposalNotification.event == ProposalEvent.Complete_Proposal) {
                if (proposalNotification.additionalCcEmail) {
                  proposalNotification.additionalCcEmail.forEach(email => {
                    if(email != "")
                    {
                      this.email_cc.push(email);
                    }
                  });
                }
                this.email_cc.push(this.govEmailId);
                this.email_cc.push(this.ifcSecretary);
                this.email_cc.push(...ifc_emails);
              }
              if (proposalNotification.event == ProposalEvent.Withdraw) {
                console.log('proposalNotification.additionalCcEmail', proposalNotification.additionalCcEmail);
                if (proposalNotification.additionalCcEmail) {
                  proposalNotification.additionalCcEmail.forEach(email => {
                    if(email != "")
                    {
                      this.email_cc.push(email);
                    }
                  });
                }
                this.email_cc.push(this.govEmailId);
                this.email_cc.push(this.ifcSecretary);
                this.email_cc.push(...ifc_emails);
              }
              // Add for other event types
            }
          }
        },
        error: (err) => {
          console.log("Unable to get approval emails ", err);
          resolved();
        },
        complete: () => {
          resolved();
        }
        
      })
    })
  }

  get_email_recipients(proposalNotification: EmailNotification) {
    let email_recipient: string[] = []
    if (environment.sendExternalEmail) {
      if (proposalNotification.event == ProposalEvent.Submit_Proposal)
      email_recipient.push(...proposalNotification.coordinatorEmail)  
      email_recipient.push(...[proposalNotification.requestedByEmail])
      if (proposalNotification.event == ProposalEvent.Return_To_Requestor)
        proposalNotification.requestedForEmail != proposalNotification.requestedByEmail ? email_recipient.push(...[proposalNotification.requestedByEmail, proposalNotification.requestedByEmail]) : email_recipient.push(proposalNotification.requestedByEmail)
      if (proposalNotification.event == ProposalEvent.Concur_IFC_Not_Required)
        proposalNotification.requestedForEmail != proposalNotification.requestedByEmail ? email_recipient.push(...[proposalNotification.requestedByEmail, proposalNotification.requestedByEmail]) : email_recipient.push(proposalNotification.requestedByEmail)
      if (proposalNotification.event == ProposalEvent.Concur_IFC_Required)
        // Need to update to send it to ifc members
        proposalNotification.requestedForEmail != proposalNotification.requestedByEmail ? email_recipient.push(...[proposalNotification.requestedByEmail, proposalNotification.requestedByEmail]) : email_recipient.push(proposalNotification.requestedByEmail)
      if (proposalNotification.event == ProposalEvent.Concur_Local_IFC_Required)
        // Need to update to send it to ifc members
        proposalNotification.requestedForEmail != proposalNotification.requestedByEmail ? email_recipient.push(...[proposalNotification.requestedByEmail, proposalNotification.requestedByEmail]) : email_recipient.push(proposalNotification.requestedByEmail)
      if (proposalNotification.event == ProposalEvent.Concurrence_Reset)
        // Need to update to send it to Respective IFC User who declined
        proposalNotification.requestedForEmail != proposalNotification.requestedByEmail ? email_recipient.push(...[proposalNotification.requestedByEmail, proposalNotification.requestedByEmail]) : email_recipient.push(proposalNotification.requestedByEmail)
      if (proposalNotification.event == ProposalEvent.Send_Reminder_Email)
        // Need to update to send it to IFC Mailbox Email (role specific)
        proposalNotification.requestedForEmail != proposalNotification.requestedByEmail ? email_recipient.push(...[proposalNotification.requestedByEmail, proposalNotification.requestedByEmail]) : email_recipient.push(proposalNotification.requestedByEmail)
      if (proposalNotification.event == ProposalEvent.Decline_Proposal)
        email_recipient.push(...proposalNotification.coordinatorEmail)
      if (proposalNotification.event == ProposalEvent.All_IFC_Roles_Concur)
        proposalNotification.requestedForEmail != proposalNotification.requestedByEmail ? email_recipient.push(...[proposalNotification.requestedByEmail, proposalNotification.requestedByEmail]) : email_recipient.push(proposalNotification.requestedByEmail)
      email_recipient.push(...proposalNotification.coordinatorEmail)
      if (proposalNotification.event == ProposalEvent.All_Local_IFC_Roles_Concur)
        proposalNotification.requestedForEmail != proposalNotification.requestedByEmail ? email_recipient.push(...[proposalNotification.requestedByEmail, proposalNotification.requestedByEmail]) : email_recipient.push(proposalNotification.requestedByEmail)
        email_recipient.push(...proposalNotification.coordinatorEmail)
      if (proposalNotification.event == ProposalEvent.Complete_Proposal)
        proposalNotification.requestedForEmail != proposalNotification.requestedByEmail ? email_recipient.push(...[proposalNotification.requestedByEmail, proposalNotification.requestedByEmail]) : email_recipient.push(proposalNotification.requestedByEmail)
      if (proposalNotification.event == ProposalEvent.Withdraw)
        proposalNotification.requestedForEmail != proposalNotification.requestedByEmail ? email_recipient.push(...[proposalNotification.requestedByEmail, proposalNotification.requestedByEmail]) : email_recipient.push(proposalNotification.requestedByEmail)
      // Need to add logic for IFC not required, required and concurrence reset
    }
    else {
      email_recipient.push(proposalNotification.requestedByEmail)
    }
    return email_recipient
  }

  get_email_body(proposalNotification: EmailNotification) {
    let html_tb_body = `<tr>
                    <td style="width:30%"><strong>Proposal Id:</strong></td>
                    <td>${proposalNotification.proposalId}</tdh>
                    </tr>
                    <tr>
                        <td><strong>Type:</strong></td>
                        <td>${proposalNotification.proposalType}</td>
                    </tr>
                    <tr>
                        <td><strong>Title:</string></td>
                        <td>${proposalNotification.title}</td>
                    </tr>
                    <tr>
                        <td><strong>Status:</strong></td>
                        <td>${proposalNotification.status}</td>
                    </tr>
                    <tr>
                        <td><strong>Business Segment:</strong></td>
                        <td>${proposalNotification.businessSegment}</td>
                    </tr>
                    <tr>
                        <td><strong>Region:</strong></td>
                        <td>${proposalNotification.region}</td>
                    </tr>
                    <tr>
                        <td><strong>Requested for:</strong></td>
                        <td>${proposalNotification.requestedFor}</td>
                    </tr>
                    <tr>
                        <td><strong>Coordinator:</strong></td>
                        <td>${proposalNotification.coordinator.join(', ')}</td>
                    </tr>`
    if (proposalNotification.event == ProposalEvent.Complete_Proposal)
      html_tb_body = `${html_tb_body}
                        <tr>
                            <td><strong>Completion Notes:</strong></td>
                            <td>${proposalNotification.completionNotes}</td>
                        </tr>`
    if (proposalNotification.event == ProposalEvent.Withdraw)
      html_tb_body = `${html_tb_body}
            <tr>
                <td><strong>Governance Comments:</strong></td>
                <td>${proposalNotification.ifcGovernanceComments}</td>
            </tr>`
    if (proposalNotification.event == ProposalEvent.Return_To_Requestor)
      html_tb_body = `${html_tb_body}
            <tr>
                <td><strong>Reason:</strong></td>
                <td>${proposalNotification.coordinatorNotes}</td>
            </tr>`
    if (proposalNotification.event == ProposalEvent.Decline_Proposal)
      html_tb_body = `${html_tb_body}
            <tr>
                <td><strong>IFC Comments:</strong></td>
                <td>${proposalNotification.ifcComments}</td>
            </tr>`
    let html_body = '';
    if (proposalNotification.event == ProposalEvent.Submit_Proposal) {
      html_body = `
            <p class="MsoNormal">
                A Policy 30 ${proposalNotification.proposalType} proposal has been submitted by  ${proposalNotification.requestedFor}. Click on <a href=${environment.webUrl}/${proposalNotification.proposalPathName}/${proposalNotification.proposalId}>${proposalNotification.proposalId}</a> to see more details.
            </p>
            <p class="MsoNormal"> <span style="text-align: center;">
            </span></p>
            <table style="width: 70%; border:1px solid rgb(63, 62, 62);">
                      ${html_tb_body}
            </table><br>
            <p> Notes: ${proposalNotification.notes}</p>`
    } else {
      html_body = `
            <p class="MsoNormal">
              An action was taken on Policy 30 ${proposalNotification.proposalType} proposal. Click on <a href=${environment.webUrl}/${proposalNotification.proposalPathName}/${proposalNotification.proposalId}>${proposalNotification.proposalId}</a> to see more details.
            </p>
            <p class="MsoNormal">
              Your concurrence is requested by ${proposalNotification.concurDate}.
            </p>
            <p class="MsoNormal"><span
             style="text-align: center;">
            </span></p>
            <table style="width: 70%; border:1px solid rgb(63, 62, 62);">
                      ${html_tb_body}
            </table>`
    }

    return html_body
  }

  get_reviewer_by_id(reviewerId: number) {
    // 5 is IFC Governance from Reviewer Look Up Table
    this.apiService.getReviewer(5).subscribe({
      next: (result) => {
        this.govEmailId = result.body.defaultEmail
      },
      error: (err) => {
        console.log("Unable to retrieve reviewer", err);

      },
      complete: () => { }
    })
  }
  get_ifc_secretary() {
    // 6 is IFC Secretary from Reviewer Look Up Table
    this.apiService.getReviewer(6).subscribe({
      next: (result) => {
        this.ifcSecretary = result.body.defaultEmail
      },
      error: (err) => {
        console.log("Unable to retrieve reviewer IFC Secretary", err);

      },
      complete: () => { }
    })
  }

  get_ifc_members(proposalId: string, email_cc: string[]) {
    // 6 is IFC Secretary from Reviewer Look Up Table
    this.apiService.getApprovals(proposalId).subscribe({
      next: (result) => {
        if (result.body) {
          for (let approval of result.body) {
            let approval_email: string;
            approval_email = approval.approverEmail;
            console.log('approval_email', approval_email);
            email_cc.push(approval_email);
          }
        }
      },
      error: (err) => {
        console.log("Unable to retrieve reviewer IFC members", err);

      },
      complete: () => { 

        console.log('get_ifc_members', email_cc); 
      }
    })
  }

  get_reviewers(email_cc: any[]) {
    // 6 is IFC Secretary from Reviewer Look Up Table
    this.apiService.getReviewers().subscribe({
      next: (result) => {
        if (result.body) {
          for (let reviewer of result.body) {
            email_cc.push(reviewer.defaultEmail);
          }
        }
      },
      error: (err) => {
        console.log("Unable to retrieve reviewers", err);

      },
      complete: () => { }
    })
  }

  formatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, val[index]);
    }
    return str;
  }
}
