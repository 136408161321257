export interface EmailNotification {
  proposalId: string;
  title: string;
  proposalType: string;
  proposalPathName:string;
  businessSegment: string;
  region: string;
  requestedFor: string;
  requestedForEmail: string;
  requestedBy?: string;
  requestedByEmail: string;
  coordinatorEmail: string[];
  coordinator: string[];
  additionalCcEmail?: string[];
  additionalCc?: string[];
  appDate?: string;
  status?: string;
  coordinatorNotes?: string;
  ifcComments?: string;
  completionNotes?: string;
  ifcGovernanceComments?: string;
  event?: string;
  notes?: string;
  concurDate? : any;
}

export interface EmailContent {
  recipient: string[];
  cc: string[];
  htmlBody: string;
  subjet: string;
}

export enum ProposalEvent {
  Submit_Proposal = 'Submit_Proposal',
  Return_To_Requestor = 'Return_To_Requestor',
  Concur_IFC_Not_Required = 'Concur_IFC_Not_Required',
  Concur_IFC_Required = 'Concur_IFC_Required',
  Concur_Local_IFC_Required = 'Concur_Local_IFC_Required',
  Decline_Proposal = 'Decline_Proposal',
  Concurrence_Reset = 'Concurrence_Reset',
  Send_Reminder_Email = 'Send_Reminder_Email',
  All_IFC_Roles_Concur = 'All_IFC_Roles_Concur',
  All_Local_IFC_Roles_Concur = 'All_Local_IFC_Roles_Concur',
  Complete_Proposal = 'Complete_Proposal',
  Withdraw = 'Withdraw',
}
