@if(!reviewComponent) {
<div class="local-reviewer-component"
  *ngIf="proposalStatus !== 'pending governance review' && userRoleTitle == 'coordinator'">
  <p><strong>Responses</strong></p>

  @if(showHideLIFCModal) {
  <div id="confirmationBox">
    <ng-container>
      <ng-template [ngTemplateOutlet]="confirmationModal"></ng-template>
    </ng-container>
  </div>
  }

  <!-- Local duedate concur by date -->
  <form nz-form [formGroup]="localSecretaryForm" nzLayout="vertical">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="14">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>Local IFC Reviewer Due By Date (Applies to All)</nz-form-label>
          <nz-form-control [@formControlAnimation] [nzErrorTip]="getErrorTipForDueDate('dueDate')">
            <nz-date-picker formControlName="dueDate" [(ngModel)]="localSecretaryFormModel.dueDate"
              nzPlaceHolder="mm/dd/yyyy" style="width: 100%" (ngModelChange)="selectDueByDate($event)"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="14" style="display: none;">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>Local IFC Reviewer Concur By Date (Applies to All)</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <nz-date-picker formControlName="concurDate" [(ngModel)]="localSecretaryFormModel.concurDate"
              nzPlaceHolder="mm/dd/yyyy" style="width: 100%"
              (ngModelChange)="selectConcurByDate($event)"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>

  <!-- Local Legal -->
  <form nz-form [formGroup]="localLegalForm" nzLayout="vertical">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="14" class="left-side-form">
        <!-- dropdown input form -->
        <div class="left-side-form-dropdown">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Local IFC Legal</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-select nzShowSearch
                         [nzServerSearch]="true" nzMode="multiple"
                         formControlName="localLegal" [(ngModel)]="localLegalFormModel.localLegal"
                         [nzSuffixIcon]="legalemplate" (nzOnSearch)="searchGroupMember($event, 'localLegal')"
                         (ngModelChange)="onGroupMemberChange($event)">
                <ng-container *ngFor="let data of localLegalDefaultMember">
                  <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
                </ng-container>
              </nz-select>

              <ng-template #legalemplate>
                <span nz-icon nzType="user" nzTheme="outline"></span>
              </ng-template>
              

            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- comments input field -->
        <div class="left-side-form-comments">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="legalComments"
                [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="7" nzOffset="1" class="right-side-action-buttons">
        <!-- Concurrence status -->
        <span> Concurrence: <br> {{legalConcurrence}} </span>
        <!-- Resend Request toggle button -->
        <span>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation] class="pb-0">Resend Request</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-switch formControlName="localLegalSwitch" (ngModelChange)="localLegalFormModel.localLegalSwitch"
                (ngModelChange)="resetApprovalToggle($event, 'localifclegal')"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </span>
        <!-- email icon -->
        <span>
          <nz-form-item>
            <a (click)="sendReminderEmail()"><span nz-icon nzType="mail" nzTheme="twotone" style="font-size: 2.5rem;"></span></a>
          </nz-form-item>
        </span>
      </div>
    </div>
  </form>

  <!-- Local Controllers -->
  <form nz-form [formGroup]="localControllersForm" nzLayout="vertical">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="14" class="left-side-form">
        <!-- dropdown input form -->
        <div class="left-side-form-dropdown">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Local IFC Controllers</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-select nzShowSearch 
              [nzServerSearch]="true" nzMode="multiple"
             formControlName="localControllers" [(ngModel)]="localControllersFormModel.localControllers"
              [nzSuffixIcon]="ControllersTemplate" (nzOnSearch)="searchGroupMember($event, 'localControllers')"
              (ngModelChange)="onGroupMemberChange($event)">
             <ng-container *ngFor="let data of localControllersDefaultMember">
                <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
              </ng-container>
            </nz-select>
            <ng-template #ControllersTemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>

            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- comments input field -->
        <div class="left-side-form-comments">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="controllersComments"
                [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="7" nzOffset="1" class="right-side-action-buttons">
        <!-- Concurrence status -->
        <span> Concurrence: <br> {{controllersConcurrence}} </span>
        <!-- Resend Request toggle button -->
        <span>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation] class="pb-0">Resend Request</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-switch formControlName="localControllersSwitch"
                (ngModelChange)="resetApprovalToggle($event, 'localifccontrollers')"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </span>
        <!-- email icon -->
        <span>
          <nz-form-item>
            <a (click)="sendReminderEmail()"><span nz-icon nzType="mail" nzTheme="twotone" style="font-size: 2.5rem;"></span></a>
          </nz-form-item>
        </span>
      </div>
    </div>
  </form>

  <!-- Local Tax -->
  <form nz-form [formGroup]="localTaxForm" nzLayout="vertical">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="14" class="left-side-form">
        <!-- dropdown input form -->
        <div class="left-side-form-dropdown">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Local IFC Tax</nz-form-label>
            <nz-form-control [@formControlAnimation]>

              <nz-select nzShowSearch 
              [nzServerSearch]="true" nzMode="multiple"
             formControlName="localTax" [(ngModel)]="localTaxFormModel.localTax"
              [nzSuffixIcon]="taxTemplate" (nzOnSearch)="searchGroupMember($event, 'localTax')"
              (ngModelChange)="onGroupMemberChange($event)">
             <ng-container *ngFor="let data of localTaxDefaultMember">
                <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
              </ng-container>
            </nz-select>
            <ng-template #taxTemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>

            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- comments input field -->
        <div class="left-side-form-comments">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="taxComments"
                [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="7" nzOffset="1" class="right-side-action-buttons">
        <!-- Concurrence status -->
        <span> Concurrence: <br> {{taxConcurrence}} </span>
        <!-- Resend Request toggle button -->
        <span>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation] class="pb-0">Resend Request</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-switch formControlName="localTaxSwitch"
                (ngModelChange)="resetApprovalToggle($event, 'localifctax')"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </span>
        <!-- email icon -->
        <span>
          <nz-form-item>
            <a (click)="sendReminderEmail()"><span nz-icon nzType="mail" nzTheme="twotone" style="font-size: 2.5rem;"></span></a>
          </nz-form-item>
        </span>
      </div>
    </div>
  </form>

  <!-- Local Treasury-->
  <form nz-form [formGroup]="localTreasuryForm" nzLayout="vertical">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="14" class="left-side-form">
        <div class="left-side-form-dropdown">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Local IFC Treasury</nz-form-label>
            <nz-form-control [@formControlAnimation]>

              <nz-select nzShowSearch 
              [nzServerSearch]="true" nzMode="multiple"
             formControlName="localTreasury" [(ngModel)]="localTreasuryFormModel.localTreasury"
              [nzSuffixIcon]="treasuryTemplate" (nzOnSearch)="searchGroupMember($event, 'localTreasury')"
              (ngModelChange)="onGroupMemberChange($event)">
             <ng-container *ngFor="let data of localTreasuryDefaultMember">
                <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
              </ng-container>
            </nz-select>
            <ng-template #treasuryTemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>

            </nz-form-control>
          </nz-form-item>
        </div>
        <div class="left-side-form-comments">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <textarea nz-input formControlName="treasuryComments"
                [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-col nzSpan="7" nzOffset="1" class="right-side-action-buttons">
        <span> Concurrence: <br> {{treasuryConcurrence}} </span>
        <span>
          <nz-form-item>
            <nz-form-label [@formLabelAnimation] class="pb-0">Resend Request</nz-form-label>
            <nz-form-control [@formControlAnimation]>
              <nz-switch formControlName="localTreasurySwitch"
                (ngModelChange)="resetApprovalToggle($event, 'localifctreasury')"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </span>
        <span>
          <nz-form-item>
            <a (click)="sendReminderEmail()"><span nz-icon nzType="mail" nzTheme="twotone" style="font-size: 2.5rem;"></span></a>
          </nz-form-item>
        </span>
      </div>
    </div>
  </form>

</div>

<!--  -->

<div *ngIf="proposalStatus == 'pending governance review' && userRoleTitle == 'coordinator'">
  <form nz-form [formGroup]="coordianatorLocalForm" nzLayout="vertical">
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="14">
        <nz-form-item>
          <nz-form-label nzRequired [@formLabelAnimation]>Local Due By Date (Applies to All)</nz-form-label>
          <nz-form-control [nzErrorTip]="getErrorTipForDueDate('dueDate')" [@formControlAnimation]>
            <nz-date-picker formControlName="dueDate" [(ngModel)]="coordianatorLocalFormModel.dueDate"
              nzPlaceHolder="mm/dd/yyyy" style="width: 100%" (ngModelChange)="selectDueByDate($event)"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="7" style="display: none;">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>Local Concur By Date (Applies to All)</nz-form-label>
          <nz-form-control [@formControlAnimation]>
            <nz-date-picker formControlName="concurDate" [(ngModel)]="coordianatorLocalFormModel.concurDate"
              nzPlaceHolder="mm/dd/yyyy" style="width: 100%"
              (ngModelChange)="selectConcurByDate($event)"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <!--  -->
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="7">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Local IFC Legal</nz-form-label>
          <nz-form-control [nzErrorTip]="getErrorTip('localLegalForm','localLegal')" [@formControlAnimation]>
            <nz-select nzShowSearch nzPlaceHolder="Select one or more local legal" [nzServerSearch]="true" nzMode="multiple"
             formControlName="localLegal" [(ngModel)]="coordianatorLocalFormModel.localLegal"
              [nzSuffixIcon]="legalemplate" (nzOnSearch)="searchGroupMember($event, 'localLegal')"
              (ngModelChange)="onGroupMemberChange($event)">
             <ng-container *ngFor="let data of localLegalDefaultMember">
                <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
              </ng-container>
            </nz-select>
            <ng-template #legalemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="7">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Local IFC Controllers</nz-form-label>
          <nz-form-control [nzErrorTip]="getErrorTip('localControllersForm','localControllers')" [@formControlAnimation]>
            <nz-select nzShowSearch nzPlaceHolder="Select one or more local controllers" [nzServerSearch]="true" nzMode="multiple"
             formControlName="localControllers" [(ngModel)]="coordianatorLocalFormModel.localControllers"
              [nzSuffixIcon]="ControllersTemplate" (nzOnSearch)="searchGroupMember($event, 'localControllers')"
              (ngModelChange)="onGroupMemberChange($event)">
             <ng-container *ngFor="let data of localControllersDefaultMember">
                <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
              </ng-container>
            </nz-select>
            <ng-template #ControllersTemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <!--  -->
    <div nz-row nzGutter="8">
      <div nz-col nzSpan="7">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Local IFC Tax</nz-form-label>
          <nz-form-control [nzErrorTip]="getErrorTip('localTaxForm','localTax')" [@formControlAnimation]>
            <nz-select nzShowSearch nzPlaceHolder="Select one or more local tax" [nzServerSearch]="true" nzMode="multiple"
             formControlName="localTax" [(ngModel)]="coordianatorLocalFormModel.localTax"
              [nzSuffixIcon]="taxTemplate" (nzOnSearch)="searchGroupMember($event, 'localTax')"
              (ngModelChange)="onGroupMemberChange($event)">
             <ng-container *ngFor="let data of localTaxDefaultMember">
                <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
              </ng-container>
            </nz-select>
            <ng-template #taxTemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="7">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Local IFC Treasury</nz-form-label>
          <nz-form-control [nzErrorTip]="getErrorTip('localTreasuryForm','localTreasury')" [@formControlAnimation]>
            <nz-select nzShowSearch nzPlaceHolder="Select one or more local treasury" [nzServerSearch]="true" nzMode="multiple"
            formControlName="localTreasury" [(ngModel)]="coordianatorLocalFormModel.localTreasury"
             [nzSuffixIcon]="treasuryTemplate" (nzOnSearch)="searchGroupMember($event, 'localTreasury')"
             (ngModelChange)="onGroupMemberChange($event)">
            <ng-container *ngFor="let data of localTreasuryDefaultMember">
               <nz-option [nzValue]="data.defaultEmail" [nzLabel]="data.defaultEmail"></nz-option>
             </ng-container>
           </nz-select>
            <ng-template #treasuryTemplate>
              <span nz-icon nzType="user" nzTheme="outline"></span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <!--  -->
  </form>
</div>
}

@if(reviewComponent) {
<div>
  <nz-table #basicTable [nzData]="approvalsRecords" [ngClass]="reviewComponent ? 'reviewPageBlurTable' : ''"
    [nzShowPagination]="approvalsRecords.length > 10 ? true : false">
    <thead>
      <tr>
        <th scope="col">Local IFC ReviewerType</th>
        <th scope="col">Approver</th>
        <th scope="col">Concur Date</th>
        <th scope="col">Due Date</th>
        <th scope="col">Concurrence</th>
        <th scope="col">Comment</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of approvalsRecords">
        <td>{{ data?.type }}</td>
        <td>{{ data?.fullName }}</td>
        <td>{{ data?.concurDate | date:'shortDate' }}</td>
        <td>{{ data?.dueDate | date:'shortDate' }}</td>
        <td>{{ data?.status == true ? 'Concurred' : data?.status == false ? 'Declined':'Pending' }}</td>
        <td>{{ data?.comment }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>
}

<ng-template #confirmationModal>
  <nz-alert nzShowIcon nzType="info" [nzMessage]="confirmationModalHeading" [nzDescription]="descriptionTemplate"
    [nzAction]="actionTemplate"></nz-alert>
  <ng-template #descriptionTemplate>
    <p> {{confirmationModalContent}} </p>
  </ng-template>
  <ng-template #actionTemplate>
    <nz-space nzDirection="horizontal">
      <button nz-button nzType="primary" (click)="getConfirmation('yes')" class="mr-2">
        <span>Yes</span>
      </button>
      <button nz-button nzType="default" (click)="getConfirmation('no')">
        <span>No</span>
      </button>
    </nz-space>
  </ng-template>
</ng-template>
