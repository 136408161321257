import { Component, Input } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { PROPOSAL_ID, REQUEST_ID, SV_PROPOSAL_STATUS,SV_USER_ROLE, SV_USER } from 'src/app/constants/constants';
import { Attachment } from 'src/app/core/interfaces/Attachment';
import { AcquisitionEntityQuestion } from 'src/app/core/interfaces/EntityIndicatorQuestion';
import { UserRole } from 'src/app/core/interfaces/UserRole';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceGeneralDetails } from 'src/app/core/services/data_service_general_details';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataServiceComments } from 'src/app/core/services/data_service_comments';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { DataServiceContacts } from 'src/app/core/services/data_service_contacts';

@Component({
  selector: 'app-dissolution-proposal-review',
  templateUrl: './dissolution-proposal-review.component.html',
  styleUrls: ['./dissolution-proposal-review.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class DissolutionProposalReviewComponent {
  collapseIcon: string = 'double-right';
  generalDetailsForm!: UntypedFormGroup;
  requestedForUser!: string;
  coordinators: string[] = [];
  additionalCcs: string[] = [];
  businessSegmentUnit!: string;
  region!: string;
  @Input() acquisitionProposalOverviewForm!: UntypedFormGroup;
  @Input() acquisitionPropossedSlateForm!: UntypedFormGroup;
  @Input() acquisitionAssetForm!: UntypedFormGroup;
  @Input() acquisitionOwnershipInterestForm!: UntypedFormGroup;
  @Input() acquisitionEntityQuestionsForm!: UntypedFormGroup;
  @Input() acquisitionEntitySelectionForm!: UntypedFormGroup;
  @Input() dissolutionEntitySelectionForm!: UntypedFormGroup;
  @Input() contactsForm!: UntypedFormGroup;
  @Input() attachmentTabClass = 'tab-default';
  @Input() attachmentUploadClass="";
  @Input() reviewComponent="";
  attachments: string = "attachments";
  proposalId!: string;
  @Input() dissolutionProposalAttachmentForm!: UntypedFormGroup;
  entityQuestions: AcquisitionEntityQuestion[] = [];
  showAsset: boolean = false;
  showOwnsershipInterest: boolean = false;
  showLegalEntityNA: boolean = false;
  selectedLegalEntities: any[] = [];
  files: Attachment[] = [];
  histories: any[] = [];
  userRoles: UserRole[] = [];
  requestStateName: string = '';
  ifcRequired!: boolean;
  isReviewComponent!: boolean;
  isLocalReviewer!: boolean;

  constructor(private dataservice: DataService, private generalDetailsDataService: DataServiceGeneralDetails,
    private apiService: P30APIService, private session: SessionStorageService,
    private commentService: DataServiceComments, private contactService: DataServiceContacts) {

  }

  ngOnInit() {
    this.getUserRoles();
    this.getLegalEntities();
    this.getAttachments();
    this.getHistories();
    this.patchContactsFormValues();
    this.patchProposalOverviewDataToFormGroup();
    // to get the request state for dynamic UI show/Hide
    this.requestStateName = this.session?.getItem(SV_PROPOSAL_STATUS).name.toLocaleLowerCase();
    this.isReviewComponent = true;
    const regex = new RegExp('Local');
    this.isLocalReviewer = regex.test(this.session?.getItem(SV_USER_ROLE).roleTitle);

    if(this.session.getItem(PROPOSAL_ID)){
      this.proposalId = this.session.getItem(PROPOSAL_ID);
    }

    //this.acquisitionEntitySelectionForm.disable();
    // this.dissolutionEntitySelectionForm.disable();
    // this.acquisitionAssetForm.disable();
    // this.acquisitionOwnershipInterestForm.disable();
    // this.acquisitionPropossedSlateForm.disable();
    this.acquisitionProposalOverviewForm.disable();
    // this.acquisitionPropossedSlateForm.disable();
    this.contactsForm.disable();
    if (this.dataservice.aqGeneralDetailsForm) {
      this.generalDetailsDataService.patchGeneralDetailsDataToFormGroup(this.dataservice.aqGeneralDetailsForm);
      this.generalDetailsForm = this.dataservice.aqGeneralDetailsForm;
      this.requestedForUser = this.generalDetailsForm.controls["requestedFor"].value;
      this.businessSegmentUnit = this.generalDetailsForm.controls["businessSegmentUnit"].value;
      this.region = this.generalDetailsForm.controls["region"].value;
      this.generalDetailsForm.disable();

    }
  }
  get legalEntitiesFormArray() {
    return this.dissolutionEntitySelectionForm.controls["legalEntitiesFormArray"] as FormArray;
  }

  patchProposalOverviewDataToFormGroup() {
    this.apiService.getProposalOverview(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        if (event.body) {

          this.acquisitionProposalOverviewForm.patchValue({
            requestId: this.session.getItem(REQUEST_ID),
            summaryId: event.body[0].summaryId,
            overviewOfProposal: event.body[0].overviewOfProposal,
            businessReasonForProposal: event.body[0].businessReasonForProposal,
            targetCompletionDate: event.body[0].targetCompletionDate,
          });
        }
      },
      error: (err) => {
        console.log("Unable to get proposal overview from the database table ", err);
      },
      complete: () => {

      }
    });
  }

  async patchContactsFormValues() {
    this.apiService.getContacts(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        if (event['body']) {
          this.ifcRequired = event.body[0].ifcRequired;
          let coordinatorNames = event.body[0].coordinator.includes(';') ? event.body[0].coordinator.split(';') : [event.body[0].coordinator];
          let additionalCcNames = event.body[0].additionalCcsForNotifications.includes(';') ? event.body[0].additionalCcsForNotifications.split(';') : [event.body[0].additionalCcsForNotifications];
          this.contactsForm.patchValue({
            requestId: this.session.getItem(REQUEST_ID),
            contactId: event.body[0].contactId,
            coordinator: coordinatorNames,
            additionalCcsForNotifications: additionalCcNames,
            ifcRequired: event.body[0].ifcRequired
          })
        }
      },
      error: (err) => {
        console.log("Unable to get general contacts from the database table ", err);
      },
      complete: () => {

      }
    })
  }

  get proposedSlates() {
    return this.acquisitionPropossedSlateForm.controls["proposedSlates"] as FormArray;
  }

  ngOnDestroy() {
    this.dataservice.aqGeneralDetailsForm.enable();
    if (this.acquisitionEntitySelectionForm) {
      this.acquisitionEntitySelectionForm.enable();
    }
    this.acquisitionProposalOverviewForm.enable();
    this.contactsForm.enable();
    this.isReviewComponent = false;
  }

  getEntityIndicatorQuestions() {
    this.apiService.getEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (data) => {
        if (data.body) {
        this.entityQuestions = [...data.body];        
        }
      },
      error: (err) => {
        console.log("Unable to get entity indicator questions from the database table ", err);
      },
      complete: () => {
        

        this.selectedLegalEntities.forEach((entity: any) => {
          this.showLegalEntityNA = false;
          let que = this.entityQuestions.filter(p => p.legalEntityId === Number(entity.legalEntityId));
          let element = que[0];

          if (element) {
            entity.commentsForHavingActiveRegistrations = element.commentsForHavingActiveRegistrations;
            entity.commentsForHavingBankAccounts = element.commentsForHavingBankAccounts
            entity.commentsForHavingEmployees = element.commentsForHavingEmployees
            entity.commentsForHavingSubsidiaries = element.commentsForHavingSubsidiaries
            entity.entityQuestionsId = element.entityQuestionsId ? element.entityQuestionsId : 0
            entity.entityType = element.entityType
            entity.haveActiveBankAccounts = element.haveActiveBankAccounts
            entity.haveActiveRegistrationsInOtherStatesCountries = element.haveActiveRegistrationsInOtherStatesCountries
            entity.haveAnyEmployees = element.haveAnyEmployees
            entity.haveSubsidiaries = element.haveSubsidiaries
            entity.legalEntityId = element.legalEntityId
          }

        });
      }
    });
  }
  getLegalEntities(): Promise<void> {
    return new Promise<void>((resolved) => {
      this.apiService.getLegalEntities(this.session.getItem(PROPOSAL_ID)).subscribe({
        next: (event) => {
          let entities = [];
          entities = event.body
          this.selectedLegalEntities = [...entities];
          // if (this.selectedLegalEntities[0].legalEntityComments) {
          //   this.showLegalEntityNA = true;
          //   this.acquisitionEntitySelectionForm
          //   this.acquisitionEntitySelectionForm.disable();
          // }
          // else {
          this.getEntityIndicatorQuestions();
          //}
        }
        ,
        error: (err) => {
          console.log("Unable to get legal entity record in the database table ", err);

        },
        complete: () => {
          resolved();
        }
      });
    })
  }
  getAttachments(): void {
    this.apiService.getAttachments(this.session.getItem(PROPOSAL_ID)).subscribe(response => {
      this.files = [];
      if (response.body) {
        const data = response.body
        data.forEach((element: Attachment) => {
          this.files.push(element)
        })
      }
      if(this.isLocalReviewer)
         this.files = this.files.filter(x=>x.uploadedByEmail != this.session.getItem(SV_USER).emailAddress)
    })

  }
  getHistories(): void {
    this.apiService.getHistories(this.session.getItem(PROPOSAL_ID)).subscribe(response => {
      if (response.body) {
        this.histories = [...response.body];
      }
    })
  }
  getUserRoles() {
    this.apiService.getAllUserRoles().subscribe(output => {
      if (output.status == 200) {
        this.userRoles = [...output.body];
      }
    })
  }

}
