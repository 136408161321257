import { Component, Input } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { PROPOSAL_ID, REQUEST_ID, SV_PROPOSAL_STATUS, SV_USER, SV_USER_ROLE } from 'src/app/constants/constants';
import { Attachment } from 'src/app/core/interfaces/Attachment';
import { AcquisitionEntityQuestion } from 'src/app/core/interfaces/EntityIndicatorQuestion';
import { UserRole } from 'src/app/core/interfaces/UserRole';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceGeneralDetails } from 'src/app/core/services/data_service_general_details';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataServiceComments } from 'src/app/core/services/data_service_comments';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { DataServiceContacts } from 'src/app/core/services/data_service_contacts';

@Component({
  selector: 'app-proposal-review',
  templateUrl: './proposal-review.component.html',
  styleUrls: ['./proposal-review.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class ProposalReviewComponent {
  collapseIcon:string = 'double-right';
  generalDetailsForm!:UntypedFormGroup;
  requestedForUser!: string;
  coordinators: string[] =[];
  additionalCcs: string[] =[];
  businessSegmentUnit!: string;
  region!: string;
  @Input() acquisitionProposalOverviewForm!:  UntypedFormGroup;
  @Input() acquisitionPropossedSlateForm!: UntypedFormGroup;
  @Input() acquisitionAssetForm!: UntypedFormGroup;
  @Input() acquisitionOwnershipInterestForm!: UntypedFormGroup;
  @Input() acquisitionEntityQuestionsForm!: UntypedFormGroup;
  @Input() mergerEntityDetailsForm!: UntypedFormGroup;
  @Input() mergerProposalOverviewForm!: UntypedFormGroup;
  @Input() acquisitionEntitySelectionForm!: UntypedFormGroup;
  @Input() dissolutionEntitySelectionForm!: UntypedFormGroup;
  @Input() mergingSurviveEntitySelectionForm!: UntypedFormGroup;
  @Input() contactsForm!:UntypedFormGroup;
  entityQuestions: AcquisitionEntityQuestion[] = [];
  showAsset:boolean = false;
  showOwnsershipInterest:boolean = false;
  showLegalEntityNA:boolean = false;
  selectedLegalEntities: any[] = [];
  files: Attachment[] = [];
  histories: any[] = [];
  userRoles: UserRole[] = [];
  requestStateName: string = '';
  ifcRequired!: boolean;
  isReviewComponent!: boolean;
  isLocalReviewer!: boolean;
  proposalId!: string;

  constructor(private dataservice:DataService, private generalDetailsDataService:DataServiceGeneralDetails,
    private apiService:P30APIService, private session:SessionStorageService,
    private commentService: DataServiceComments, private contactService: DataServiceContacts){

  }

  ngOnInit(){
    this.getUserRoles();
    this.getEntityIndicatorQuestions();
    this.getLegalEntities();
    this.getAttachments();
    this.getHistories();
    this.patchContactsFormValues();
    // to get the request state for dynamic UI show/Hide
    this.requestStateName = this.session?.getItem(SV_PROPOSAL_STATUS).name.toLocaleLowerCase();
    this.isReviewComponent = true;
    this.acquisitionEntitySelectionForm.disable();
    if(this.dissolutionEntitySelectionForm){
      this.dissolutionEntitySelectionForm.disable();
    }
    if(this.mergingSurviveEntitySelectionForm){
      this.mergingSurviveEntitySelectionForm.disable();
    }
    if(this.mergerEntityDetailsForm){
      this.mergerEntityDetailsForm.disable();
    }
    if (this.session.getItem(PROPOSAL_ID)) {
      this.proposalId = this.session.getItem(PROPOSAL_ID);
    }
    this.acquisitionAssetForm.disable();
    this.acquisitionOwnershipInterestForm.disable();
    this.acquisitionPropossedSlateForm.disable();
    this.acquisitionProposalOverviewForm.disable();
    if(this.mergerProposalOverviewForm){
      this.mergerProposalOverviewForm.disable();
    }
    this.acquisitionPropossedSlateForm.disable();
    this.contactsForm.disable();
    if(this.dataservice.aqGeneralDetailsForm){
      this.generalDetailsDataService.patchGeneralDetailsDataToFormGroup(this.dataservice.aqGeneralDetailsForm);
      this.generalDetailsForm = this.dataservice.aqGeneralDetailsForm;
      this.requestedForUser = this.generalDetailsForm.controls["requestedFor"].value;
      this.businessSegmentUnit = this.generalDetailsForm.controls["businessSegmentUnit"].value;
      this.region = this.generalDetailsForm.controls["region"].value;
      this.generalDetailsForm.disable();
    }
    const regex = new RegExp('Local');
    this.isLocalReviewer = regex.test(this.session?.getItem(SV_USER_ROLE).roleTitle);
  }

  async patchContactsFormValues() {
    this.apiService.getContacts(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        if(event['body']){
          this.ifcRequired = event.body[0].ifcRequired;
          let coordinatorNames = event.body[0].coordinator.includes(';') ? event.body[0].coordinator.split(';') : [event.body[0].coordinator];
          let additionalCcNames = event.body[0].additionalCcsForNotifications.includes(';') ? event.body[0].additionalCcsForNotifications.split(';') : [event.body[0].additionalCcsForNotifications];
          this.contactsForm.patchValue({
            requestId: this.session.getItem(REQUEST_ID),
            contactId: event.body[0].contactId,
            coordinator: coordinatorNames,
            additionalCcsForNotifications: additionalCcNames,
            ifcRequired: event.body[0].ifcRequired
          })
        }
      },
      error: (err) =>{
        console.log("Unable to get general contacts from the database table ",err);
      },
      complete: () =>{

      }
    })
  }

  get proposedSlates(){
    return this.acquisitionPropossedSlateForm.controls["proposedSlates"] as FormArray;
  }

  ngOnDestroy(){
    this.dataservice.aqGeneralDetailsForm.enable();
    this.acquisitionEntitySelectionForm.enable();
    if(this.mergerEntityDetailsForm){
      this.mergerEntityDetailsForm.enable();
    }
    if(this.mergingSurviveEntitySelectionForm){
      this.mergingSurviveEntitySelectionForm.enable();
    }
    this.acquisitionEntityQuestionsForm.enable();
    if(this.mergerProposalOverviewForm){
      this.mergerProposalOverviewForm.enable();
    }
    this.acquisitionAssetForm.enable();
    if(this.dissolutionEntitySelectionForm){
      this.dissolutionEntitySelectionForm.disable();
    }
    this.acquisitionOwnershipInterestForm.enable();
    this.acquisitionProposalOverviewForm.enable();
    this.acquisitionPropossedSlateForm.enable();
    this.contactsForm.enable();
    this.isReviewComponent = false;
  }

  getEntityIndicatorQuestions(){
    this.apiService.getEntityIndicatorQuestion(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (data)=>{
        this.entityQuestions = [...data.body];
        if(this.entityQuestions[0].assests){
          this.showAsset = true;
        }else if(this.entityQuestions[0].ownershipInterest){
          this.showOwnsershipInterest = true;
        }
      },
      error:(err) =>{

      },
      complete: () =>{

      }
    });
  }
  getLegalEntities():Promise<void>{
    return new Promise<void>((resolved)=>{
      this.apiService.getLegalEntities(this.session.getItem(PROPOSAL_ID)).subscribe({
        next: (event) => {
          let entities = [];
          entities = event.body
          this.selectedLegalEntities = [...entities];
          if(this.selectedLegalEntities[0].legalEntityComments){
            this.showLegalEntityNA = true;
          }
        },
        error: (err) => {
          console.log("Unable to get legal entity record in the database table ", err);

        },
        complete: () => {
          resolved();
        }
      });
    })
  }
  getAttachments(): void {
    this.apiService.getAttachments(this.session.getItem(PROPOSAL_ID)).subscribe(response => {
      this.files = [];
      if (response.body) {
        const data = response.body
        data.forEach((element: Attachment) => {
          this.files.push(element)
        })
        if (this.isLocalReviewer) {
          this.files = this.files.filter(x => x.uploadedByEmail != this.session.getItem(SV_USER).emailAddress)
        }
      }
    })

  }
  getHistories():void {
    this.apiService.getHistories(this.session.getItem(PROPOSAL_ID)).subscribe(response => {
      if(response.body) {
        this.histories = [...response.body];
      }
    })
  }
  getUserRoles(){
    this.apiService.getAllUserRoles().subscribe(output=>{
      if(output.status == 200){
        this.userRoles = [...output.body];
      }
    })
  }

}
