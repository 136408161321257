<form [nzLayout]="'vertical'" nz-form [formGroup]="form">
    <div nz-row nzGutter="8">
        <div nz-col nzSpan="12" nzOffset="6">
            <nz-page-header [nzTitle]="header"></nz-page-header>
            <ng-template #header>
                <span class="custom-header">{{notes.title}}</span>
            </ng-template>
            @if(notes.role === coordinator ){
                @if(notes.actionType === concur){
                    @if(approval){
                        @if(approval.status){
                            <div nz-row nzGutter="8" style="margin-left: 20px;">
                                <label>IFC Governance Concurrence: Concurred </label>
                            </div>
                        }
                        @else {
                            <div nz-row nzGutter="8" style="margin-left: 20px;">
                                <label>IFC Governance Concurrence:  </label>
                            </div>
                        }
                    }
                    <div nz-row nzGutter="8" style="margin-left: 20px;">
                        <label>IFC Governance Comments</label>
                    </div>
                    <nz-form-item>
                        <nz-form-control [@formControlAnimation]>
                            <textarea nz-input placeholder="comments" #concurCommnet
                                [nzAutosize]="{ minRows: 5, maxRows: 10 }" formControlName="comment"
                                [(ngModel)]="comment" (keyup)="onChange()"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                }
                @else if(notes.actionType === returnToRequestor){
                    <div nz-row nzGutter="8" style="margin-left: 20px;">
                        <label> Coordinator notes: </label>
                    </div>
                    <nz-form-item>
                        <nz-form-control [@formControlAnimation]>
                            <textarea nz-input placeholder="Coordinator notes" #concurCommnet
                                [nzAutosize]="{ minRows: 5, maxRows: 10 }" formControlName="comment"
                                [(ngModel)]="comment" (keyup)="onChange()"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                }
                @else if(notes.actionType === complete){
                    <div nz-row nzGutter="8" style="margin-left: 20px;">
                        <label> IFC Governance comments</label>
                    </div>
                    <nz-form-item>
                        <nz-form-control [@formControlAnimation]>
                            <textarea nz-input placeholder="IFC Governance Comments" #concurCommnet
                                [nzAutosize]="{ minRows: 5, maxRows: 10 }" formControlName="comment"
                                [(ngModel)]="comment" (keyup)="onChange()"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                }
                @else if(notes.actionType === withdraw){
                    <div nz-row nzGutter="8" style="margin-left: 20px;">
                        <label> IFC Governance comments</label>
                    </div>
                    <nz-form-item>
                        <nz-form-control [@formControlAnimation]>
                            <textarea nz-input placeholder="IFC Governance Comments" #concurCommnet
                                [nzAutosize]="{ minRows: 5, maxRows: 10 }" formControlName="comment"
                                [(ngModel)]="comment" (keyup)="onChange()"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                }
                
            }@else if(notes.role === ifcMember){
                @for(approval of approvals;track approval.approvalsId){
                    @if(approval && userRole.roleTitle==approval.type){
                        @if(approval.status ){
                            <div nz-row nzGutter="8" style="margin-left: 20px;">
                                <label>{{userRole.roleTitle}} Concurrence: Concurred </label>
                            </div>
                        }
                        @else {
                            <div nz-row nzGutter="8" style="margin-left: 20px;">
                                <label>{{userRole.roleTitle}} Concurrence:  {{notes.concurType}}</label>
                            </div>
                        }
                        
                    }
                }
                <div nz-row nzGutter="8" style="margin-left: 20px;">
                    <label>{{userRole.roleTitle}} Comments</label>
                </div>
                <nz-form-item>
                    <nz-form-control [@formControlAnimation]>
                        <textarea nz-input placeholder="comments" #concurCommnet
                            [nzAutosize]="{ minRows: 5, maxRows: 10 }" formControlName="comment"
                            [(ngModel)]="comment" (keyup)="onChange()"></textarea>
                    </nz-form-control>
                </nz-form-item>
            }
            @else if(notes.role === localIfcMember){
                @for(approval of approvals;track approval.approvalsId){
                    @if(approval && userRole.roleTitle==approval.type){
                        @if(approval.status ){
                            <div nz-row nzGutter="8" style="margin-left: 20px;">
                                <label>{{userRole.roleTitle}} Concurrence: Concurred </label>
                            </div>
                        }
                        @else {
                            <div nz-row nzGutter="8" style="margin-left: 20px;">
                                <label>{{userRole.roleTitle}} Concurrence:  {{notes.concurType}}</label>
                            </div>
                        }
                        
                    }
                }
                <div nz-row nzGutter="8" style="margin-left: 20px;">
                    <label>{{userRole.roleTitle}} Comments</label>
                </div>
                <nz-form-item>
                    <nz-form-control [@formControlAnimation]>
                        <textarea nz-input placeholder="comments" #concurCommnet
                            [nzAutosize]="{ minRows: 5, maxRows: 10 }" formControlName="comment"
                            [(ngModel)]="comment" (keyup)="onChange()"></textarea>
                    </nz-form-control>
                </nz-form-item>
            }

        </div>
    </div>
</form>
<div class="nzButtonRight">
    <button nz-button nzType="default" (click)="goBackToReviewPage()"><span>back</span></button>
    <nz-divider nzType="vertical"></nz-divider>
    <button nz-button nzType="primary" [disabled]="disableSubmit" (click)="submit()"><span>submit</span></button>
</div>