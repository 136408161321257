import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CalAngularService } from '@cvx/cal-angular';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';
import { concat } from 'rxjs';
import { COMPLETE, CONCUR, COORDINATOR, DECLINE, IFCCOMPTROLLERS, IFCGOVERNANCE, IFCLEGAL, IFCMEMBER, IFCTAX, IFCTREASURY, LOCALCOMPTROLLERS, LOCALIFCMEMBER, LOCALLEGAL, LOCALTAX, LOCALTREASURY, PROPOSAL_ID, REQUEST_ID, RETURNTOREQUESTOR, SV_PROPOSAL_STATUS, SV_USER, SV_USER_ROLE, WITHDRAW } from 'src/app/constants/constants';
import { Contacts } from 'src/app/core/interfaces/Contacts';
import { User } from 'src/app/core/interfaces/User';
import { UserRole } from 'src/app/core/interfaces/UserRole';
import { Approval } from 'src/app/core/interfaces/approval';
import { Notes } from 'src/app/core/interfaces/notes';
import { Reviewer } from 'src/app/core/interfaces/reviewer';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceComments } from 'src/app/core/services/data_service_comments';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-notes-submission',
  templateUrl: './notes-submission.component.html',
  styleUrl: './notes-submission.component.less'
})
export class NotesSubmissionComponent {
  @Input() notes!:Notes;
  @Output() enableReview = new EventEmitter<boolean>();
  @Output() submitNote = new EventEmitter<object>();
  @Output() ifcApprove = new EventEmitter<boolean>();
  isIFCRequired: boolean = false;

  form = this.fb.group({
    comment: [null]
  });
  disableSubmit: boolean = true;
  comment!: string;
  formData!: Approval;
  approvals: Approval[] = [];
  approval!: Approval;
  approverId!: number;
  concur: string = CONCUR;
  returnToRequestor:string = RETURNTOREQUESTOR;
  withdraw:string = WITHDRAW;
  complete:string = COMPLETE;
  coordinator: string =  COORDINATOR;
  ifcMember: string = IFCMEMBER;
  localIfcMember: string = LOCALIFCMEMBER;
  ifcMembsers: string[] = [IFCGOVERNANCE,IFCLEGAL,IFCTREASURY,IFCTAX,IFCCOMPTROLLERS]
  localIfcMembsers: string[] = [LOCALLEGAL,LOCALTREASURY,LOCALTAX,LOCALCOMPTROLLERS]
  userRole!: UserRole;
  constructor(private fb: FormBuilder,
    private apiService: P30APIService,
    private authService:CalAngularService,
    private dataservice: DataService,
    private dataserviceComment: DataServiceComments,
    private session: SessionStorageService) { }

  ngOnInit() {
    this.approverId = this.session.getItem(SV_USER).userId;
    this.userRole = this.session.getItem(SV_USER_ROLE);
    switch(this.notes.role){
      case COORDINATOR:{
        if(this.notes.actionType === CONCUR){
          this.getApprovals();
          this.getContacts();
        }else if(this.notes.actionType === RETURNTOREQUESTOR){
          this.getComments();
        }else if(this.notes.actionType === COMPLETE){
          this.getContacts();
        }else if(this.notes.actionType === WITHDRAW){
          this.getApprovals();
        }
        break;
      }
      case IFCMEMBER:{
        this.getApprovals();
        this.getContacts();
        break;
      }
      case LOCALIFCMEMBER:{
        this.getApprovals();
        this.getContacts();
        break;
      }
      default:{
        break;
      }
    }


  }

  onChange() {
    if (this.comment) {
      this.disableSubmit = false;
    } else {
      this.disableSubmit = true;
    }
  }

  getApprovals() {
    this.apiService.getApprovals(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (approval) => {
        if (approval.body) {
          this.approvals = [...approval.body];
          this.approval  = <Approval> this.approvals.find(e=>e.approverId==this.approverId);
          for(let approval of this.approvals){
            if(approval &&
              (this.userRole.roleTitle==approval.type || this.userRole.roleTitle === COORDINATOR) &&
              (this.notes.concurType == CONCUR || this.notes.concurType == DECLINE) && approval.comment){
              this.comment = approval.comment?approval.comment:"";
              this.form.controls["comment"].disable();
              this.form.updateValueAndValidity();
              this.disableSubmit = true;
            }
          }
          if(this.notes.actionType === CONCUR && this.isIFCRequired && this.checkAllIFCMembersApproved() ){
            console.log("inside")
            this.ifcApprove.emit(true);
          }else{
            this.ifcApprove.emit(false);
          }
        }
      },
      error: (err) => {
        console.log(`Unable to retrieve approvals ${err}`);
      },
      complete: () => {

      }
    })
  }

  getContacts() {
    this.apiService.getContacts(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (contacts) => {
        if (contacts.body) {
          let arrContacts = [...contacts.body];
          let contact = arrContacts.find(e=>e.requestId==this.session.getItem(REQUEST_ID));
          if(contact)
            this.isIFCRequired = contact.ifcRequired?contact.ifcRequired:false;
          if(contact.completionNotes){
            this.comment = contact.completionNotes;
            this.form.controls["comment"].disable();
            this.form.updateValueAndValidity();
            this.disableSubmit = true;
          }
        }
      },
      error: (err) => {
        console.log(`Unable to retrieve contacts ${err}`);
      },
      complete: () => {

      }
    })
  }

  disableCommentControl(){
    this.form.controls["comment"].disable();
    this.form.updateValueAndValidity();
    this.disableSubmit = true;
  }

  getComments(){
    this.apiService.getComments(this.session.getItem(PROPOSAL_ID)).subscribe({
      next:(comments)=>{
        if(comments.body){
          let arrComments = [...comments.body];
          let emailAddress = this.session.getItem(SV_USER).emailAddress;
          let comment_rr = arrComments.find(e=>e.coordinatorEmail==emailAddress && e.typeOfComment === RETURNTOREQUESTOR);
          let comment_c = arrComments.find(e=>e.coordinatorEmail==emailAddress && e.typeOfComment === COMPLETE);
          let comment_w = arrComments.find(e=>e.coordinatorEmail==emailAddress && e.typeOfComment === WITHDRAW);
          if(comment_rr){
            this.comment = comment_rr.comment;
          }else if(comment_c){
            this.comment = comment_c.comment;
          }else if(comment_w){
            this.comment = comment_w.comment;
          }

        }
      },
      error: (err)=>{
        console.log(`Unable to retrieve comments ${err}`);
      },
      complete:() =>{

      }
    })

  }
  submit() {
    switch(this.notes.role){
      case COORDINATOR:{
        if(this.notes.actionType === CONCUR){
          this.submitApproval();
        }else if(this.notes.actionType === RETURNTOREQUESTOR){
          this.createComment(RETURNTOREQUESTOR);
        }else if(this.notes.actionType ===  COMPLETE){
          this.submitContacts();
        }else if(this.notes.actionType === WITHDRAW){
          this.submitApproval();
        }
        break;
      }
      case IFCMEMBER:{
        this.submitApproval()
        break;
      }
      case LOCALIFCMEMBER:{
        this.submitApproval()
        break;
      }
      default:{
        break;
      }
    }

  }
  submitContacts(){
    this.apiService.getContacts(this.session.getItem(PROPOSAL_ID)).subscribe({
      next:(contacts)=>{
        if(contacts.body){
          let arrContacts = [...contacts.body];
          let user:User = this.session.getItem(SV_USER);
          let contact = arrContacts.find(e=>e.requestId==this.session.getItem(REQUEST_ID));
          if(contact){
            const data = {
              "requestId": this.session.getItem(REQUEST_ID),
              "coordinator": `${user.firstName} ${user.lastName}`,
              "coordinatorEmail": user.emailAddress,
              "ifcRequired": contact.ifcRequired,
              "additionalCcsForNotifications": contact.additionalCcsForNotifications,
              "additionalCcsForNotificationsEmail": contact.additionalCcsForNotificationsEmail,
              "ifcSecretary": contact.ifcSecretary,
              "ifcSecretaryEmail": contact.ifcSecretaryEmail,
              "completionNotes": this.comment
            }
            this.updateContact(contact.contactId,data);
          }
        }
      },
      error:(err)=>{
        console.log(`Unable to retrieve contact ${err}`);
      },
      complete:()=>{
        this.dataservice.saveHistory(this.authService.cvxClaimsPrincipal.objectId,
          this.session.getItem(PROPOSAL_ID),
          `Proposal completed`)
      }
    })
  }

  createOrUpdateApproval(){
    this.apiService.getReviewers().subscribe({
      next: (reviewer) => {
        if (reviewer.body) {
          let reviewers: Reviewer[] = [...reviewer.body];
          let roleTitle =  this.session.getItem(SV_USER_ROLE).roleTitle;
          if(roleTitle === COORDINATOR){
            roleTitle = IFCGOVERNANCE;
          }
          let reviewerData = reviewers.find(e => e.type === roleTitle);
          this.apiService.getApprovals(this.session.getItem(PROPOSAL_ID)).subscribe({
            next: (approval) => {
              const data: Approval = {
                requestId: this.session.getItem(REQUEST_ID),
                reviewerId: reviewerData?.reviewerId,
                type: reviewerData?.type,
                approverId: this.session.getItem(SV_USER).userId,
                approverEmail: this.session.getItem(SV_USER).emailAddress,
                status: this.notes.concurType === CONCUR,
                concurDate: new Date().toLocaleString("en-US"),
                comment: this.comment,
                reset: false
              }
              if (approval.body) {
                let approvals: Approval[] = [...approval.body];
                let approvalsId: any = approvals.find(e => e.type === reviewerData?.type)?.approvalsId;
                data.dueDate = approvals.find(e => e.type === reviewerData?.type)?.dueDate;
                if(approvalsId){
                  this.updateApproval(approvalsId,data);
                }else{
                  data.dueDate = '';
                  this.createApproval(data);
                }
              } else {
                data.dueDate = '';
                this.createApproval(data);
              }
            },
            error: (err) => {
              console.log("unable to create or update approval",err);
            },
            complete: () => {
              if(this.notes.concurType === DECLINE){
                this.dataservice.saveHistory(this.authService.cvxClaimsPrincipal.objectId,
                  this.session.getItem(PROPOSAL_ID),
                  `Proposal declined`)
              }
              else if(this.notes.concurType === CONCUR){
                this.dataservice.saveHistory(this.authService.cvxClaimsPrincipal.objectId,
                  this.session.getItem(PROPOSAL_ID),
                  `Proposal concurred`)

              }else if(this.notes.actionType === WITHDRAW){
                this.dataservice.saveHistory(this.authService.cvxClaimsPrincipal.objectId,
                  this.session.getItem(PROPOSAL_ID),
                  `Proposal withdrawn`)
              }


            }
          })


        }

      },
      error: (err) => {

      },
      complete: () => {

      }
    })
  }

  submitApproval(){
    switch(this.notes.role){
      case COORDINATOR:{
        if(this.isIFCRequired){
          this.createOrUpdateApproval();
        }else{
          this.apiService.getReviewers().subscribe({
            next: (reviewer) => {
              if (reviewer.body) {
                let reviewers: Reviewer[] = [...reviewer.body];
                let roleTitle =  this.session.getItem(SV_USER_ROLE).roleTitle;
                if(roleTitle === COORDINATOR){
                  roleTitle = IFCGOVERNANCE;
                }
                let reviewerData = reviewers.find(e => e.type === roleTitle);
                const data: Approval = {
                  requestId: this.session.getItem(REQUEST_ID),
                  reviewerId: reviewerData?.reviewerId,
                  type: reviewerData?.type,
                  approverId: this.session.getItem(SV_USER).userId,
                  approverEmail: this.session.getItem(SV_USER).emailAddress,
                  status: this.notes.concurType === CONCUR,
                  concurDate: new Date().toLocaleString("en-US"),
                  comment: this.comment,
                  dueDate: '',
                  reset: false
                }
                this.apiService.createApprovals(this.session.getItem(PROPOSAL_ID), data).subscribe({
                  next: (result) => {
                    console.log(result.body);
                  },
                  error: (err) => {
                    console.log(`Unable to save concur decline record ${err}`);
                  },
                  complete: () => {
                    if(this.notes.concurType === DECLINE){
                      this.dataservice.saveHistory(this.authService.cvxClaimsPrincipal.objectId,
                        this.session.getItem(PROPOSAL_ID),
                        `Proposal declined`)
                    }
                    else if(this.notes.concurType === CONCUR){
                      this.dataservice.saveHistory(this.authService.cvxClaimsPrincipal.objectId,
                        this.session.getItem(PROPOSAL_ID),
                        `Proposal concurred`)
                    }
                    this.submitNote.emit({success:true,typeOfComment:this.notes.actionType,role: this.notes.role,approverType:data.type});
                    this.getApprovals();
                  }

                })
              }

            },
            error: (err) => {

            },
            complete: () => {

            }
          })
        }
        break;
      }
      case IFCMEMBER:{
        this.createOrUpdateApproval();
        break;
      }
      case LOCALIFCMEMBER:{
        this.createOrUpdateApproval();
        break;
      }
      default:{
        break;
      }
    }


  }

  checkAllIFCMembersApproved(){
    // console.log("test check all ",this.approvals.every(e=>e.status == true && e.requestId == this.session.getItem(REQUEST_ID)))
    return this.approvals.every(e=>e.status == true && e.requestId == this.session.getItem(REQUEST_ID));
  }

  createComment(typeOfComment:string){
    let user:User = this.session.getItem(SV_USER);
    let status = this.session.getItem(SV_PROPOSAL_STATUS).name;
    this.dataserviceComment.commentData = this.comment;
    this.dataserviceComment.coordinatorDetails = {
      fullName: `${user.firstName} ${user.lastName}`,
      mail:`${user.emailAddress}`,
      objectId:`${user.objectId}`
    }
    this.dataserviceComment.createComment(typeOfComment,status).subscribe({
      next: (result) => {
        console.log(result.body)
      },
      error: (err) => {
        console.log(`Unable to save comment record ${err}`);
      },
      complete: () => {
        this.dataservice.saveHistory(this.authService.cvxClaimsPrincipal.objectId,
          this.session.getItem(PROPOSAL_ID),
          `Proposal returned to requestor`);
        this.submitNote.emit({success:true,typeOfComment:this.notes.actionType,role: this.notes.role});
      }

    })
  }

  updateComment(commentId:number,typeOfComment:string){
    let user:User = this.session.getItem(SV_USER);
    let status = this.session.getItem(SV_PROPOSAL_STATUS).name;
    this.dataserviceComment.commentData = this.comment;
    this.dataserviceComment.coordinatorDetails = {
      fullName: `${user.firstName} ${user.lastName}`,
      mail:`${user.emailAddress}`,
      objectId:`${user.objectId}`
    }
    this.dataserviceComment.updateComment(commentId,typeOfComment,status).subscribe({
      next: (result) => {
        console.log(result.body);
      },
      error: (err) => {
        console.log(`Unable to update comment record ${err}`);
      },
      complete: () => {
        this.submitNote.emit({success:true,typeOfComment:this.notes.actionType,role: this.notes.role});
      }

    })
  }



  createApproval(data: Approval){
    this.apiService.createApprovals(this.session.getItem(PROPOSAL_ID), data).subscribe({
      next: (result) => {
        console.log(result.body);
        this.getApprovals();
      },
      error: (err) => {
        console.log(`Unable to save concur decline record ${err}`);
      },
      complete: () => {
        this.submitNote.emit({success:true,typeOfComment:this.notes.actionType,role: this.notes.role,approverType:data.type});
      }

    })
  }

  updateApproval(approvalsId: number,data: Approval){
    this.apiService.updateApprovals(this.session.getItem(PROPOSAL_ID), approvalsId, data).subscribe({
      next: (result) => {
        if(result.status==200){
          this.getApprovals();
        }
      },
      error: (err) => {
        console.log(`Unable to update concur decline record ${err}`);
      },
      complete: () => {
        this.submitNote.emit({success:true,typeOfComment:this.notes.actionType,role: this.notes.role,approverType:data.type});
      }

    })
  }
  updateContact(contactId:number,data:any){
    this.apiService.updateContacts(this.session.getItem(PROPOSAL_ID), contactId, data).subscribe({
      next: (result) => {
        console.log(result.body)
      },
      error: (err) => {
        console.log(`Unable to update contact record ${err}`);
      },
      complete: () => {
        this.submitNote.emit({success:true,typeOfComment:this.notes.actionType,role: this.notes.role});
      }

    })
  }
  goBackToReviewPage(){
    this.enableReview.emit(true);
  }


}


