import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { map } from 'rxjs';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { SV_USER_ROLE, SV_PROPOSAL_STATUS, SV_USER, PROPOSAL_ID, IFCConcurred, IFCDeclined, IFCPending, ConfirmationHeading, ConfirmationContent, STATUSPendingGovernance, IFCSecretaryGroupName, TAB_TITLE_DEFAULT_CLASS, LocalComptrollersGroupName, LocalLegalGroupName, LocalTaxGroupName, LocalTreasuryGroupName, STATUSPendingLocalIFCConcurrence, LOCALLEGAL, LOCALCOMPTROLLERS, LOCALTAX, LOCALTREASURY, STATUSPendingIFCConcurrence } from 'src/app/constants/constants';
//import { IFCReviewersMembersDTO, IFCGroupsDTO } from 'src/app/core/interfaces/ifcReviewers';
import { LocalIFCControllersReviewer, LocalIFCGroupsDTO, LocalIFCLegalReviewer, LocalIFCReviewer, LocalIFCReviewersMembersDTO, LocalIFCTaxReviewer, LocalIFCTreasuryReviewer } from 'src/app/core/interfaces/LocalIFCReviewer';
import { UserDetails } from 'src/app/core/interfaces/User';
//import { IFCGroupsDTO, IFCReviewersMembersDTO } from 'src/app/core/interfaces/ifcReviewers';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceApprovals } from 'src/app/core/services/data_service_approvals';
import { DataServiceIFCReviewers } from 'src/app/core/services/data_service_ifc_reviewers';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-local-reviewer',
  templateUrl: './local-reviewer.component.html',
  styleUrl: './local-reviewer.component.less',
  animations: [formControlElement, formLabelElement],
})
export class LocalReviewerComponent implements OnInit {
  @Output() localPayloadRecords = new EventEmitter<LocalIFCGroupsDTO[]>();
  @Output() isLocalApprovalSubmitted = new EventEmitter<boolean>();
  @Output() isResetEmailTrigger = new EventEmitter<boolean>();
  @Output() isReminderEmailTrigger = new EventEmitter<boolean>();
  @Output() isLoader = new EventEmitter<boolean>();
  @Output() localRevewerTabClass = new EventEmitter<string>();
  @Input() isIFCRequired!: boolean;
  @Input() reviewComponent!: boolean | string;
  localLegalDefaultMember: LocalIFCReviewersMembersDTO[] = [];
  localControllersDefaultMember: LocalIFCReviewersMembersDTO[] = [];
  localTaxDefaultMember: LocalIFCReviewersMembersDTO[] = [];
  localTreasuryDefaultMember: LocalIFCReviewersMembersDTO[] = [];
  //localGovernanceDefaultMember: IFCReviewersMembersDTO[] = [];
  //localSecretaryDefaultMember: IFCReviewersMembersDTO[] = [];
  userRoleTitle: any;
  isDueDate!: string | null;
  isConcurDate!: string;
  legalConcurrence!: string;
  controllersConcurrence!: string;
  taxConcurrence!: string;
  treasuryConcurrence!: string;
  governanceConcurrence!: string;
  proposalStatus!: string;
  loggedInUserID!: number;
  listOfGroupsMemberByDefault: LocalIFCReviewersMembersDTO[] = [];
  approvalsRecords: LocalIFCGroupsDTO[] = [];
  proposal_ID: string = this.session.getItem(PROPOSAL_ID);
  approverId = this.session.getItem(SV_USER).userId;
  isVisible!: boolean;
  isOkLoading!: boolean;
  isModalYesBtnClicked!: boolean;
  isModalNoBtnClicked!: boolean;
  confirmModal?: any;
  confirmationModalHeading = ConfirmationHeading;
  confirmationModalContent = ConfirmationContent;
  showHideLIFCModal: boolean = false;
  resetGroupName!: string;
  legalStatus!: boolean | null;
  controllersStatus!: boolean | null;
  taxStatus!: boolean | null;
  treasuryStatus!: boolean | null;
  governanceStatus!: boolean | null;
  localLegalApprovalsId!: number | null;
  localTaxApprovalsId!: number | null;
  localControllersApprovalsId!: number | null;
  localTresuryApprovalsId!: number | null;
  localGovernanceApprovalsId!: number | null;
  legalConcuredStatus!: boolean | null;
  controllersConcuredStatus!: boolean | null;
  taxConcuredStatus!: boolean | null;
  treasuryConcuredStatus!: boolean | null;
  governanceConcuredStatus!: boolean | null;
  sessionDueDate: any;
  isActionComplete!: boolean;

  coordianatorLocalForm!: UntypedFormGroup;
  coordianatorLocalFormModel: LocalIFCReviewer = {
    dueDate: '',
    concurDate: '',
    localTreasury: [],
    localLegal: [],
    localControllers: [],
    localTax: [],
  };

  localSecretaryForm!: UntypedFormGroup;
  localSecretaryFormModel = {
    dueDate: '',
    concurDate: '',
    //localSecretary: '',
  };
  localLegalForm!: UntypedFormGroup;
  localLegalFormModel: LocalIFCLegalReviewer = {
    localLegal: [],
    legalComments: '',
    localLegalSwitch: false,
  };
  localControllersForm!: UntypedFormGroup;
  localControllersFormModel: LocalIFCControllersReviewer = {
    localControllers: [],
    controllersComments: '',
    localControllersSwitch: false,
  };
  localTaxForm!: UntypedFormGroup;
  localTaxFormModel: LocalIFCTaxReviewer = {
    localTax: [],
    taxComments: '',
    localTaxSwitch: false,
  };
  localTreasuryForm!: UntypedFormGroup;
  localTreasuryFormModel: LocalIFCTreasuryReviewer = {
    localTreasury: [],
    treasuryComments: '',
    localTreasurySwitch: false,
  };
  // localGovernanceForm!: UntypedFormGroup;
  // localGovernanceFormModel = {
  //   localGovernance: '',
  //   governanceComments: '',
  //   localGovernanceSwitch: false,
  // };

  constructor(
    private fb: UntypedFormBuilder,
    private dataservice: DataService,
    private session: SessionStorageService,
    private graph: MsGraphService,
    private localReviewersService: DataServiceIFCReviewers
  ) {
   }


  async ngOnInit() {
    this.isActionComplete = false;
    this.loggedInUserID = this.session.getItem(SV_USER).userId;
    this.dataservice.getUserRoleTitle(this.session.getItem(SV_USER_ROLE).roleTitle);
    this.proposalStatus = this.session.getItem(SV_PROPOSAL_STATUS).name.toLocaleLowerCase();
    this.userRoleTitle = this.dataservice.roleTitle.toLocaleLowerCase();
    this.sessionDueDate = this.session.getItem("duedate") ? this.session.getItem("duedate") : null;
    this.localReviewersService.reviewNsubmitStatus(undefined);
    //this.localReviewersService.getIFCUpdateRecordsStatus(undefined);
    this.localReviewersService.getLocalUpdateRecordsStatus(undefined);
    if (this.isIFCRequired) {
      this.createFormGroupsWithNestedFormControllers();
      await this.getDefaultMemberOfGroups();
      await this.getApprovalsList();
      this.enableDisableCommentsFormFields();
    }
    this.showHideLIFCModal = false;
    this.createPayload();
    this.selectDueByDate(this.localSecretaryFormModel.dueDate);
  }

  // to get default members of all groups
  async getDefaultMemberOfGroups() {
    this.isLoader.emit(true);
    this.listOfGroupsMemberByDefault = [];
    let defaultUser = await this.localReviewersService.defaultLocalGroupMemberAPICall();
    defaultUser.subscribe({
      next: (groupMember) => {
        if (groupMember['body']) {
          this.listOfGroupsMemberByDefault = [...groupMember['body']];
          this.listOfGroupsMemberByDefault = this.listOfGroupsMemberByDefault.filter(x => x.defaultEmail == '');
          this.storeDefaultMemberIntoVariable();
          this.isLoader.emit(false);
        }
      },
      error: (err) => {
        console.log("default members error => ", err);
      },
      complete: () => {
        this.isLoader.emit(false);
      }
    })
  }

  // to get all records of approval
  async getApprovalsList() {
    this.isLoader.emit(true);
    let approvalsRecords = await this.localReviewersService.getLocalApprovals();
    approvalsRecords.subscribe({
      next: (approvals) => {
        let localIfcApprovals: LocalIFCGroupsDTO[] = [];
        localIfcApprovals = approvals['body'];
        localIfcApprovals = localIfcApprovals?.filter(x => (x.type.toString().toLocaleLowerCase() == LocalLegalGroupName || x.type.toString().toLocaleLowerCase() == LocalComptrollersGroupName || x.type.toString().toLocaleLowerCase() == LocalTaxGroupName || x.type.toString().toLocaleLowerCase() == LocalTreasuryGroupName));
        if (localIfcApprovals) {
          this.isLoader.emit(false);
          this.setDefaultSecretary();
          this.reviewComponentUIShowHideData(localIfcApprovals);
          if (localIfcApprovals[0]?.dueDate) {
            this.isLocalApprovalSubmitted.emit(true);
            this.localReviewersService.getLocalUpdateRecordsStatus(true);
            this.isDueDate = localIfcApprovals[0]?.dueDate;
            this.localSecretaryForm?.controls['dueDate'].setValue(localIfcApprovals[0]?.dueDate);
            this.localSecretaryForm?.updateValueAndValidity();
            this.isActionComplete = true;
            this.localRevewerTabClass.emit(TAB_TITLE_DEFAULT_CLASS);
          }
          for (let approval of localIfcApprovals) {
            this.setUpdateLocalIFCFormFieldsValue(approval);
          }
          this.createPayload();
          this.setResetDueDateFormValidation();
        } else {
          this.isActionComplete = true;
          this.isDueDate = null;
          this.isLocalApprovalSubmitted.emit(false);
          this.localReviewersService.getLocalUpdateRecordsStatus(false);
          this.storeDefaultMemberIntoVariable();
          this.setResetDueDateFormValidation();
        }
      },
      error: (err) => {
        console.log("approvals records error => ", err);
      },
      complete: () => {
        this.showHideLIFCModal=false;
        this.isLoader.emit(false);
      }
    })
  }

  // to set default secretary group member
  setDefaultSecretary() {
    let defaultSecretary = this.listOfGroupsMemberByDefault.find((ele: any) => {
      return ele['type'].toLocaleLowerCase() == IFCSecretaryGroupName;
    })
    switch (this.proposalStatus) {
      case STATUSPendingGovernance: {
        //this.coordianatorLocalForm?.controls['localSecretary'].setValue(defaultSecretary?.defaultEmail);
        this.coordianatorLocalFormModel.localTreasury = <any>defaultSecretary?.defaultEmail;
        break;
      }
      // case STATUSPendingLocalIFCConcurrence: {
      //   this.localSecretaryForm?.controls['localSecretary'].setValue(defaultSecretary?.defaultEmail);
      //   this.localSecretaryFormModel.localSecretary = <any>defaultSecretary?.defaultEmail;
      //   break;
      // }
      case 'default': {
        break;
      }
    }
  }

  setUpdateLocalIFCFormFieldsValue(approvalRecords: LocalIFCGroupsDTO) {
    if (this.proposalStatus == STATUSPendingGovernance) {
      this.setPendingGoverenceFormValues(approvalRecords);
    } else if (this.proposalStatus == STATUSPendingIFCConcurrence || this.proposalStatus == STATUSPendingLocalIFCConcurrence) {
      this.setLocalIFCConcurrenceFormValues(approvalRecords);
    }
  }

  setPendingGoverenceFormValues(approvalRecords: LocalIFCGroupsDTO) {
    const due = this.sessionDueDate ? this.sessionDueDate : null;
    const dueDate = this.isDueDate ? this.isDueDate : due;
    if (this.proposalStatus == STATUSPendingGovernance) {
      this.coordianatorLocalForm?.controls['dueDate'].setValue(dueDate);
      this.coordianatorLocalFormModel.dueDate = dueDate;
      this.coordianatorLocalForm?.controls['concurDate'].setValue(this.isConcurDate);
      this.coordianatorLocalFormModel.concurDate = this.isConcurDate;
      switch (approvalRecords.type.toLocaleLowerCase()) {
        case LocalLegalGroupName: {
          //this.coordianatorLocalForm?.controls['localLegal'].setValue(approvalRecords['approverEmail']);
          //this.coordianatorLocalFormModel.localLegal.push(approvalRecords['approverEmail']);
          let legalEmails = approvalRecords['approverEmail'].includes(';') ? approvalRecords['approverEmail'].split(';') : [approvalRecords['approverEmail']];
          this.localLegalDefaultMember = [];
          for (let email of legalEmails) {
            if (email != '') {
              this.localLegalDefaultMember.push({
                defaultEmail: email,
                reviewerId: approvalRecords['reviewerId'],
                type: approvalRecords['type']
              })
            }
          }
          if (this.localLegalDefaultMember.length > 0) {
            this.coordianatorLocalFormModel.localLegal = legalEmails;
            const control = this.coordianatorLocalForm.controls["localLegal"];
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
          this.localLegalApprovalsId = <any>approvalRecords['approvalsId'];
          break;
        }
        case LocalComptrollersGroupName: {
          //this.coordianatorLocalForm?.controls['localControllers'].setValue(approvalRecords['approverEmail']);
          //this.coordianatorLocalFormModel.localControllers = approvalRecords['approverEmail'];
          let controllersEmails = approvalRecords['approverEmail'].includes(';') ? approvalRecords['approverEmail'].split(';') : [approvalRecords['approverEmail']];
          this.localControllersDefaultMember = [];
          for (let email of controllersEmails) {
            if (email != '') {
              this.localControllersDefaultMember.push({
                defaultEmail: email,
                reviewerId: approvalRecords['reviewerId'],
                type: approvalRecords['type']
              });
            }
          }
          if (this.localControllersDefaultMember.length > 0) {
            this.coordianatorLocalFormModel.localControllers = controllersEmails;
            const control = this.coordianatorLocalForm.controls["localControllers"];
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
          this.localControllersApprovalsId = <any>approvalRecords['approvalsId'];
          break;
        }
        case LocalTaxGroupName: {
          //this.coordianatorLocalForm?.controls['localTax'].setValue(approvalRecords['approverEmail']);
          //this.coordianatorLocalFormModel.localTax = approvalRecords['approverEmail'];
          let taxEmails = approvalRecords['approverEmail'].includes(';') ? approvalRecords['approverEmail'].split(';') : [approvalRecords['approverEmail']];
          this.localTaxDefaultMember = [];
          for (let email of taxEmails) {
            if (email != '') {
              this.localTaxDefaultMember.push({
                defaultEmail: email,
                reviewerId: approvalRecords['reviewerId'],
                type: approvalRecords['type']
              });
            }
          }
          if (this.localTaxDefaultMember.length > 0) {
            this.coordianatorLocalFormModel.localTax = taxEmails;
            const control = this.coordianatorLocalForm.controls["localTax"];
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
          this.localTaxApprovalsId = <any>approvalRecords['approvalsId'];
          break;
        }
        case LocalTreasuryGroupName: {
          //this.coordianatorLocalForm?.controls['localTreasury'].setValue(approvalRecords['approverEmail']);
          //this.coordianatorLocalFormModel.localTreasury = approvalRecords['approverEmail'];
          let treasuryEmails = approvalRecords['approverEmail'].includes(';') ? approvalRecords['approverEmail'].split(';') : [approvalRecords['approverEmail']];
          this.localTreasuryDefaultMember = [];
          for (let email of treasuryEmails) {
            if (email != '') {
              this.localTreasuryDefaultMember.push({
                defaultEmail: email,
                reviewerId: approvalRecords['reviewerId'],
                type: approvalRecords['type']
              });
            }
          }
          if (this.localTreasuryDefaultMember.length > 0) {
            this.coordianatorLocalFormModel.localTreasury = treasuryEmails;
            const control = this.coordianatorLocalForm.controls["localTreasury"];
            control.markAsDirty();
            control.updateValueAndValidity({ onlySelf: true });
          }
          this.localTresuryApprovalsId = <any>approvalRecords['approvalsId'];
          break;
        }
        // case IFCGovernanceGroupName: {
        //   this.coordianatorLocalForm?.controls['localGovernance'].setValue(approvalRecords['approverEmail']);
        //   this.coordianatorLocalFormModel.localGovernance = approvalRecords['approverEmail'];
        //   this.localGovernanceApprovalsId = <any>approvalRecords['approvalsId'];
        //   break;
        // }
        // case IFCSecretaryGroupName: {
        //   this.coordianatorLocalForm?.controls['localSecretary']?.setValue(approvalRecords['approverEmail']);
        //   this.coordianatorLocalFormModel.localSecretary = approvalRecords['approverEmail'];
        //   break;
        // }
        case 'default': {
          break;
        }
      }
      this.coordianatorLocalForm?.updateValueAndValidity();
    }
  }

  setLocalIFCConcurrenceFormValues(approvalRecords: LocalIFCGroupsDTO) {
    let status = approvalRecords['status'] === false ? IFCDeclined : IFCPending;
    switch (approvalRecords.type.toLocaleLowerCase()) {
      case LocalLegalGroupName: {
        let legalEmails = approvalRecords['approverEmail'].includes(';') ? approvalRecords['approverEmail'].split(';') : [approvalRecords['approverEmail']];
        this.localLegalDefaultMember = [];
        this.localLegalForm?.setValue({
          localLegal: legalEmails,
          legalComments: approvalRecords['comment'],
          localLegalSwitch: approvalRecords['reset'],
        });
        this.localLegalForm?.updateValueAndValidity();
        for (let email of legalEmails) {
          if (email != '') {
            this.localLegalDefaultMember.push({
              defaultEmail: email,
              reviewerId: approvalRecords['reviewerId'],
              type: approvalRecords['type']
            });
          }
        };
        this.localLegalApprovalsId = <any>approvalRecords['approvalsId'];
        //this.localLegalFormModel.localLegal.push(approvalRecords['approverEmail']);
        this.localLegalFormModel.localLegal = legalEmails;
        this.localLegalFormModel.legalComments = approvalRecords['comment'];
        this.localLegalFormModel.localLegalSwitch = <any>approvalRecords['reset'];
        this.legalConcuredStatus = approvalRecords['status'];
        this.legalConcurrence = approvalRecords['status'] === true ? IFCConcurred : status;
        if (this.legalConcurrence !== IFCConcurred) this.localLegalForm?.controls['localLegalSwitch'].disable();
        break;
      }
      case LocalComptrollersGroupName: {
        let controllersEmails = approvalRecords['approverEmail'].includes(';') ? approvalRecords['approverEmail'].split(';') : [approvalRecords['approverEmail']];
        this.localControllersDefaultMember = [];
        this.localControllersForm.setValue({
          localControllers: controllersEmails,
          controllersComments: approvalRecords['comment'],
          localControllersSwitch: approvalRecords['reset'],
        });
        this.localControllersForm?.updateValueAndValidity();
        for (let email of controllersEmails) {
          if (email != '') {
            this.localControllersDefaultMember.push({
              defaultEmail: email,
              reviewerId: approvalRecords['reviewerId'],
              type: approvalRecords['type']
            });
          }
        };
        this.localControllersApprovalsId = <any>approvalRecords['approvalsId'];
        //this.localControllersFormModel.localControllers.push(approvalRecords['approverEmail']);
        this.localControllersFormModel.localControllers = controllersEmails;
        this.controllersConcuredStatus = approvalRecords['status'];
        this.controllersConcurrence = approvalRecords['status'] === true ? IFCConcurred : status;
        if (this.controllersConcurrence !== IFCConcurred) this.localControllersForm?.controls['localControllersSwitch'].disable();
        break;
      }
      case LocalTaxGroupName: {
        let taxEmails = approvalRecords['approverEmail'].includes(';') ? approvalRecords['approverEmail'].split(';') : [approvalRecords['approverEmail']];
        this.localTaxDefaultMember = [];
        this.localTaxForm.setValue({
          localTax: taxEmails,
          taxComments: approvalRecords['comment'],
          localTaxSwitch: approvalRecords['reset'],
        });
        this.localTaxForm?.updateValueAndValidity();
        for (let email of taxEmails) {
          if (email != '') {
            this.localTaxDefaultMember.push({
              defaultEmail: email,
              reviewerId: approvalRecords['reviewerId'],
              type: approvalRecords['type']
            });
          }
        };
        this.localTaxApprovalsId = <any>approvalRecords['approvalsId'];
        //this.localTaxFormModel.localTax.push(approvalRecords['approverEmail']);
        this.localTaxFormModel.localTax = taxEmails;
        this.taxConcuredStatus = approvalRecords['status'];
        this.taxConcurrence = approvalRecords['status'] === true ? IFCConcurred : status;
        if (this.taxConcurrence !== IFCConcurred) this.localTaxForm?.controls['localTaxSwitch'].disable();
        break;
      }
      case LocalTreasuryGroupName: {
        let treasuryEmails = approvalRecords['approverEmail'].includes(';') ? approvalRecords['approverEmail'].split(';') : [approvalRecords['approverEmail']];
        this.localTreasuryDefaultMember = []
        this.localTreasuryForm.setValue({
          localTreasury: treasuryEmails,
          treasuryComments: approvalRecords['comment'],
          localTreasurySwitch: approvalRecords['reset'],
        });
        this.localTreasuryForm?.updateValueAndValidity();
        for (let email of treasuryEmails) {
          if (email != '') {
            this.localTreasuryDefaultMember.push({
              defaultEmail: email,
              reviewerId: approvalRecords['reviewerId'],
              type: approvalRecords['type']
            });
          }
        };
        this.localTresuryApprovalsId = <any>approvalRecords['approvalsId'];
        //this.localTreasuryFormModel.localTreasury.push(approvalRecords['approverEmail']);
        this.localTreasuryFormModel.localTreasury = treasuryEmails;
        this.treasuryConcuredStatus = approvalRecords['status'];
        this.treasuryConcurrence = approvalRecords['status'] === true ? IFCConcurred : status;
        if (this.treasuryConcurrence !== IFCConcurred) this.localTreasuryForm?.controls['localTreasurySwitch'].disable();
        break;
      }
      // case IFCGovernanceGroupName: {
      //   this.localGovernanceDefaultMember = [];
      //   this.localGovernanceForm?.controls['localGovernance'].setValue(approvalRecords['approverEmail']);
      //   this.localGovernanceForm?.controls['governanceComments'].setValue(approvalRecords['comment']);
      //   this.localGovernanceForm?.controls['localGovernanceSwitch'].setValue(approvalRecords['reset']);
      //   this.localGovernanceFormModel.governanceComments = approvalRecords['comment'];
      //   this.localGovernanceForm?.updateValueAndValidity();
      //   this.localGovernanceDefaultMember.push({
      //     defaultEmail: approvalRecords['approverEmail'],
      //     reviewerId: approvalRecords['reviewerId'],
      //     type: approvalRecords['type'],
      //   });
      //   this.localGovernanceApprovalsId = <any>approvalRecords['approvalsId'];
      //   this.governanceConcuredStatus = approvalRecords['status'];
      //   this.governanceConcurrence = approvalRecords['status'] === true ? IFCConcurred : status;
      //   if(this.governanceConcurrence !== IFCConcurred) this.localGovernanceForm?.controls['localGovernanceSwitch'].disable();
      //   break;
      // }
      case IFCSecretaryGroupName: {
        this.localSecretaryForm.setValue({
          dueDate: approvalRecords['dueDate'],
          concurDate: approvalRecords['concurDate'],
          localSecretary: approvalRecords['approverEmail'],
        });
        break;
      }
    }
  }

  enableDisableCommentsFormFields() {
    if ((this.proposalStatus == STATUSPendingIFCConcurrence) || (this.proposalStatus == STATUSPendingLocalIFCConcurrence)) {
      this.localLegalForm.controls['legalComments'].disable();
      this.localLegalForm.controls['localLegal'].disable();
      this.localControllersForm.controls['controllersComments'].disable();
      this.localControllersForm.controls['localControllers'].disable();
      this.localTaxForm.controls['taxComments'].disable();
      this.localTaxForm.controls['localTax'].disable();
      this.localTreasuryForm.controls['treasuryComments'].disable();
      this.localTreasuryForm.controls['localTreasury'].disable();
      // this.localGovernanceForm.controls['governanceComments'].disable();
      // this.localGovernanceForm.controls['localGovernance'].disable();
      // this.localSecretaryForm.controls['localSecretary'].disable();
    }
  }

  createFormGroupsWithNestedFormControllers() {
    if ((this.proposalStatus == 'pending ifc concurrence') || (this.proposalStatus == 'pending local ifc concurrence')) {
      this.localSecretaryForm = this.fb.group({
        dueDate: [null],
        concurDate: [null],
        //localSecretary: [null],
      });
      this.localLegalForm = this.fb.group({
        localLegal: [null],
        legalComments: [null],
        localLegalSwitch: [false],
      });
      this.localControllersForm = this.fb.group({
        localControllers: [null],
        controllersComments: [null],
        localControllersSwitch: [false],
      });
      this.localTaxForm = this.fb.group({
        localTax: [null],
        taxComments: [null],
        localTaxSwitch: [false],
      });
      this.localTreasuryForm = this.fb.group({
        localTreasury: [null],
        treasuryComments: [null],
        localTreasurySwitch: [false],
      });
      // this.localGovernanceForm = this.fb.group({
      //   localGovernance: [null],
      //   governanceComments: [null],
      //   localGovernanceSwitch: [false],
      // });
    } else {
      this.coordianatorLocalForm = this.fb.group({
        dueDate: [null],
        concurDate: [null],
        //localSecretary: [null],
        localLegal: [null],
        localControllers: [null],
        localTax: [null],
        localTreasury: [null],
        //localGovernance: [null],
      });
    }
  }

  async storeDefaultMemberIntoVariable() {
    const dueDate = this.isDueDate ? this.isDueDate : this.sessionDueDate;
    this.coordianatorLocalForm?.controls['dueDate'].setValue(dueDate);
    if (this.listOfGroupsMemberByDefault) {
      this.listOfGroupsMemberByDefault.forEach(
        (member: LocalIFCReviewersMembersDTO) => {
          switch (member['type'].toLocaleLowerCase()) {
            case 'localifc legal': {
              let legalEmails = member['defaultEmail'].includes(';') ? member['defaultEmail'].split(';') : [member['defaultEmail']];
              this.localLegalDefaultMember = [];
              for (let email of legalEmails) {
                if (email != '') {
                  this.localLegalDefaultMember.push({
                    defaultEmail: email,
                    reviewerId: member['reviewerId'],
                    type: member['type']
                  })
                }
              }
              if (this.localLegalDefaultMember.length > 0) {
                this.coordianatorLocalFormModel.localLegal = legalEmails;
                const control = this.coordianatorLocalForm.controls["localLegal"];
                control.markAsDirty();
                control.updateValueAndValidity({ onlySelf: true });
              }
              break;
            }
            case 'localifc comptrollers': {
              let comptrollersEmails = member['defaultEmail'].includes(';') ? member['defaultEmail'].split(';') : [member['defaultEmail']];
              this.localControllersDefaultMember = [];
              for (let email of comptrollersEmails) {
                if (email != '') {
                  this.localControllersDefaultMember.push({
                    defaultEmail: email,
                    reviewerId: member['reviewerId'],
                    type: member['type']
                  })
                }
              }
              //this.coordianatorLocalForm?.controls['localControllers'].setValue(member['defaultEmail']);
              //this.coordianatorLocalFormModel.localControllers.push(member['defaultEmail']);
              if (this.localControllersDefaultMember.length > 0) {
                this.coordianatorLocalFormModel.localControllers = comptrollersEmails;
                const control = this.coordianatorLocalForm.controls["localControllers"];
                control.markAsDirty();
                control.updateValueAndValidity({ onlySelf: true });
              }
              break;
            }
            case 'localifc tax': {
              let taxEmails = member['defaultEmail'].includes(';') ? member['defaultEmail'].split(';') : [member['defaultEmail']];
              this.localTaxDefaultMember = [];
              for (let email of taxEmails) {
                if (email != '') {
                  this.localTaxDefaultMember.push({
                    defaultEmail: email,
                    reviewerId: member['reviewerId'],
                    type: member['type']
                  })
                }
              }
              //this.coordianatorLocalForm?.controls['localTax'].setValue(member['defaultEmail']);
              //this.coordianatorLocalFormModel.localTax=member['defaultEmail'];
              if (this.localTaxDefaultMember.length > 0) {
                this.coordianatorLocalFormModel.localTax = taxEmails;
                const control = this.coordianatorLocalForm.controls["localTax"];
                control.markAsDirty();
                control.updateValueAndValidity({ onlySelf: true });
              }
              break;
            }
            case 'localifc treasury': {
              let treasuryEmails = member['defaultEmail'].includes(';') ? member['defaultEmail'].split(';') : [member['defaultEmail']];
              this.localTreasuryDefaultMember = [];
              for (let email of treasuryEmails) {
                if (email != '') {
                  this.localTreasuryDefaultMember.push({
                    defaultEmail: email,
                    reviewerId: member['reviewerId'],
                    type: member['type']
                  })
                }
              }
              //this.coordianatorLocalForm?.controls['localTreasury'].setValue(member['defaultEmail']);
              //this.coordianatorLocalFormModel.localTreasury.push(member['defaultEmail']);
              if (this.localTreasuryDefaultMember.length > 0) {
                this.coordianatorLocalFormModel.localTreasury = treasuryEmails;
                const control = this.coordianatorLocalForm.controls["localTreasury"];
                control.markAsDirty();
                control.updateValueAndValidity({ onlySelf: true });
              }
              break;
            }
            // case 'local governance': {
            //   this.localGovernanceDefaultMember = [];
            //   this.localGovernanceDefaultMember.push({
            //     defaultEmail: member['defaultEmail'],
            //     reviewerId: member['reviewerId'],
            //     type: member['type'],
            //   });
            //   this.coordianatorLocalForm?.controls['localGovernance'].setValue(member['defaultEmail']);
            //   this.coordianatorLocalFormModel.localGovernance = member['defaultEmail'];
            //   break;
            // }
            // case 'local secretary': {
            //   this.localSecretaryDefaultMember = [];
            //   this.localSecretaryDefaultMember.push({
            //     defaultEmail: member['defaultEmail'],
            //     reviewerId: member['reviewerId'],
            //     type: member['type'],
            //   });
            //   this.coordianatorLocalForm?.controls['localSecretary']?.setValue(member['defaultEmail']);
            //   this.coordianatorLocalFormModel.localSecretary = member['defaultEmail'];
            //   this.localSecretaryForm?.controls['localSecretary']?.setValue(member['defaultEmail']);
            //   this.localSecretaryFormModel.localSecretary = member['defaultEmail'];
            //   break;
            // }
          }
        }
      );
    }
  }

  setResetDueDateFormValidation() {
    switch (this.proposalStatus) {
      case STATUSPendingGovernance: {
        const controls = this.coordianatorLocalForm?.controls["dueDate"];
        if (this.isDueDate) {
          controls.removeValidators([Validators.required]);
          controls?.updateValueAndValidity();
        } else {
          controls.setValidators([Validators.required]);
          controls?.updateValueAndValidity({ onlySelf: true });
        }
        //const cocurrenceLocalLegalControl = this.coordianatorLocalForm?.controls["localLegal"];
        //if (this.localLegalFormModel.localLegal.length>0) {
        //  cocurrenceLocalLegalControl.removeValidators([Validators.required]);
        //  cocurrenceLocalLegalControl?.updateValueAndValidity();
        //} else {
        //  cocurrenceLocalLegalControl.setValidators([Validators.required]);
        //  cocurrenceLocalLegalControl?.updateValueAndValidity({ onlySelf: true });
        //}
        break;
      }
      case STATUSPendingIFCConcurrence: {
        const cocurrenceDueDateControl = this.localSecretaryForm?.controls["dueDate"];
        if (this.isDueDate) {
          cocurrenceDueDateControl.removeValidators([Validators.required]);
          cocurrenceDueDateControl?.updateValueAndValidity();
        } else {
          cocurrenceDueDateControl.setValidators([Validators.required]);
          cocurrenceDueDateControl?.updateValueAndValidity({ onlySelf: true });
        }
        //const cocurrenceLocalLegalControl = this.localSecretaryForm?.controls["localLegal"];
        //if (this.localLegalFormModel.localLegal) {
        //  cocurrenceLocalLegalControl.removeValidators([Validators.required]);
        //  cocurrenceLocalLegalControl?.updateValueAndValidity();
        //} else {
        //  cocurrenceLocalLegalControl.setValidators([Validators.required]);
        //  cocurrenceLocalLegalControl?.updateValueAndValidity({ onlySelf: true });
        //}
        break;
      }
      case STATUSPendingLocalIFCConcurrence: {
        const cocurrenceDueDateControl = this.localSecretaryForm?.controls["dueDate"];
        if (this.isDueDate) {
          cocurrenceDueDateControl.removeValidators([Validators.required]);
          cocurrenceDueDateControl?.updateValueAndValidity();
        } else {
          cocurrenceDueDateControl.setValidators([Validators.required]);
          cocurrenceDueDateControl?.updateValueAndValidity({ onlySelf: true });
        }
        break;
      }
    }
  }

  // to get confirmation from modal
  getConfirmation(action: string) {
    this.showHideLIFCModal = false;
    this.setResetToggleValue(action);
    
  }

  // toggle btn to reset the group members
  resetApprovalToggle(event: any, groupName: string) {
    if (event) {
      this.resetGroupName = groupName;
      this.showHideLIFCModal = true;
      this.updateIFCMemberResetValue(groupName, true);
    }
  }

  updateIFCMemberResetValue(group: string, resetAction: boolean) {
    switch (group) {
      case 'localifclegal': {
        this.localLegalForm?.controls['localLegalSwitch'].setValue(resetAction);
        this.localLegalForm?.controls['localLegalSwitch'].disable();
        break;
      }
      case 'localifccontrollers': {
        this.localControllersForm?.controls['localControllersSwitch'].setValue(resetAction);
        this.localControllersForm.controls['localControllersSwitch'].disable();
        break;
      }
      case 'localifctax': {
        this.localTaxForm?.controls['localTaxSwitch'].setValue(resetAction);
        this.localTaxForm?.controls['localTaxSwitch'].disable();
        break;
      }
      case 'localifctreasury': {
        this.localTreasuryForm?.controls['localTreasurySwitch'].setValue(resetAction);
        this.localTreasuryForm?.controls['localTreasurySwitch'].disable();
        break;
      }
      // case 'governance': {
      //   this.localGovernanceForm?.controls['localGovernanceSwitch'].setValue(resetAction);
      //   this.localGovernanceForm?.controls['localGovernanceSwitch'].disable();
      //   break;
      // }
    }
  }

  // based upon modal action status set values into forms fields.
  setResetToggleValue(action: string) {
    this.showHideLIFCModal = false;
    if (action == 'yes') {
      this.isResetEmailTrigger.emit(true);
      this.createPayload();
      switch (this.resetGroupName) {
        case 'localifclegal': {
          this.legalConcurrence = '';
          this.localLegalForm?.controls['localLegal'].enable();
          this.localReviewersService.updateLocalApproval(LOCALLEGAL);
          break;
        }
        case 'localifccontrollers': {
          this.controllersConcurrence = '';
          this.localControllersForm?.controls['localControllers'].enable();
          this.localReviewersService.updateLocalApproval(LOCALCOMPTROLLERS);
          break;
        }
        case 'localifctax': {
          this.taxConcurrence = '';
          this.localTaxForm?.controls['localTax'].enable();
          this.localReviewersService.updateLocalApproval(LOCALTAX);
          break;
        }
        case 'localifctreasury': {
          this.treasuryConcurrence = '';
          this.localTreasuryForm?.controls['localTreasury'].enable();
          this.localReviewersService.updateLocalApproval(LOCALTREASURY);
          break;
        }
        // case 'governance': {
        //   this.governanceConcurrence = '';
        //   this.ifcGovernanceForm?.controls['ifcGovernance'].enable();
        //   this.ifcReviewersService.updateIFCApproval(IFCGOVERNANCE);
        //   break;
        // }
        default: {
          break;
        }
      }
    } else {
      //this.getApprovalsList();
      this.showHideLIFCModal=false;
      //this.enableDisableGroupMemeberField();
    }


  }

  enableDisableGroupMemeberField() {
    switch (this.resetGroupName) {
      case 'localifclegal': {
        this.localLegalForm?.controls['localLegal'].disable();
        break;
      }
      case 'localifccontrollers': {
        this.localControllersForm?.controls['localControllers'].disable();
        break;
      }
      case 'localifctax': {
        this.localTaxForm?.controls['localTax'].disable();
        break;
      }
      case 'localifctreasury': {
        this.localTreasuryForm?.controls['localTreasury'].disable();
        break;
      }
      // case 'governance': {
      //   this.localGovernanceForm?.controls['localGovernance'].disable();
      //   break;
      // }
    }
  }

  selectDueByDate(event: any) {
    if (!event) {
      this.localReviewersService.getLocalUpdateRecordsStatus(undefined);
    } else {
      let dueDate = new Date(event).toLocaleString('en-US');
      this.session.setItem("duedate", dueDate);
      this.setResetDueDateFormValidation();
      let isNewDateSelected = this.compareDueDateAndSelectedDate(event);
      if (this.isActionComplete || isNewDateSelected) {
        if (this.isDueDate) {
          this.localReviewersService.getLocalUpdateRecordsStatus(true);
          this.isLocalApprovalSubmitted.emit(true);
          this.isDueDate = dueDate;
          this.isActionComplete = false;
          this.createPayload();
        } else {
          this.localReviewersService.getLocalUpdateRecordsStatus(false);
          this.isLocalApprovalSubmitted.emit(false);
          this.isDueDate = dueDate;
          this.isActionComplete = false;
          this.createPayload();
        }
      }
    }
  }

  compareDueDateAndSelectedDate(event: any) {
    if (this.isDueDate == event) {
      this.localReviewersService.reviewNsubmitStatus(undefined);
      this.localReviewersService.getLocalUpdateRecordsStatus(undefined);
      return false;
    } else {
      this.localReviewersService.reviewNsubmitStatus(true);
      return true;
    }
  }

  selectConcurByDate(event: any) {
    if (event) {
      this.isConcurDate = event;
    }
  }

  legalPayload() {
    const dueDate = this.isDueDate ? this.isDueDate : this.sessionDueDate;
    const legalPayload = [
      {
        reviewerId: this.localLegalDefaultMember[0]?.reviewerId,
        type: this.localLegalDefaultMember[0]?.type,
        approvalsId: Number(this.localLegalApprovalsId),
        approverId: null,
        approverEmail: this.proposalStatus === STATUSPendingGovernance ?
          this.coordianatorLocalForm?.controls['localLegal'].value?.join(";") : this.localLegalForm?.controls['localLegal'].value?.join(";"),
        dueDate: dueDate,
        concurDate: this.isConcurDate ? this.isConcurDate : null,
        status: this.proposalStatus === STATUSPendingGovernance ? null : this.legalConcuredStatus,
        comment: this.proposalStatus === STATUSPendingGovernance ? '' : this.localLegalForm?.controls['legalComments'].value,
        reset: this.proposalStatus === STATUSPendingGovernance ? null : this.localLegalForm?.controls['localLegalSwitch'].value,
      }
    ];
    console.log('locallegalTest:' + this.coordianatorLocalForm?.controls['localLegal'].value?.join(";"));
    return legalPayload;
  }

  createPayload() {
    const dueDate = this.isDueDate ? this.isDueDate : this.sessionDueDate;
    if (this.localLegalDefaultMember.length && this.localControllersDefaultMember.length && this.localTaxDefaultMember.length && this.localTreasuryDefaultMember.length) {
      let legalPayload: LocalIFCGroupsDTO[] = this.legalPayload();
      let payload: LocalIFCGroupsDTO[] = [...legalPayload,
      {
        reviewerId: this.localControllersDefaultMember[0]?.reviewerId,
        type: this.localControllersDefaultMember[0]?.type,
        approverId: null,
        approvalsId: Number(this.localControllersApprovalsId),
        approverEmail: this.proposalStatus === STATUSPendingGovernance ?
          this.coordianatorLocalForm?.controls['localControllers'].value?.join(";") : this.localControllersForm?.controls['localControllers'].value?.join(";"),
        dueDate: dueDate,
        concurDate: this.isConcurDate ? this.isConcurDate : null,
        status: this.proposalStatus == STATUSPendingGovernance ? null : this.controllersConcuredStatus,
        comment: this.proposalStatus == STATUSPendingGovernance ? '' : this.localControllersForm?.controls['controllersComments'].value,
        reset: this.proposalStatus == STATUSPendingGovernance ? null : this.localControllersForm?.controls['localControllersSwitch'].value,
      },
      {
        reviewerId: this.localTaxDefaultMember[0].reviewerId,
        type: this.localTaxDefaultMember[0].type,
        approverId: null,
        approvalsId: Number(this.localTaxApprovalsId),
        approverEmail: this.proposalStatus === STATUSPendingGovernance ?
          this.coordianatorLocalForm?.controls['localTax'].value?.join(";") : this.localTaxForm?.controls['localTax'].value?.join(";"),
        dueDate: dueDate,
        concurDate: this.isConcurDate ? this.isConcurDate : null,
        status: this.proposalStatus == STATUSPendingGovernance ? null : this.taxConcuredStatus,
        comment: this.proposalStatus == STATUSPendingGovernance ? '' : this.localTaxForm?.controls['taxComments'].value,
        reset: this.proposalStatus == STATUSPendingGovernance ? null : this.localTaxForm?.controls['localTaxSwitch'].value,
      },
      {
        reviewerId: this.localTreasuryDefaultMember[0]?.reviewerId,
        type: this.localTreasuryDefaultMember[0]?.type,
        approverId: null,
        approvalsId: Number(this.localTresuryApprovalsId),
        approverEmail: this.proposalStatus === STATUSPendingGovernance ?
          this.coordianatorLocalForm?.controls['localTreasury'].value?.join(";") : this.localTreasuryForm?.controls['localTreasury'].value?.join(";"),
        dueDate: dueDate,
        concurDate: this.isConcurDate ? this.isConcurDate : null,
        status: this.proposalStatus == STATUSPendingGovernance ? null : this.treasuryConcuredStatus,
        comment: this.proposalStatus == STATUSPendingGovernance ? '' : this.localTreasuryForm?.controls['treasuryComments'].value,
        reset: this.proposalStatus == STATUSPendingGovernance ? null : this.localTreasuryForm?.controls['localTreasurySwitch'].value,
      },
        // {
        //   reviewerId: this.localGovernanceDefaultMember[0].reviewerId,
        //   type: this.localGovernanceDefaultMember[0].type,
        //   approverId: null,
        //   approvalsId: Number(this.localGovernanceApprovalsId),
        //   approverEmail: this.proposalStatus === STATUSPendingGovernance ?
        //     this.coordianatorLocalForm?.controls['localGovernance'].value : this.localGovernanceForm?.controls['localGovernance'].value,
        //   dueDate: dueDate,
        //   concurDate: this.isConcurDate ? this.isConcurDate : null,
        //   status: this.proposalStatus == STATUSPendingGovernance ? null : this.governanceConcuredStatus,
        //   comment: this.proposalStatus == STATUSPendingGovernance ? '' : this.localGovernanceForm?.controls['governanceComments'].value,
        //   reset: this.proposalStatus == STATUSPendingGovernance ? null : this.localGovernanceForm?.controls['localGovernanceSwitch'].value,
        // },
      ];
      this.localReviewersService.localReviewersPayload(payload);
      this.localPayloadRecords.emit(payload);
    }
  }

  reviewComponentUIShowHideData(records: LocalIFCGroupsDTO[]) {
    this.approvalsRecords = [...records];
    switch (this.reviewComponent) {
      case true: {
        this.getFullName();
        break;
      }
      case false: {
        break;
      }
      case undefined: {
        break;
      }
    }
  }

  async getFullName() {
    let userFullName: string;
    for (let user of this.approvalsRecords) {
      user.fullName = "";
      for (let userEmail of user.approverEmail.split(';')) {
        this.graph.getUserByEmail(userEmail).subscribe((data: UserDetails) => {
          userFullName = `${data.givenName} ${data.surname}`;
          if (userFullName != undefined)
            user.fullName += userFullName+"  ";
        })
      }
     
    }
  }

  getErrorTipForDueDate(controlName: string): any {
    switch (this.proposalStatus) {
      case STATUSPendingGovernance: {
        const control = this.coordianatorLocalForm?.controls[controlName];
        this.showErrorMsg(control);
        break;
      }
      case STATUSPendingLocalIFCConcurrence: {
        const control = this.localSecretaryForm?.controls[controlName];
        this.showErrorMsg(control);
        break;
      }
    }
  }

  showErrorMsg(control: any) {
    if (control.hasError('required')) {
      return "Please select Due by Date!"
    }
    return ""
  }

  async searchGroupMember(value: string, groupName: string) {
    //this.localLegalDefaultMember = [];
    if (value) {
      let users = [...await this.dataservice.searchUser(value)];
      switch (groupName) {
        case 'localLegal': {
          this.localLegalDefaultMember = [];
          for (let user of users) {
            this.localLegalDefaultMember.push({
              defaultEmail: user?.mail,
              reviewerId: 7,
              type: 'LocalIFC Legal'
            })
          }
          break;
        }
        case 'localControllers': {
          this.localControllersDefaultMember = [];
          for (let user of users) {
            this.localControllersDefaultMember.push({
              defaultEmail: user?.mail,
              reviewerId: 10,
              type: "LocalIFC Comptrollers"
            })
          }
          break;
        }
        case 'localTax': {
          this.localTaxDefaultMember = [];
          for (let user of users) {
            this.localTaxDefaultMember.push({
              defaultEmail: user?.mail,
              reviewerId: 9,
              type: "LocalIFC Tax"
            })
          }
          break;
        }
        case 'localTreasury': {
          this.localTreasuryDefaultMember = [];
          for (let user of users) {
            this.localTreasuryDefaultMember.push({
              defaultEmail: user?.mail,
              reviewerId: 8,
              type: "LocalIFC Treasury"
            })
          }
          break;
        }
        // case 'localGovernance': {
        //   for (let user of users) {
        //     this.localGovernanceDefaultMember.push({
        //       defaultEmail: user?.mail,
        //       reviewerId: 5,
        //       type: "Local Governance"
        //     })
        //   }
        //   break;
        // }
      }
    }
  }

  onGroupMemberChange(event: string) {
    this.createPayload();
  }

  sendReminderEmail() {
    this.isReminderEmailTrigger.emit(true);
  }

  ngOnDestroy() {
    this.localReviewersService.reviewNsubmitStatus(undefined);
    this.localReviewersService.getLocalUpdateRecordsStatus(undefined);
  }

  getErrorTip(formName: string, controlName: string): string {
    switch (formName) {
      case 'localLegalForm': {
        const localLegalcontrol = this.coordianatorLocalForm.controls[controlName];
        if (localLegalcontrol.hasError('required')) {
          return "Please input localIfc legal!"
        }
        return "";
      }
      case 'localControllersForm': {
        const localControllerscontrol = this.coordianatorLocalForm.controls[controlName];
        if (localControllerscontrol.hasError('required')) {
          return "Please input localIfc controllers!"
        }
        return "";
      }
      case 'localTaxForm': {
        const localTaxcontrol = this.coordianatorLocalForm.controls[controlName];
        if (localTaxcontrol.hasError('required')) {
          return "Please input localIfc tax!"
        }
        return "";
      }
      case 'localTreasuryForm': {
        const localTreasurycontrol = this.coordianatorLocalForm.controls[controlName];
        if (localTreasurycontrol.hasError('required')) {
          return "Please input localIfc treasury!"
        }
        return "";
      }
      default:
        return "";
    }

  }

}
