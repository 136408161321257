import { Component, Input } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { PROPOSAL_ID, REQUEST_ID, SV_PROPOSAL_STATUS, SV_USER, SV_USER_ROLE } from 'src/app/constants/constants';
import { Attachment } from 'src/app/core/interfaces/Attachment';
import { AcquisitionEntityQuestion } from 'src/app/core/interfaces/EntityIndicatorQuestion';
import { UserRole } from 'src/app/core/interfaces/UserRole';
import { P30APIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { DataServiceGeneralDetails } from 'src/app/core/services/data_service_general_details';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataServiceComments } from 'src/app/core/services/data_service_comments';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { DataServiceContacts } from 'src/app/core/services/data_service_contacts';
import { DataServiceEntityQuestions } from 'src/app/core/services/data_service_entity_questions';
import { DataServiceEntitySelection } from 'src/app/core/services/data_service_merger_entity_selection';
import { LegalEntity } from 'src/app/core/interfaces/LegalEntities';

@Component({
  selector: 'app-merger-proposal-review',
  templateUrl: './merger-proposal-review.component.html',
  styleUrls: ['./merger-proposal-review.component.less'],
  animations: [formControlElement, formLabelElement],
})
export class MergerProposalReviewComponent {
  collapseIcon:string = 'double-right';
  generalDetailsForm!:UntypedFormGroup;
  requestedForUser!: string;
  coordinators: string[] =[];
  additionalCcs: string[] =[];
  businessSegmentUnit!: string;
  region!: string;
  //@Input() acquisitionProposalOverviewForm!:  UntypedFormGroup;  
  // @Input() acquisitionAssetForm!: UntypedFormGroup;
  // @Input() acquisitionOwnershipInterestForm!: UntypedFormGroup;
 // @Input() acquisitionEntityQuestionsForm!: UntypedFormGroup;
  @Input() mergerEntityDetailsForm!: UntypedFormGroup;
  @Input() mergerProposalOverviewForm!: UntypedFormGroup;
  //@Input() acquisitionEntitySelectionForm!: UntypedFormGroup;
 // @Input() dissolutionEntitySelectionForm!: UntypedFormGroup;
  @Input() mergingSurviveEntitySelectionForm!: UntypedFormGroup;
  @Input() contactsForm!: UntypedFormGroup;
  @Input() attachmentTabClass = 'tab-default';
  @Input() attachmentUploadClass = "";
  @Input() reviewComponent = "";
  attachments: string = "attachments";
  proposalId!: string;
  @Input() mergerProposalAttachmentForm!: UntypedFormGroup;
  entityQuestions: AcquisitionEntityQuestion[] = [];
  showAsset:boolean = false;
  showOwnsershipInterest:boolean = false;
  showLegalEntityNA:boolean = false;
  selectedLegalEntities: any[] = [];
  selectedLegalMergerEntities: any[] = [];
  selectedLegalSurvivingEntities: any[] = [];
  files: Attachment[] = [];
  histories: any[] = [];
  userRoles: UserRole[] = [];
  requestStateName: string = '';
  ifcRequired!: boolean;
  isReviewComponent!: boolean;
  isLocalReviewer!: boolean;

  constructor(private dataservice:DataService, private generalDetailsDataService:DataServiceGeneralDetails,
    private apiService:P30APIService, private session:SessionStorageService,
    private commentService: DataServiceComments, private contactService: DataServiceContacts,
    private entityQuestionsDataService: DataServiceEntityQuestions,
    private entitySelectionDataService: DataServiceEntitySelection){

  }

  ngOnInit(){
    this.getUserRoles();
    //this.getEntityIndicatorQuestions();
    this.getLegalEntities();
    this.getAttachments();
    this.getHistories();
    this.patchContactsFormValues();
    // to get the request state for dynamic UI show/Hide
    this.requestStateName = this.session?.getItem(SV_PROPOSAL_STATUS).name.toLocaleLowerCase();
    this.isReviewComponent = true;
    //this.acquisitionEntitySelectionForm.disable();
    //this.dissolutionEntitySelectionForm.disable();
    this.mergingSurviveEntitySelectionForm.disable();
    this.mergerEntityDetailsForm.disable();
   // this.acquisitionAssetForm.disable();
    //this.acquisitionOwnershipInterestForm.disable();    
   // this.acquisitionProposalOverviewForm.disable();
    this.mergerProposalOverviewForm.disable();
    this.contactsForm.disable();
    if(this.dataservice.aqGeneralDetailsForm){
      this.generalDetailsDataService.patchGeneralDetailsDataToFormGroup(this.dataservice.aqGeneralDetailsForm);
      this.generalDetailsForm = this.dataservice.aqGeneralDetailsForm;
      this.requestedForUser = this.generalDetailsForm.controls["requestedFor"].value;
      this.businessSegmentUnit = this.generalDetailsForm.controls["businessSegmentUnit"].value;
      this.region = this.generalDetailsForm.controls["region"].value;
      this.generalDetailsForm.disable();
    }
    if (this.session.getItem(PROPOSAL_ID)) {
      this.proposalId = this.session.getItem(PROPOSAL_ID);
    }
    const regex = new RegExp('Local');
    this.isLocalReviewer = regex.test(this.session?.getItem(SV_USER_ROLE).roleTitle);
  }

  async patchContactsFormValues() {
    this.apiService.getContacts(this.session.getItem(PROPOSAL_ID)).subscribe({
      next: (event) => {
        if(event['body']){
          this.ifcRequired = event.body[0].ifcRequired;
          let coordinatorNames = event.body[0].coordinator.includes(';') ? event.body[0].coordinator.split(';') : [event.body[0].coordinator];
          let additionalCcNames = event.body[0].additionalCcsForNotifications.includes(';') ? event.body[0].additionalCcsForNotifications.split(';') : [event.body[0].additionalCcsForNotifications];
          this.contactsForm.patchValue({
            requestId: this.session.getItem(REQUEST_ID),
            contactId: event.body[0].contactId,
            coordinator: coordinatorNames,
            additionalCcsForNotifications: additionalCcNames,
            ifcRequired: event.body[0].ifcRequired
          })
        }
      },
      error: (err) =>{
        console.log("Unable to get general contacts from the database table ",err);
      },
      complete: () =>{

      }
    })
  }


  ngOnDestroy(){
    this.dataservice.aqGeneralDetailsForm.enable();
    //this.acquisitionEntitySelectionForm.enable();
    this.mergerEntityDetailsForm.enable();
    this.mergingSurviveEntitySelectionForm.enable();
    //this.acquisitionEntityQuestionsForm.enable();
    this.mergerProposalOverviewForm.enable();
    this.mergerEntityDetailsForm.enable();
   // this.acquisitionAssetForm.enable();
   // this.dissolutionEntitySelectionForm.disable();
    //this.acquisitionOwnershipInterestForm.enable();
    //this.acquisitionProposalOverviewForm.enable();
    this.contactsForm.enable();
    this.isReviewComponent = false;
  }
  getLegalEntities(): Promise<boolean> {
    return new Promise<boolean>((resolved) => {
      if (this.session.getItem(PROPOSAL_ID)) {
        this.selectedLegalEntities = [];
        this.entityQuestionsDataService.getEntityIndicatorQuestions()
          .subscribe({
            next: (event) => {
              if (event.body) {
                this.entityQuestions = [...event.body];
              }
            },
            complete: () => {
              this.entitySelectionDataService.getLegalEntities().subscribe({
                next: (event) => {
                  
                  if (event.body) {
                    this.selectedLegalEntities = [...event.body];                    
                    
                  }
                },
                error: (err) => {
                  
                  console.log("Merger Review: Unable to get legal entity record in the database table ", err);
                  
                  resolved(false);
                },
                complete: () => {
                  for (const element of this.selectedLegalEntities) {
                    if (element.typeofLegalEntity == "Merging Entity") {
                      let entityQuestionsSet = this.entityQuestions.filter((x=> x.legalEntityId === element.legalEntityId));
                      let legalEntityMerger  = {
                        legalEntityId: element.legalEntityId,
                        legalEntityName: element.companyName,
                        ccn: element.ccn,
                        companyName: element.companyName,
                        jurisdiction: element.jurisdiction,
                        ownershipDetail: element.ownershipDetail,
                        entityQuestionsId: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].entityQuestionsId : '',
                        activeRegistration: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].haveActiveRegistrationsInOtherStatesCountries : '',
                        activeRegistrationComments: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].commentsForHavingActiveRegistrations : '',
                        subsidiaries: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].haveSubsidiaries : '',
                        subsidiariesComments: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].commentsForHavingSubsidiaries : ''
                      }
                       this.selectedLegalMergerEntities.push(legalEntityMerger);
                    }

                    if (element.typeofLegalEntity == "Surviving Entity") {
                      let entityQuestionsSet = this.entityQuestions.filter((x=> x.legalEntityId === element.legalEntityId));
                      let legalEntityMerger  = {
                        legalEntityId: element.legalEntityId,
                        legalEntityName: element.companyName,
                        ccn: element.ccn,
                        companyName: element.companyName,
                        jurisdiction: element.jurisdiction,
                        ownershipDetail: element.ownershipDetail,
                        entityQuestionsId: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].entityQuestionsId : '',                          
                        willTheSurvivingEntityChangeItsNameAfterTheMerger: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].willTheSurvivingEntityChangeItsNameAfterTheMerger : '',
                        commentsForSurvivingEntityChangingName: entityQuestionsSet.length > 0 ? entityQuestionsSet[0].commentsForSurvivingEntityChangingName : ''
                      }
                       this.selectedLegalSurvivingEntities.push(legalEntityMerger);
                    }
                  }
                  resolved(true);
                }
              });
             
            }
          });

      }
    });
  }


  getAttachments(): void {
    this.apiService.getAttachments(this.session.getItem(PROPOSAL_ID)).subscribe(response => {
      this.files = [];
      if (response.body) {
        const data = response.body
        data.forEach((element: Attachment) => {
          this.files.push(element)
        })
      }
      if (this.isLocalReviewer)
        this.files = this.files.filter(x => x.uploadedByEmail != this.session.getItem(SV_USER).emailAddress)
    })

  }
  getHistories():void {
    this.apiService.getHistories(this.session.getItem(PROPOSAL_ID)).subscribe(response => {
      if(response.body) {
        this.histories = [...response.body];
      }
    })
  }
  getUserRoles(){
    this.apiService.getAllUserRoles().subscribe(output=>{
      if(output.status == 200){
        this.userRoles = [...output.body];
      }
    })
  }

}
