<p><strong>Merging Entity(ies)</strong></p>
<form nz-form [formGroup]="mergerEntityDetailsForm">
  <div nz-row nzGutter="8">
    <div nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label [@formLabelAnimation] nzTooltipIcon="info-circle" nzTooltipTitle="Comments will be required if Legal Entity is N/A." >Legal Entity(ies)</nz-form-label>
        <br/>
        <nz-form-control [@formControlAnimation]>
          <nz-select nzShowSearch [nzServerSearch]="true" nzPlaceHolder="Search by name. Select one or more."
            formControlName="legalEntity" [nzLoading]="nzLoading" (ngModelChange)="onChange($event)"
            (nzOnSearch)="onSearchEvent($event)" [nzSuffixIcon]="suffixIconSearch">
            <nz-option *ngFor="let entity of legalEntities" [nzValue]="entity.companyName"
              [nzLabel]="entity.companyName"></nz-option>
          </nz-select>
          <ng-template #suffixIconSearch>
            <span nz-icon nzType="search" nzTheme="outline" (click)="onSearch()"></span>
          </ng-template>
          <nz-tag *ngFor="let selectedItem of selectedEntity" nzMode="closeable" style="margin-top: 5px;"
            (nzOnClose)="onCloseTag(selectedItem)">{{selectedItem}}</nz-tag>
        </nz-form-control>
      </nz-form-item>
      <nz-form-label [@formLabelAnimation] *ngIf="legalEntitiesFormArray.controls.length > 0">Answer the following
        questions for the entities being merged</nz-form-label>
      <div class="entity-merger-questions">
        <ng-container formArrayName="legalEntitiesQuestionsFormArray">
          <div *ngFor="let proposedSlateForm of legalEntitiesQuestionsFormArray.controls; let i = index">
            <div [formGroupName]="i">
              <nz-collapse [nzGhost]="true">
                <nz-collapse-panel [nzHeader]="legalEntitiesQuestionsFormArray.at(i).value.companyName" [nzActive]="true">
                  <nz-form-item>
                    <nz-form-label [@formLabelAnimation]>Have active registrations in
                      other
                      states/countries?</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <nz-switch formControlName="activeRegistration"
                        (ngModelChange)="onChangeActiveReg(proposedSlateForm)"></nz-switch>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item *ngIf="legalEntitiesQuestionsFormArray.at(i).value.activeRegistration">
                    <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Comments</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <textarea nz-input placeholder="Active Registrations" formControlName="activeRegistrationComments"
                        [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label [@formLabelAnimation]>Have
                      subsidiaries?</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <nz-switch formControlName="subsidiaries"></nz-switch>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item *ngIf="legalEntitiesQuestionsFormArray.at(i).value.subsidiaries">
                    <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <textarea nz-input placeholder="Subsidiaries" formControlName="subsidiariesComments"
                        [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
                    </nz-form-control>
                  </nz-form-item>
                </nz-collapse-panel>
              </nz-collapse>
            </div>
          </div>
        </ng-container>
      </div>
      <nz-form-item *ngIf="showComments">
        <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Comments</nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <textarea nz-input placeholder="Comments" formControlName="comments"
            [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzSpan="11" nzOffset="1">
      <ng-container formArrayName="legalEntitiesFormArray">
        <div nz-row nzGutter="8" [nzAlign]="'middle'" *ngIf="legalEntitiesFormArray.controls.length > 0">
          <div nz-col nzSpan="1">
            <button nz-button nzType="primary" nzShape="circle" (click)="prevSlide()" [disabled]="current === 0"><span
                nz-icon nzType="left" nzTheme="outline" style="color: white;"></span></button>
          </div>
          <div nz-col nzSpan="19" nzOffset="1">
            <nz-carousel [nzEffect]="'scrollx'" [nzDots]="false" #carouselLegalEntity style="height: 350px;"
              [nzEnableSwipe]="false">
              <div nz-carousel-content *ngFor="let legalEntityForm of legalEntitiesFormArray.controls; let i = index">
                <nz-descriptions [nzColumn]="1">
                  <nz-descriptions-item
                    nzTitle="Company Code Number">{{legalEntityForm.get('ccn')?.value}}</nz-descriptions-item>
                  <nz-descriptions-item
                    nzTitle="Company Name">{{legalEntityForm.get('companyName')?.value}}</nz-descriptions-item>
                  <nz-descriptions-item
                    nzTitle="Jurisdiction">{{legalEntityForm.get('jurisdiction')?.value}}</nz-descriptions-item>
                  <nz-descriptions-item
                    nzTitle="Ownership Details">{{legalEntityForm.get('ownershipDetail')?.value}}</nz-descriptions-item>
                </nz-descriptions>
              </div>
            </nz-carousel>
          </div>
          <div nz-col nzSpan="1">
            <button nz-button nzType="primary" nzShape="circle" (click)="nextSlide()"
              [disabled]="current === legalEntitiesFormArray.length -1"><span nz-icon nzType="right" nzTheme="outline"
                style="color: white;"></span></button>
          </div>
          <div style="margin: 0 auto;color: #2199d1;">Merging Entity</div>
        </div>
        
      </ng-container>
    </div>
  </div>
</form>


<p><strong>Surviving Entity</strong></p>
<form nz-form [formGroup]="mergingSurviveEntitySelectionForm">
  <div nz-row nzGutter="8">
    <div nz-col nzSpan="11">
      <nz-form-item>
        <nz-form-label [@formLabelAnimation] nzTooltipIcon="info-circle" nzTooltipTitle="Comments will be required if Surviving Entity is N/A." >Legal Entity</nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <nz-select nzShowSearch [nzServerSearch]="true" nzPlaceHolder="Search by name. Select one or more."
            formControlName="legalEntity" [nzLoading]="nzSurvivingLoading" (ngModelChange)="onSurvivingChange($event)"
            (nzOnSearch)="onSurvivingSearchEvent($event)" [nzSuffixIcon]="suffixIconSearch1">
            <nz-option *ngFor="let entity of survivingEntities" [nzValue]="entity.companyName"
              [nzLabel]="entity.companyName"></nz-option>
          </nz-select>
          <ng-template #suffixIconSearch1>
            <span nz-icon nzType="search" nzTheme="outline" (click)="onSurvivingSearch()"></span>
          </ng-template>
          <nz-tag *ngFor="let selectedItem of selectedSurvivingEntity" nzMode="closeable" style="margin-top: 5px;"
            (nzOnClose)="onCloseTag1(selectedItem)">{{selectedItem}}</nz-tag>
        </nz-form-control>
      </nz-form-item>
      
      <div class="entity-merger-questions">
        <ng-container formArrayName="legalEntitiesQuestionsFormArray1">
          <div *ngFor="let proposedSlateForm of legalEntitiesQuestionsFormArray1.controls; let i = index">
            <div [formGroupName]="i">
              <nz-collapse [nzGhost]="true">
                <nz-collapse-panel [nzHeader]="legalEntitiesQuestionsFormArray1.at(i).value.companyName" [nzActive]="true">
                  <nz-form-item>
                    <nz-form-label [@formLabelAnimation]>Will the Surviving Entity change the name after the merger?</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <nz-switch formControlName="entityChangeName"
                        (ngModelChange)="onSurvivingChangeActiveReg(proposedSlateForm)"></nz-switch>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item *ngIf="legalEntitiesQuestionsFormArray1.at(i).value.entityChangeName">
                    <nz-form-label [@formLabelAnimation]><label class="mandate">*</label>Comments</nz-form-label>
                    <nz-form-control [@formControlAnimation]>
                      <textarea nz-input placeholder="Change name after merger" formControlName="entityChangeNameComments"
                        [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
                    </nz-form-control>
                  </nz-form-item>
                </nz-collapse-panel>
              </nz-collapse>
            </div>
          </div>
        </ng-container>
      </div>
      <nz-form-item *ngIf="showSurvivingComments">
        <nz-form-label [@formLabelAnimation]><label class="mandate">*</label> Comments</nz-form-label>
        <nz-form-control [@formControlAnimation]>
          <textarea nz-input placeholder="Comments" formControlName="comments"
            [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col nzSpan="11" nzOffset="1">
      <ng-container formArrayName="legalEntitiesFormArray1">
        <div nz-row nzGutter="8" [nzAlign]="'middle'" *ngIf="legalEntitiesFormArray1.controls.length > 0">
          <div nz-col nzSpan="1">
            <button nz-button nzType="primary" nzShape="circle" (click)="prevSurvivingSlide()"
              [disabled]="scurrent === 0"><span nz-icon nzType="left" nzTheme="outline"
                style="color: white;"></span></button>
          </div>
          <div nz-col nzSpan="19" nzOffset="1">
            <nz-carousel [nzEffect]="'scrollx'" [nzDots]="false" #carouselSurviveLegalEntity style="height: 350px;"
              [nzEnableSwipe]="false">
              <div nz-carousel-content *ngFor="let legalEntityForm of legalEntitiesFormArray1.controls; let i = index">
                <nz-descriptions [nzColumn]="1">
                  <nz-descriptions-item
                    nzTitle="Company Code Number">{{legalEntityForm.get('ccn')?.value}}</nz-descriptions-item>
                  <nz-descriptions-item
                    nzTitle="Company Name">{{legalEntityForm.get('companyName')?.value}}</nz-descriptions-item>
                  <nz-descriptions-item
                    nzTitle="Jurisdiction">{{legalEntityForm.get('jurisdiction')?.value}}</nz-descriptions-item>
                  <nz-descriptions-item
                    nzTitle="Ownership Details">{{legalEntityForm.get('ownershipDetail')?.value}}</nz-descriptions-item>
                </nz-descriptions>
              </div>
            </nz-carousel>
          </div>
          <div nz-col nzSpan="1">
            <button nz-button nzType="primary" nzShape="circle" (click)="nextSurvivingSlide()"
              [disabled]="scurrent === legalEntitiesFormArray1.length -1"><span nz-icon nzType="right" nzTheme="outline"
                style="color: white;"></span></button>
          </div>
          <div style="margin: 0 auto;color: #2199d1;">Surviving Entity</div>
        </div>
        
      </ng-container>
    </div>
  </div>
</form>
