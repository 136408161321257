import { Injectable } from '@angular/core';
import { SessionStorageService } from './session_storage';
import { P30APIService } from './api';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, combineLatest, isEmpty, map, of } from 'rxjs';
import { IFCGroupsDTO } from '../interfaces/ifcReviewers';
import { IFCCOMPTROLLERS, IFCCreateSuccess, IFCDueDateUpdateError, IFCDueDateUpdateSuccess, IFCGOVERNANCE, IFCLEGAL, IFCLegalGroupName, IFCTAX, IFCTREASURY, LOCALCOMPTROLLERS, LocalIFCCreateSuccess, LOCALLEGAL, LOCALTAX, LOCALTREASURY, PROPOSAL_ID } from 'src/app/constants/constants';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { LocalIFCGroupsDTO } from '../interfaces/LocalIFCReviewer';


@Injectable({
  providedIn: 'root',
})
export class DataServiceIFCReviewers {
  ifcReviewersPayload$ = new BehaviorSubject<IFCGroupsDTO[]>([]);
  localReviewersPayload$ = new BehaviorSubject<IFCGroupsDTO[]>([]);
  isConcurDateEmpty$ = new BehaviorSubject<boolean>(true);
  isLocalConcurDateEmpty$ = new BehaviorSubject<boolean>(true);
  isRecordsToUpdate$ = new BehaviorSubject<boolean | undefined>(undefined);
  isLocalRecordsToUpdate$ = new BehaviorSubject<boolean | undefined>(undefined);
  isReviewSubmitClicked$ = new BehaviorSubject<boolean | undefined>(undefined);
  isActionCompletd!: boolean;
  isLocalActionCompletd!: boolean;

  constructor(
    private session: SessionStorageService,
    private apiService: P30APIService,
    private message: NzMessageService
  ) { }

  reviewersPayload(state: IFCGroupsDTO[]) {
    this.ifcReviewersPayload$.next(state);
  }

  ifcConcurDateValue(state: boolean) {
    this.isConcurDateEmpty$.next(state);
  }

  getIFCUpdateRecordsStatus(state: boolean | undefined) {
    this.isRecordsToUpdate$.next(state);
  }
  reviewNsubmitStatus(state: boolean | undefined) {
    this.isReviewSubmitClicked$.next(state);
  }

  reviewAndValidateIfcReviewers(form: UntypedFormGroup): Promise<boolean> {
    const control = form.controls["dueDate"];
    control.setValidators([Validators.required]);
    control.markAsDirty();
    control.updateValueAndValidity();
    return Promise.resolve(form.valid);
  }

  async defaultGroupMemberAPICall() {
    return this.apiService.getReviewers().pipe(
      map((data: any) => {
        if (data) return data;
      })
    );
  }

  async getIFCApprovals() {
    const proposal_ID = this.session.getItem(PROPOSAL_ID);
    return this.apiService.getApprovals(proposal_ID).pipe(
      map((approvals: any) => {
        if (approvals) return approvals;
      })
    );
  }

  async submitIfcForApproval(form: any) {
    this.isReviewSubmitClicked$.subscribe((reviewStatus: boolean | undefined) => {
      switch (reviewStatus) {
        case true: {
          this.isRecordsToUpdate$.subscribe((updateState: boolean | undefined) => {
            if (this.isActionCompletd) {
              this.isActionCompletd = false;
              let payload: IFCGroupsDTO[] = [];
              this.ifcReviewersPayload$.subscribe((value: IFCGroupsDTO[]) => {
                payload = value;
                return payload
              });
              switch (!isNaN(Number(payload[0].approvalsId))) {
                case true: {
                  this.updateDueDateForAllGroups();
                  this.getIFCUpdateRecordsStatus(true);
                  break;
                }
                case false: {
                  this.createIFCApproval();
                  this.getIFCUpdateRecordsStatus(true);
                  break;
                }
                case undefined: {
                  break;
                }
              }
            } else {
              return;
            }
          })
          break;
        }
        case false: {
          break;
        }
        case undefined: {
          break;
        }
      }
    })
  }

  async createIFCApproval() {
    let responseStatus: boolean;
    let payload: IFCGroupsDTO[] = [];
    let Proposal_ID = this.session.getItem(PROPOSAL_ID);
    this.ifcReviewersPayload$.subscribe((value: IFCGroupsDTO[]) => {
      payload = value;
    });
    await this.apiService.createApprovals(Proposal_ID, payload[0]);
    let legal = this.apiService.createApprovals(Proposal_ID, payload[0]);
    let comptrollers = this.apiService.createApprovals(Proposal_ID, payload[1]);
    let tax = this.apiService.createApprovals(Proposal_ID, payload[2]);
    let treasury = this.apiService.createApprovals(Proposal_ID, payload[3]);
    let governance = this.apiService.createApprovals(Proposal_ID, payload[4]);
    combineLatest([legal, comptrollers, tax, treasury, governance]).subscribe((result: any) => {
      this.isActionCompletd = false;
      responseStatus = result.every((res: any) => Number(res.status) == 200 || Number(res.status) == 201);
      if (responseStatus) {
        this.message.success(IFCCreateSuccess);
      } else {
        this.message.error(IFCDueDateUpdateError);
      }
    })
  }

  async updateDueDateForAllGroups() {
    let responseStatus: boolean;
    let payload: IFCGroupsDTO[] = [];
    this.ifcReviewersPayload$.subscribe((value: IFCGroupsDTO[]) => {
      payload = value;
      return payload
    });
    let proposal_ID = this.session.getItem(PROPOSAL_ID);
    let legal = await this.apiService.updateApprovals(proposal_ID, Number(payload[0]?.approvalsId), payload[0]);
    let comptrollers = await this.apiService.updateApprovals(proposal_ID, Number(payload[1]?.approvalsId), payload[1]);
    let tax = await this.apiService.updateApprovals(proposal_ID, Number(payload[2]?.approvalsId), payload[2]);
    let treasury = await this.apiService.updateApprovals(proposal_ID, Number(payload[3]?.approvalsId), payload[3]);
    let governance = await this.apiService.updateApprovals(proposal_ID, Number(payload[4]?.approvalsId), payload[4]);
    combineLatest([legal, comptrollers, tax, treasury, governance]).subscribe((result: any) => {
      this.isActionCompletd = false;
      responseStatus = result.every((res: any) => Number(res.status) == 200 || Number(res.status) == 201);
      if (responseStatus) {
        this.message.success(IFCDueDateUpdateSuccess);
      } else {
        this.message.error(IFCDueDateUpdateError);
      }
    })
  }

  updateIFCApproval(type: string) {
    let payload: IFCGroupsDTO[] = [];
    let Proposal_ID = this.session.getItem(PROPOSAL_ID);
    this.ifcReviewersPayload$.subscribe((value: IFCGroupsDTO[]) => {
      payload = value;
    });
    switch (type) {
      case IFCLEGAL: {
        let payload_data: IFCGroupsDTO = payload[0]
        payload_data.status = null;
        payload_data.comment = '';
        this.apiService.updateApprovals(Proposal_ID, Number(payload[0]?.approvalsId), payload_data).subscribe(legal => {
          if (legal.body) {
            console.log("this is legal response => ", legal);
          }
        });
        break;
      }
      case IFCCOMPTROLLERS: {
        let payload_data: IFCGroupsDTO = payload[1]
        payload_data.status = null;
        payload_data.comment = '';
        this.apiService.updateApprovals(Proposal_ID, Number(payload[1]?.approvalsId), payload_data).subscribe(comptroller => {
          if (comptroller.body) {
            console.log("this is comptroller response => ", comptroller);
          }
        });
        break;
      }
      case IFCTAX: {
        let payload_data: IFCGroupsDTO = payload[2]
        payload_data.status = null;
        payload_data.comment = '';
        this.apiService.updateApprovals(Proposal_ID, Number(payload[2]?.approvalsId), payload_data).subscribe(tax => {
          if (tax.body) {
            console.log("this is tax response => ", tax);
          }
        });
        break;
      }
      case IFCTREASURY: {
        let payload_data: IFCGroupsDTO = payload[3]
        payload_data.status = null;
        payload_data.comment = '';
        this.apiService.updateApprovals(Proposal_ID, Number(payload[3]?.approvalsId), payload_data).subscribe(treasury => {
          if (treasury.body) {
            console.log("this is treasury response => ", treasury);
          }
        });
        break;
      }
      case IFCGOVERNANCE: {
        let payload_data: IFCGroupsDTO = payload[4]
        payload_data.status = null;
        payload_data.comment = '';
        this.apiService.updateApprovals(Proposal_ID, Number(payload[4]?.approvalsId), payload_data).subscribe(governance => {
          if (governance.body) {
            console.log("this is governance response => ", governance);
          }
        });
        break;
      }
    }

  }

  //#region For Local Reviewver Role
  localReviewersPayload(state: IFCGroupsDTO[]) {
    this.localReviewersPayload$.next(state);
  }

  localConcurDateValue(state: boolean) {
    this.isLocalConcurDateEmpty$.next(state);
  }

  getLocalUpdateRecordsStatus(state: boolean | undefined) {
    this.isLocalRecordsToUpdate$.next(state);
  }

  reviewAndValidateLocalReviewers() {
    let payload: LocalIFCGroupsDTO[] = [];
    this.localReviewersPayload$.subscribe((value: LocalIFCGroupsDTO[]) => {
      payload = value;
    });
    if (payload.length > 0) {
      //payload.filter(x=>x.approverEmail !="" && x.dueDate !="").length>0
      if (payload.filter(x=>x.approverEmail !="" && x.dueDate !="").length==4)
        return true;
      else
        return false;
    }
    else {
      if (this.session.getItem("userRole").roleTitle == "Requestor")
        return true;
      return false;
    }


  }

  async defaultLocalGroupMemberAPICall() {
    return this.apiService.getReviewers().pipe(
      map((data: any) => {
        if (data) return data;
      })
    );
  }

  async getLocalApprovals() {
    const proposal_ID = this.session.getItem(PROPOSAL_ID);
    return this.apiService.getApprovals(proposal_ID).pipe(
      map((approvals: any) => {
        if (approvals) return approvals;
      })
    );
  }

  async submitLocalForApproval(form: any) {
    this.isReviewSubmitClicked$.subscribe((reviewStatus: boolean | undefined) => {
      switch (reviewStatus) {
        case true: {
          this.isLocalRecordsToUpdate$.subscribe((updateState: boolean | undefined) => {
            if (this.isLocalActionCompletd) {
              this.isLocalActionCompletd = false;
              let localpayload: LocalIFCGroupsDTO[] = [];
              this.localReviewersPayload$.subscribe((value: LocalIFCGroupsDTO[]) => {
                localpayload = value;
                return localpayload
              });
              switch (!isNaN(Number(localpayload[0].approvalsId))) {
                case true: {
                  this.updateDueDateForAllLocalGroups();
                  this.getLocalUpdateRecordsStatus(true);
                  break;
                }
                case false: {
                  this.createLocalApproval();
                  this.getLocalUpdateRecordsStatus(true);
                  break;
                }
                case undefined: {
                  break;
                }
              }
            } else {
              return;
            }
          })
          break;
        }
        case false: {
          break;
        }
        case undefined: {
          break;
        }
      }
    })
  }

  async createLocalApproval() {
    let responseStatus: boolean;
    let payload: LocalIFCGroupsDTO[] = [];
    let Proposal_ID = this.session.getItem(PROPOSAL_ID);
    this.localReviewersPayload$.subscribe((value: LocalIFCGroupsDTO[]) => {
      payload = value;
    });

    let localLegal = payload[0].approverEmail.length > 0 ? this.apiService.createApprovals(Proposal_ID, payload[0]) : of('Dummy Parameter');

    let localComptrollers = payload[1].approverEmail.length > 0 ? this.apiService.createApprovals(Proposal_ID, payload[1]): of('Dummy Parameter');;
    let localTax = payload[2].approverEmail.length > 0 ? this.apiService.createApprovals(Proposal_ID, payload[2]) : of('Dummy Parameter');
    let localTreasury = payload[3].approverEmail.length > 0 ? this.apiService.createApprovals(Proposal_ID, payload[3]): of('Dummy Parameter');;
    //let governance = this.apiService.createApprovals(Proposal_ID, payload[4]);
    combineLatest([localLegal, localComptrollers, localTax, localTreasury]).subscribe((result: any) => {
      this.isLocalActionCompletd = false;
      responseStatus = result.every((res: any) => Number(res.status) == 200 || Number(res.status) == 201);
      if (responseStatus) {
        this.message.success(LocalIFCCreateSuccess);
      } else {
        this.message.error(IFCDueDateUpdateError);
      }
    })
  }

  async updateDueDateForAllLocalGroups() {
    let responseStatus: boolean;
    let payload: LocalIFCGroupsDTO[] = [];
    this.localReviewersPayload$.subscribe((value: LocalIFCGroupsDTO[]) => {
      payload = value;
      return payload
    });
    let proposal_ID = this.session.getItem(PROPOSAL_ID);
    let localLegal = payload[0].approverEmail.length > 0 ? this.apiService.updateApprovals(proposal_ID, Number(payload[0]?.approvalsId), payload[0]) : of('Dummy Parameter');
    let localComptrollers = payload[1].approverEmail.length > 0 ? this.apiService.updateApprovals(proposal_ID, Number(payload[1]?.approvalsId), payload[1]) : of('Dummy Parameter');
    let localTax = payload[2].approverEmail.length > 0 ? this.apiService.updateApprovals(proposal_ID, Number(payload[2]?.approvalsId), payload[2]) : of('Dummy Parameter');
    let localTreasury = payload[3].approverEmail.length > 0 ? this.apiService.updateApprovals(proposal_ID, Number(payload[3]?.approvalsId), payload[3]) : of('Dummy Parameter');
    combineLatest([localLegal, localComptrollers, localTax, localTreasury]).subscribe((result: any) => {
      this.isLocalActionCompletd = false;
      responseStatus = result.every((res: any) => Number(res.status) == 200 || Number(res.status) == 201);
      if (responseStatus) {
        this.message.success(IFCDueDateUpdateSuccess);
      } else {
        this.message.error(IFCDueDateUpdateError);
      }
    });
  }

  updateLocalApproval(type: string) {
    let payload: LocalIFCGroupsDTO[] = [];
    let Proposal_ID = this.session.getItem(PROPOSAL_ID);
    this.ifcReviewersPayload$.subscribe((value: LocalIFCGroupsDTO[]) => {
      payload = value;
    });
    switch (type) {
      case LOCALLEGAL: {
        let payload_data: LocalIFCGroupsDTO = payload[0]
        payload_data.status = null;
        payload_data.comment = '';
        this.apiService.updateApprovals(Proposal_ID, Number(payload[0]?.approvalsId), payload_data).subscribe(legal => {
          if (legal.body) {
            console.log("this is legal response => ", legal);
          }
        });
        break;
      }
      case LOCALCOMPTROLLERS: {
        let payload_data: LocalIFCGroupsDTO = payload[1]
        payload_data.status = null;
        payload_data.comment = '';
        this.apiService.updateApprovals(Proposal_ID, Number(payload[1]?.approvalsId), payload_data).subscribe(comptroller => {
          if (comptroller.body) {
            console.log("this is comptroller response => ", comptroller);
          }
        });
        break;
      }
      case LOCALTAX: {
        let payload_data: LocalIFCGroupsDTO = payload[2]
        payload_data.status = null;
        payload_data.comment = '';
        this.apiService.updateApprovals(Proposal_ID, Number(payload[2]?.approvalsId), payload_data).subscribe(tax => {
          if (tax.body) {
            console.log("this is tax response => ", tax);
          }
        });
        break;
      }
      case LOCALTREASURY: {
        let payload_data: LocalIFCGroupsDTO = payload[3]
        payload_data.status = null;
        payload_data.comment = '';
        this.apiService.updateApprovals(Proposal_ID, Number(payload[3]?.approvalsId), payload_data).subscribe(treasury => {
          if (treasury.body) {
            console.log("this is treasury response => ", treasury);
          }
        });
        break;
      }
    }

  }
  //#endregion

}
