<div class="content-proposal-review">
    <nz-collapse [nzGhost]="true">
      <nz-collapse-panel
        nzHeader="General Details"
        nzDisabled="false"
        [nzExpandedIcon]="collapseIcon"
      >
        <div class="general-details">
          <form
            nz-form
            class="request-details-form"
            [formGroup]="generalDetailsForm"
          >
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]>Requested For</nz-form-label>
              <nz-form-control [@formControlAnimation]>
                <input formControlName="requestedFor" type="text" nz-input />
              </nz-form-control>
            </nz-form-item>
            @if(requestStateName !== 'draft'){
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]>Requested By</nz-form-label>
              <nz-form-control [@formControlAnimation]>
                <input formControlName="requestedBy" type="text" nz-input />
              </nz-form-control>
            </nz-form-item>
            }
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]>Request Title</nz-form-label>
              <nz-form-control [@formControlAnimation]>
                <input formControlName="requestTitle" type="text" nz-input />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]>Proposal Id</nz-form-label>
              <nz-form-control [@formControlAnimation]>
                <input
                  formControlName="proposalId"
                  type="text"
                  nz-input
                  [disabled]="true"
                />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]
                >Business Unit Segment</nz-form-label
              >
              <nz-form-control [@formControlAnimation]>
                <input
                  formControlName="businessSegmentUnit"
                  type="text"
                  nz-input
                />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]>Region</nz-form-label>
              <nz-form-control [@formControlAnimation]>
                <input formControlName="region" type="text" nz-input />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]>OPCO</nz-form-label>
              <nz-form-control [@formControlAnimation]>
                  <input formControlName="opco" type="text" nz-input />
              </nz-form-control>
          </nz-form-item>
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]>Urgent</nz-form-label>
              <nz-form-control [@formControlAnimation]>
                <nz-switch formControlName="urgent"></nz-switch>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]
                >Urgency Explanation</nz-form-label
              >
              <nz-form-control [@formControlAnimation]>
                <textarea
                  nz-input
                  formControlName="urgencyExplanation"
                  [nzAutosize]="{ minRows: 8, maxRows: 10 }"
                ></textarea>
              </nz-form-control>
            </nz-form-item>
          </form>
        </div>
      </nz-collapse-panel>
      <nz-collapse-panel
        nzHeader="Proposal Details"
        nzDisabled="false"
        [nzExpandedIcon]="collapseIcon"
      >
        <p><strong>Legal Entity</strong></p>
        <div class="entity-selection">
          <form
            nz-form
            [formGroup]="legalEntityOthersForm"
            *ngIf="showLegalEntityNA"
          >
            <div>
              <nz-form-item>
                <nz-form-label [@formLabelAnimation]>Legal Entity</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                  <nz-select formControlName="entityName">
                    <nz-option nzValue="NA" nzLabel="NA"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                  <textarea
                    nz-input
                    formControlName="entityComments"
                    [nzAutosize]="{ minRows: 5, maxRows: 10 }"
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label [@formLabelAnimation]>
                  Summary of Legal Entity(ies) activites
                </nz-form-label>
                <nz-form-control [@formControlAnimation]>
                  <textarea
                    nz-input
                    formControlName="summaryLegalActivities"
                    [nzAutosize]="{ minRows: 5, maxRows: 10 }"
                  ></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
          </form>
        </div>
        <div *ngIf="!showLegalEntityNA">
          <nz-collapse
            *ngFor="let legalEntity of selectedLegalEntities; let i = index"
            [nzGhost]="true"
          >
            <nz-collapse-panel
              nzHeader="Legal Entity {{ legalEntity.ccn }}"
              nzDisabled="false"
              [nzExpandedIcon]="collapseIcon"
            >
              <div class="legal-entity">
                <p class="paragraph">
                  Legal Entity Name:&nbsp;<span>{{ legalEntity.ccn }}</span>
                </p>
                <p class="paragraph">
                  Company Code Number:&nbsp;<span>{{ legalEntity.ccn }}</span>
                </p>
                <p class="paragraph">
                  Company Name:&nbsp;<span>{{ legalEntity.companyName }}</span>
                </p>
                <p class="paragraph">
                  Jurisdiction:&nbsp;<span>{{ legalEntity.jurisdiction }}</span>
                </p>
                <p class="paragraph">
                  Ownership Details:&nbsp;
                  <span>
                    {{ legalEntity.ownershipDetail }}
                  </span>
                </p>
                <div *ngIf="legalEntity.summaryId">
                  <p class="paragraph">
                    Summary of Legal Entity(ties) activities:&nbsp;
                    <span>
                      {{ legalEntity.summaryofLegalEntitiesActivities }}
                    </span>
                  </p>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
        <p><strong>Proposal Overview</strong></p>
        <div class="proposal-overview">
          <form nz-form [formGroup]="OthersProposalOverviewForm">
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]>
                Overview of Proposal
                </nz-form-label>
              <nz-form-control [@formControlAnimation]>
                <textarea
                  nz-input
                  formControlName="overviewOfProposal"
                  [nzAutosize]="{ minRows: 5, maxRows: 10 }"
                ></textarea>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]>
                Business Reason for Proposal
              </nz-form-label>
              <nz-form-control [@formControlAnimation]>
                <textarea
                  nz-input
                  formControlName="businessReasonForProposal"
                  [nzAutosize]="{ minRows: 5, maxRows: 10 }"
                ></textarea>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]>
                Target Effective Date
              </nz-form-label>
              <nz-form-control [@formControlAnimation]>
                <nz-date-picker
                  formControlName="targetEffectiveDate"
                  style="width: 100%"
                ></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </form>
        </div>
        <p><strong>Attachments</strong></p>
        <div class="attachments">
          <div *ngFor="let file of files">
            <a [href]="file.filePath">
              <span nz-icon nzType="paper-clip" nzTheme="outline"></span>&nbsp;
              {{ file.fileName }}
            </a>
          </div>
          @if(isLocalReviewer){
          <p class="localAttachments"><strong>Local IFC Reviewer Attachments</strong></p>

          <app-file-upload [proposalId]="proposalId" [islocalReviewer]="isLocalReviewer"></app-file-upload>
          }
        </div>
      </nz-collapse-panel>
      <nz-collapse-panel nzHeader="Assignments"
                         nzDisabled="false"
                         [nzExpandedIcon]="collapseIcon">
        <div class="assignment">
          <p><strong>General</strong></p>
          <form nz-form [formGroup]="contactsForm">
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]>Coordinator</nz-form-label>
              <nz-form-control [@formControlAnimation]>
                <textarea nz-input
                          formControlName="coordinator"
                          [nzAutosize]="{ minRows: 2, maxRows: 10 }"></textarea>
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]>
                Additional CC's For Notifications
              </nz-form-label>
              <nz-form-control [@formControlAnimation]>
                <textarea nz-input
                          formControlName="additionalCcsForNotifications"
                          [nzAutosize]="{ minRows: 2, maxRows: 10 }"></textarea>
              </nz-form-control>
            </nz-form-item>
            @if(requestStateName !== 'draft') {
            <nz-form-item>
              <nz-form-label [@formLabelAnimation]>IFC Required?</nz-form-label>
              <nz-form-control [@formControlAnimation]>
                <nz-switch formControlName="ifcRequired"></nz-switch>
              </nz-form-control>
            </nz-form-item>
            }
          </form>
        </div>
        <!-- IFC Local Reviewers -->
        <div class="assignment" *ngIf="requestStateName !== 'draft' && ifcRequired == true">
          <p><strong>IFC Local Reviewers</strong></p>
          <app-local-reviewer [reviewComponent]="isReviewComponent"
                              [isIFCRequired]="ifcRequired"></app-local-reviewer>
        </div>
        <!-- IFC Reviewers -->
        <div class="assignment"
             *ngIf="requestStateName !== 'draft' && ifcRequired == true">
          <p><strong>IFC Reviewers</strong></p>
          <app-ifc-reviewer [reviewComponent]="isReviewComponent"
                            [isIFCRequired]="ifcRequired"></app-ifc-reviewer>
        </div>
        <!-- comments -->
        <div class="assignment">
          <p><strong>Comments</strong></p>
          <app-comments [reviewComponent]="isReviewComponent"></app-comments>
        </div>
      </nz-collapse-panel>
      <nz-collapse-panel
        nzHeader="History"
        nzDisabled="false"
        [nzExpandedIcon]="collapseIcon"
      >
        <div nz-row nzGutter="8" *ngFor="let item of histories">
          @for(role of userRoles;track role.userRoleId){
          @if(item.action.includes('Endorsed') && role.userRoleId ==
          item.userRoleId){
          <label style="margin-left: 30px">
            {{ item.action }} on <strong>{{ item.date }}</strong>
          </label>
          }@else if(role.userRoleId == item.userRoleId) {
          <label style="margin-left: 30px">
            {{ item.action }} by
            <strong>{{ role.roleTitle }} {{ item.user }}</strong> on
            <strong>{{ item.date }}</strong>
          </label>
          } }
        </div>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
