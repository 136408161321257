<nz-tabset (nzSelectChange)="getCurrentTab($event)">
  <nz-tab [nzTitle]="contact" nzTabPaneStyle>
    <ng-template nz-tab>
      <app-contacts [contactsForm]="contactsForm" (isLoader)="emitIsLoader($event)"
        (isContactFormValid)="emitIsContactFormValid($event)" (ifIFCRequired)="ifIFCRequired($event)"></app-contacts>
    </ng-template>
    <ng-template #contact>
      <button nz-button nzType="link" [ngClass]="contactsTabClass">
        {{ contacts }}
      </button>
    </ng-template>
  </nz-tab>
  
   
   
  <!-- local and ifc reviewers tab -->
  @if(isIfcRequired && roleTitle === coordinator && proposalStatusId != 1){
  
  <!-- local reviewer tab -->
  <nz-tab [nzTitle]="localReviewersTab" nzTabPaneStyle *ngIf="isRequestorCoordinatorRole">
    <ng-template nz-tab>
      <app-local-reviewer (localPayloadRecords)="localPayload($event)" [isIFCRequired]="isIfcRequired"
      (isLocalApprovalSubmitted)="isLocalApprovalSubmit($event)"
      (isResetEmailTrigger)="receiveResetEmailTrigger($event)"
      (isReminderEmailTrigger)="receiveReminderEmailTrigger($event)"
      (isLoader)="emitIsLoader($event)" [reviewComponent]="false" (localRevewerTabClass)="localRevewerTbClass($event)"></app-local-reviewer>
    </ng-template>
    <ng-template #localReviewersTab>
      <button nz-button nzType="link" [ngClass]="localRevewerTabClass">
        {{ localRevewer }}
      </button>
    </ng-template>
  </nz-tab>
   
  <!-- ifc reviewer tab -->
  <nz-tab [nzTitle]="reviewersTab">
    <ng-template nz-tab>
      <app-ifc-reviewer (ifcPayloadRecords)="ifcPayload($event)" [isIFCRequired]="isIfcRequired"
      (isApprovalSubmitted)="isApprovalSubmit($event)"
      (isResetEmailTrigger)="receiveResetEmailTrigger($event)"
      (isReminderEmailTrigger)="receiveReminderEmailTrigger($event)"
      (isLoader)="emitIsLoader($event)" [reviewComponent]="false" (ifcRevewerTabClass)="ifcRevewerTbClass($event)"></app-ifc-reviewer>
    </ng-template>
    <ng-template #reviewersTab>
      <button nz-button nzType="link" [ngClass]="ifcRevewerTabClass">
        {{ ifcRevewer }}
      </button>
    </ng-template>
  </nz-tab>
  }

  <!-- coordinator notes tab -->
  <nz-tab [nzTitle]="comment" nzTabPaneStyle *ngIf="isRequestorCoordinatorRole">
    <ng-template nz-tab>
      <app-comments (isLoader)="emitIsLoader($event)"></app-comments>
    </ng-template>
    <ng-template #comment>
      <button nz-button nzType="link" [ngClass]="commentsTabClass">
        {{ comments }}
      </button>
    </ng-template>
  </nz-tab>
</nz-tabset>
