<div class="content-proposal-review">
    <nz-collapse [nzGhost]="true">
        <nz-collapse-panel nzHeader="General Details" nzDisabled="false" [nzExpandedIcon]="collapseIcon">
            <div class="general-details">
                <form nz-form class="request-details-form" [formGroup]="generalDetailsForm">
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Requested For</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <input formControlName="requestedFor" type="text" nz-input />
                        </nz-form-control>
                    </nz-form-item>
                    @if(requestStateName !== 'draft'){
                      <nz-form-item>
                          <nz-form-label [@formLabelAnimation]>Requested By</nz-form-label>
                          <nz-form-control [@formControlAnimation]>
                              <input formControlName="requestedBy" type="text" nz-input />
                          </nz-form-control>
                      </nz-form-item>
                    }
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Request Title</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <input formControlName="requestTitle" type="text" nz-input />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Proposal Id</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <input formControlName="proposalId" type="text" nz-input [disabled]="true" />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Business Unit Segment</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <input formControlName="businessSegmentUnit" type="text" nz-input />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Region</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <input formControlName="region" type="text" nz-input />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>OPCO</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <input formControlName="opco" type="text" nz-input />
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Urgent</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <nz-switch formControlName="urgent"></nz-switch>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Urgency Explanation</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <textarea nz-input formControlName="urgencyExplanation"
                                [nzAutosize]="{ minRows: 8, maxRows: 10 }"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </div>
        </nz-collapse-panel>
        <nz-collapse-panel nzHeader="Proposal Details" nzDisabled="false" [nzExpandedIcon]="collapseIcon">
            <p><strong>Legal Entity</strong></p>
            <div class="entity-selection">
                <form nz-form [formGroup]="acquisitionEntitySelectionForm" *ngIf="showLegalEntityNA">
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Legal Entity</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <nz-select formControlName="legalEntity">
                                <nz-option nzValue="NA" nzLabel="NA"></nz-option>
                            </nz-select>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <textarea nz-input formControlName="comments"
                                [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
                        </nz-form-control>
                    </nz-form-item>

                </form>
            </div>
            <div *ngIf="!showLegalEntityNA">
                <nz-collapse *ngFor="let legalEntity of selectedLegalEntities; let i = index" [nzGhost]="true">
                    <nz-collapse-panel nzHeader="Legal Entity {{legalEntity.ccn}}" nzDisabled="false"
                        [nzExpandedIcon]="collapseIcon">
                        <div class="legal-entity">
                            <p class="paragraph">Legal Entity Name:&nbsp;<span>{{legalEntity.ccn}}</span></p>
                            <p class="paragraph">Company Code Number:&nbsp;<span>{{legalEntity.ccn}}</span></p>
                            <p class="paragraph">Company Name:&nbsp;<span>{{legalEntity.companyName}}</span></p>
                            <p class="paragraph">Jurisdiction:&nbsp;<span>{{legalEntity.jurisdiction}}</span></p>
                            <p class="paragraph">Ownership Details:&nbsp;<span>{{legalEntity.ownershipDetail}}</span>
                            </p>
                        </div>

                    </nz-collapse-panel>
                </nz-collapse>
            </div>
            <p><strong>Entity Questions</strong></p>
            <div class="asset" *ngIf="showAsset">
                <p><strong>Asset</strong></p>
                <div class="asset">
                    <form nz-form [formGroup]="acquisitionAssetForm">
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]>Seller Entity</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <input nz-input formControlName="sellerEntity" />
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]>Approximate Cost</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <input nz-input formControlName="approximateCost" />
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]>Description of Asset</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <textarea nz-input formControlName="assetDescription"
                                    [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
                            </nz-form-control>
                        </nz-form-item>
                    </form>
                </div>
            </div>
            <div *ngIf="showOwnsershipInterest">
                <p><strong>Ownership Interest</strong></p>
                <div class="ownsership-interest">
                    <form nz-form [formGroup]="acquisitionOwnershipInterestForm">
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]>Name of Entity Being Acquired</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <input nz-input formControlName="nameOfEntityBeingAcquired" />
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]>Jurisdiction</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <input nz-input formControlName="jurisdiction" />
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]>Have active registrations in other
                                states/countries?</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <nz-switch formControlName="haveActiveRegistrations"></nz-switch>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <textarea nz-input formControlName="haveActiveRegistrationsComments"
                                    [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]>
                              Amount of Initial Investment (Please note that any additional investments will require a separate Policy 161)
                            </nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <nz-switch formControlName="additionalContributions"></nz-switch>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <textarea nz-input formControlName="additionalContributionComments"
                                    [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                          <nz-form-label [@formLabelAnimation]>Cash/Non-Cash</nz-form-label>
                          <nz-form-control [@formControlAnimation]>
                            <input formControlName="investmentMethod" type="text" nz-input />
                          </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]>Have subsidiaries?</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <nz-switch formControlName="haveSubsidiaries"></nz-switch>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <textarea nz-input formControlName="haveSubsidiariesComments"
                                    [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]>Need bank accounts?</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <nz-switch formControlName="needBankAccounts"></nz-switch>
                            </nz-form-control>
                        </nz-form-item>
                        <nz-form-item>
                            <nz-form-label [@formLabelAnimation]>Comments</nz-form-label>
                            <nz-form-control [@formControlAnimation]>
                                <textarea nz-input formControlName="needBankAccountComments"
                                    [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
                            </nz-form-control>
                        </nz-form-item>
                    </form>
                </div>
            </div>
            <p><strong>Proposal Overview</strong></p>
            <div class="proposal-overview">
                <form nz-form [formGroup]="acquisitionProposalOverviewForm">
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Overview of Proposal</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <textarea nz-input formControlName="overviewOfProposal"
                                [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Business Reason for Proposal</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <textarea nz-input formControlName="businessReasonForProposal"
                                [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Target Signing Date</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <nz-date-picker formControlName="targetSigningDate" style="width: 100%"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Target Completion Date</nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <nz-date-picker formControlName="targetCompletionDate" style="width: 100%"></nz-date-picker>
                        </nz-form-control>
                    </nz-form-item>
                    <nz-form-item>
                        <nz-form-label [@formLabelAnimation]>Name of Shareholder(s) or Owner(s) and<br>
                            Proposed Voting and Capital Structure Percentages
                        </nz-form-label>
                        <nz-form-control [@formControlAnimation]>
                            <textarea nz-input formControlName="nameOfShareHolderAndMore"
                                [nzAutosize]="{ minRows: 5, maxRows: 10 }"></textarea>
                        </nz-form-control>
                    </nz-form-item>
                </form>
            </div>
            <p><strong>Proposed Slate</strong></p>
            <div class="propossed-slate">
                <form nz-form [formGroup]="acquisitionPropossedSlateForm">
                    <ng-container formArrayName="proposedSlates">
                        <div *ngFor="let proposedSlateForm of proposedSlates.controls; let i = index">
                            <div [formGroupName]="i">
                                <div nz-row nzGutter="8">
                                    <div nz-col nzSpan="12">
                                        <nz-form-item>
                                            <nz-form-label [@formLabelAnimation]>Name</nz-form-label>
                                            <nz-form-control [@formControlAnimation]>
                                                <input nz-input formControlName="name">
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                    <div nz-col nzSpan="12">
                                        <nz-form-item>
                                            <nz-form-label [@formLabelAnimation]>Title</nz-form-label>
                                            <nz-form-control [@formControlAnimation]>
                                                <input nz-input formControlName="title">
                                            </nz-form-control>
                                        </nz-form-item>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </form>
            </div>
            <p><strong>Attachments</strong></p>
            <div class="attachments">
              <div *ngFor="let file of files">
                <a [href]="file.filePath">
                  <span nz-icon nzType="paper-clip"
                        nzTheme="outline"></span>&nbsp;{{file.fileName}}
                </a>
              </div>
              @if(isLocalReviewer){
              <p class="localAttachments"><strong>Local IFC Reviewer Attachments</strong></p>

              <app-file-upload [proposalId]="proposalId" [islocalReviewer]="isLocalReviewer"></app-file-upload>
              }
            </div>
        </nz-collapse-panel>
        <nz-collapse-panel nzHeader="Assignments" nzDisabled="false" [nzExpandedIcon]="collapseIcon">
          <div class="assignment">
            <p><strong>General</strong></p>
            <form nz-form [formGroup]="contactsForm">
              <nz-form-item>
                <nz-form-label [@formLabelAnimation]>Coordinator</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                  <textarea nz-input formControlName="coordinator"
                            [nzAutosize]="{ minRows: 2, maxRows: 10 }"></textarea>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-label [@formLabelAnimation]>Additional CC's For Notifications</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                  <textarea nz-input formControlName="additionalCcsForNotifications"
                            [nzAutosize]="{ minRows: 2, maxRows: 10 }"></textarea>
                </nz-form-control>
              </nz-form-item>
              @if(requestStateName !== 'draft') {
              <nz-form-item>
                <nz-form-label [@formLabelAnimation]>IFC Required?</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                  <nz-switch formControlName="ifcRequired"></nz-switch>
                </nz-form-control>
              </nz-form-item>
              }
            </form>
          </div>
          <!-- IFC Local Reviewers -->
          <div class="assignment" *ngIf="requestStateName !== 'draft' && ifcRequired == true">
            <p><strong>IFC Local Reviewers</strong></p>
            <app-local-reviewer [reviewComponent]="isReviewComponent"
                                [isIFCRequired]="ifcRequired"></app-local-reviewer>
          </div>
          <!-- IFC Reviewers -->
          <div class="assignment" *ngIf="requestStateName !== 'draft' && ifcRequired == true">
            <p><strong>IFC Reviewers</strong></p>
            <app-ifc-reviewer [reviewComponent]="isReviewComponent" [isIFCRequired]="ifcRequired"></app-ifc-reviewer>
          </div>
          <!-- comments -->
          <div class="assignment">
            <p><strong>Comments</strong></p>
            <app-comments [reviewComponent]="isReviewComponent"></app-comments>
          </div>
        </nz-collapse-panel>
        <nz-collapse-panel nzHeader="History" nzDisabled="false" [nzExpandedIcon]="collapseIcon">
            <div nz-row nzGutter="8" *ngFor="let item of histories">
                @for(role of userRoles;track role.userRoleId){
                    @if(item.action.includes('Endorsed') && role.userRoleId == item.userRoleId){
                        <label style="margin-left:30px ;">
                            {{item.action}} on <strong>{{item.date}}</strong>
                        </label>
                    }@else if(role.userRoleId == item.userRoleId) {
                        <label style="margin-left:30px ;">
                            {{item.action}} by <strong >{{role.roleTitle}} {{item.user}}</strong> on <strong>{{item.date}}</strong>
                        </label>
                    }
                }
            </div>
        </nz-collapse-panel>
    </nz-collapse>
</div>
