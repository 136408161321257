<main class="container">
    <section id="column2">
        <np-menu [location]="NavigationLocation.Side" [navStartUrl]="'/3.html'"></np-menu>
    </section>
    <section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">
        <div class="loader-container" *ngIf="isLoader">
            <nz-spin nzTip="Loading..." style="position: fixed;left: 50%;top:50%;" [nzSize]="'large'"></nz-spin>
        </div>
        <app-page-header [displayStatus]="true" [currentStep]="currentStatusId"></app-page-header>
        <div *ngIf="submitRequest">
            <ng-lottie [options]="confetti" style="position: fixed;left: 45%;top:10%;"></ng-lottie>
        </div>
        <div class="div-content">
            <div nz-row nzGutter="8">
                <div nz-col nzSpan="4">
                    <nz-page-header [nzTitle]="ownership_restructuring"></nz-page-header>
                    <ng-template #ownership_restructuring>
                        <span class="custom-step-label">Ownership<br/> Restructuring</span>
                    </ng-template>
                    <nz-steps [nzLabelPlacement]="'vertical'" [nzDirection]="'vertical'" nzSize="small"
                        [nzCurrent]="index" class="ant-steps-cutom-icon" (nzIndexChange)="onIndexChange($event)">
                        <nz-step [nzTitle]="general" [nzIcon]="iconGeneral"
                            [nzDisabled]="stepGeneralDetailsDisable"></nz-step>
                        <nz-step [nzTitle]="proposal" [nzIcon]="iconProposal" [nzDisabled]="stepDisable"></nz-step>
                        <nz-step [nzTitle]="assignment" [nzIcon]="iconAssignment" [nzDisabled]="stepDisable"></nz-step>
                        <nz-step [nzTitle]="review" [nzIcon]="iconReview" [nzDisabled]="stepTitle[index] !== stepReview"></nz-step>
                        <nz-step [nzTitle]="submit" [nzIcon]="iconSubmit"
                            [nzDisabled]="stepTitle[index] !== stepSubmit"></nz-step>
                    </nz-steps>
                    <ng-template #general>
                        <span [ngClass]="stepGeneralDetailsClass">General Details</span>
                    </ng-template>
                    <ng-template #proposal>
                        <span [ngClass]="stepProposalDetailsClass">Proposal Details</span>
                    </ng-template>
                    <ng-template #assignment>
                        <span [ngClass]="stepAssignmentClass">Assignments</span>
                    </ng-template>
                    <ng-template #review>
                      @if(userRoleTitle === localIfcMember || userRoleTitle === ifcMember || userRoleTitle === coordinator){
                      <span class="custom-step-label">Review & Concur</span>
                      }@else{
                      <span class="custom-step-label">Review</span>
                      }

                    </ng-template>
                    <ng-template #submit>
                        <span class="custom-step-label">Submit</span>
                    </ng-template>
                    <ng-template #iconGeneral>
                        <span nz-icon>
                            <!-- icon666.com - MILLIONS vector ICONS FREE -->
                            <svg enable-background="new 0 0 52 60" viewBox="0 0 52 60"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="m4 4h11v2h-11z" />
                                <path
                                    d="m5 22h9c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1h-9c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1zm1-4h7v2h-7z" />
                                <path
                                    d="m18 22h21c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1h-21c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1zm1-4h19v2h-19z" />
                                <path
                                    d="m5 14h9c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1h-9c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1zm1-4h7v2h-7z" />
                                <path
                                    d="m18 14h21c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1h-21c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1zm1-4h19v2h-19z" />
                                <path d="m4 24h12v2h-12z" />
                                <path
                                    d="m49.768 20.435c-1.725-.601-3.614.32-4.212 2.047l-.76 2.19v.001l-.796 2.3v-25.973c0-.552-.448-1-1-1h-42c-.552 0-1 .448-1 1v58c0 .553.448 1 1 1h42c.552 0 1-.447 1-1v-11.753l7.065-20.41s0 0 0-.001.001-.001.001-.002l.745-2.176c.127-.353.189-.713.189-1.098 0-1.41-.898-2.666-2.232-3.125zm-1.656 1.948c.312-.153.667-.174 1.003-.057.529.182.885.678.885 1.234 0 .153-.022.287-.076.436l-.425 1.242-1.243-.428-1.243-.428.432-1.244c.116-.334.352-.602.667-.755zm-10.112 21.934-.582 1.683h-31.418v-16h32zm4 13.683h-40v-56h40v30.752l-2 5.782v-9.534c0-.552-.448-1-1-1h-34c-.552 0-1 .448-1 1v18c0 .553.448 1 1 1h31.727l-.753 2.184c-.062.179-.053.359-.016.531l-.018.004.927 4.4c.072.344.32.624.652.739.106.037.217.055.327.055.231 0 .459-.08.642-.233l3.447-2.889-.009-.011c.024-.02.052-.034.074-.056zm-3.739-5.969 1.039.359-.819.686zm2.411-1.283-2.481-.857 8.168-23.62 2.489.858z" />
                                <path d="m5 50h22v2h-22z" />
                            </svg>
                        </span>
                    </ng-template>
                    <ng-template #iconReview>
                        <span nz-icon>
                            <!-- icon666.com - MILLIONS vector ICONS FREE --><svg id="Capa_1"
                                enable-background="new 0 0 512 512" viewBox="0 0 512 512"
                                xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path
                                        d="m256 210.697 49.469 16.64-.538-52.189 31.111-41.905-49.801-15.616-30.241-42.539-30.241 42.538-49.801 15.616 31.111 41.905-.538 52.188zm-11.618-67.441 11.618-16.343 11.619 16.343 19.134 6-11.953 16.101.207 20.051-19.007-6.393-19.006 6.393.207-20.051-11.953-16.101z" />
                                    <path
                                        d="m409.687 153.687c0-84.743-68.944-153.687-153.687-153.687s-153.687 68.944-153.687 153.687c0 79.99 61.43 145.892 139.59 153.034v34.562h-37.255v119.366c0 28.315 23.037 51.351 51.352 51.351s51.352-23.036 51.352-51.352v-119.366h-35.42v-34.731c77.285-7.996 137.755-73.5 137.755-152.864zm-153.687 328.284c-11.758 0-21.323-9.566-21.323-21.323v-35.16h42.647v35.16c-.001 11.758-9.566 21.323-21.324 21.323zm21.323-86.511h-42.647v-24.15h42.647zm-144.981-241.773c0-68.185 55.473-123.658 123.658-123.658s123.658 55.473 123.658 123.658-55.473 123.658-123.658 123.658-123.658-55.473-123.658-123.658z" />
                                </g>
                            </svg>
                        </span>
                    </ng-template>
                    <ng-template #iconProposal>
                        <span nz-icon>
                            <!-- icon666.com - MILLIONS vector ICONS FREE --><svg xmlns="http://www.w3.org/2000/svg"
                                id="Icons" viewBox="0 0 60 60">
                                <path
                                    d="M2,60H38a2,2,0,0,0,2-2V56h2a2,2,0,0,0,2-2V38.838a2.778,2.778,0,0,0,.766-.848L59.489,12.212a3.813,3.813,0,0,0-1.418-5.218L54.8,5.134a3.813,3.813,0,0,0-5.221,1.413L44,16.32V2a2,2,0,0,0-2-2H6A2,2,0,0,0,4,2V4H2A2,2,0,0,0,0,6V58A2,2,0,0,0,2,60ZM47.611,14.031l1.343-2.351,6.435,3.678-1.343,2.351Zm1.119,2.943L39.061,34.087,36.87,32.835l9.749-17.067Zm-12.7,24.8.388-6.9,5.362,3.064Zm7.28-5.263L40.8,35.08l9.668-17.113,2.587,1.479ZM51.319,7.539a1.81,1.81,0,0,1,2.488-.671l3.275,1.864a1.811,1.811,0,0,1,.675,2.48l0,.006-1.373,2.4L49.946,9.943ZM6,2H42V19.821L34.85,32.34a2.975,2.975,0,0,0-.367,1.256L34.01,42H11a1,1,0,0,0,0,2H35.775a1.746,1.746,0,0,0,.967-.3L42,40.2V54H13V49a2,2,0,0,0-2-2H6Zm5,50.586L7.414,49H11ZM2,6H4V47.17A2.985,2.985,0,0,0,4.883,49.3l5.82,5.82A2.985,2.985,0,0,0,12.83,56H38v2H2Z" />
                                <path
                                    d="M11,20a1,1,0,0,0-2,0,5.009,5.009,0,0,0,4,4.9V26a1,1,0,0,0,2,0V24.9a5,5,0,0,0,0-9.8V9.184A3,3,0,0,1,17,12a1,1,0,0,0,2,0,5.009,5.009,0,0,0-4-4.9V6a1,1,0,0,0-2,0V7.1a5,5,0,0,0,0,9.8v5.917A3,3,0,0,1,11,20Zm6,0a3,3,0,0,1-2,2.816V17.184A3,3,0,0,1,17,20Zm-6-8a3,3,0,0,1,2-2.816v5.632A3,3,0,0,1,11,12Z" />
                                <path d="M23,9H37a1,1,0,0,0,0-2H23a1,1,0,0,0,0,2Z" />
                                <path d="M23,14H37a1,1,0,0,0,0-2H23a1,1,0,0,0,0,2Z" />
                                <path d="M23,19H37a1,1,0,0,0,0-2H23a1,1,0,0,0,0,2Z" />
                                <path d="M23,24H37a1,1,0,0,0,0-2H23a1,1,0,0,0,0,2Z" />
                                <path d="M23,29H33a1,1,0,0,0,0-2H23a1,1,0,0,0,0,2Z" />
                                <path d="M31,32H11a1,1,0,0,0,0,2H31a1,1,0,0,0,0-2Z" />
                                <path d="M31,37H11a1,1,0,0,0,0,2H31a1,1,0,0,0,0-2Z" />
                            </svg>
                        </span>
                    </ng-template>
                    <ng-template #iconSubmit>
                        <span nz-icon>
                            <svg viewBox="-16 0 512 512" xmlns="http://www.w3.org/2000/svg">
                                <path d="m120 332h90v30h-90zm0 0" />
                                <path d="m120 271h240v30h-240zm0 0" />
                                <path d="m120 211h240v30h-240zm0 0" />
                                <path d="m120 150h240v30h-240zm0 0" />
                                <path d="m240 90h90v30h-90zm0 0" />
                                <path
                                    d="m480 362h-60v-362h-261.214844l-98.785156 98.785156v263.214844h-60v150h480zm-30 30v30h-30v-30zm-60 30h-300v-302h90v-90h210zm-278.785156-332 38.785156-38.785156v38.785156zm-51.214844 302v30h-30v-30zm-30 90v-30h420v30zm0 0" />
                            </svg>
                        </span>
                    </ng-template>
                    <ng-template #iconAssignment>
                        <span nz-icon>
                            <!-- icon666.com - MILLIONS vector ICONS FREE -->
                            <svg xmlns="http://www.w3.org/2000/svg" id="Icons" viewBox="0 0 60 60">
                                <path
                                    d="M56.586,10.586A1.986,1.986,0,0,0,55.172,10h0a1.982,1.982,0,0,0-1.412.586L49,15.344V3a3,3,0,0,0-3-3H21.83A2.985,2.985,0,0,0,19.7.883L13.883,6.7A2.985,2.985,0,0,0,13,8.83V37.461l-2.073.9A3,3,0,0,0,8,36H3a3,3,0,0,0-3,3V53a3,3,0,0,0,3,3H8a2.994,2.994,0,0,0,2.98-2.807,1.906,1.906,0,0,1,.605.079l20.369,6.516a5.025,5.025,0,0,0,4.522-.848L57.8,41.844a3.175,3.175,0,0,0,.8-4.021A3.163,3.163,0,0,0,54.3,36.6L49,39.594V26.657L59.413,16.243a2,2,0,0,0,0-2.829ZM38.107,31.893l.982-2.942,1.96,1.96Zm4.79-1.961L40.069,27.1l9.446-9.446,2.828,2.828Zm8.032-13.689,1.414-1.414,2.828,2.828-1.414,1.414ZM20,3.414V6a1,1,0,0,1-1,1H16.414ZM9,53a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V39a1,1,0,0,1,1-1H8a1,1,0,0,1,1,1v.784c0,.023-.01.044-.01.067L9,52.371ZM55.277,38.343a1.166,1.166,0,0,1,1.576.447,1.173,1.173,0,0,1-.3,1.489L35.236,57.37a3.012,3.012,0,0,1-2.691.508L12.188,51.366a3.9,3.9,0,0,0-1.161-.176H11V40.5l4.748-2.052a7.986,7.986,0,0,1,3.944-.366l19.44,3.32c.021,0,.048.007.074.01l1.663.282a1.172,1.172,0,0,1,.981,1.143,1.144,1.144,0,0,1-.329.814A1.186,1.186,0,0,1,40.69,44H22.85a1,1,0,1,0,0,2h3.714l5.819,3.613a3,3,0,0,0,2.939.008ZM37.664,46l-3.32,1.876a.99.99,0,0,1-.947.015L30.354,46ZM47,40.724l-3.167,1.79a3.149,3.149,0,0,0-2.636-2.79l-1.71-.29-.072-.009-19.4-3.313a10.052,10.052,0,0,0-5,.48L15,36.6V9h4a3,3,0,0,0,3-3V2H46a1,1,0,0,1,1,1V17.344L37.942,26.4a1.011,1.011,0,0,0-.241.391L36.1,31.607a1.864,1.864,0,0,0-.074.393H19a1,1,0,0,0,0,2H37.82c.011,0,.019-.006.03-.006a1.826,1.826,0,0,0,.543-.089L43.209,32.3a1.011,1.011,0,0,0,.391-.241l3.4-3.4Zm9.586-24.481-2.829-2.828L55.172,12,58,14.828Z" />
                                <path d="M6,40H5a1,1,0,0,0,0,2H6a1,1,0,0,0,0-2Z" />
                                <path d="M6,50H5a1,1,0,0,0,0,2H6a1,1,0,0,0,0-2Z" />
                                <path d="M19,29H33a1,1,0,0,0,0-2H19a1,1,0,0,0,0,2Z" />
                                <path d="M19,24h8a1,1,0,0,0,0-2H19a1,1,0,0,0,0,2Z" />
                                <path d="M19,14H43a1,1,0,0,0,0-2H19a1,1,0,0,0,0,2Z" />
                                <path d="M37,22H31a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" />
                                <path d="M19,19h2a1,1,0,0,0,0-2H19a1,1,0,0,0,0,2Z" />
                                <path d="M42,17H25a1,1,0,0,0,0,2H42a1,1,0,0,0,0-2Z" />
                            </svg>
                        </span>
                    </ng-template>
                </div>
                <div nz-col nzSpan="20">
                    <div nz-row>
                        <div nz-col nzSpan="24" nzOffset="23" style="position: absolute;">
                            <button nz-button nzType="link" *ngIf="!stepDisable && currentStatusId==0"
                                (click)="showDeletePopup()" title="delete proposal">
                                <span nz-icon nzType="delete" nzTheme="outline" style="font-size:20px;">
                                </span>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="stepTitle[index] === stepGeneralDetails">
                        <div class="step-content">
                            <app-request-general-details (isLoader)="receiveLoaderEmitValue($event)"
                                (stepGeneralDetailsClass)="receiveStepGeneralDetailsClass($event)"
                                (isEnable)="receiveEnableEmitValue($event)" *ngIf="isDisplayGeneralDetails"
                                #generalDetailsRef></app-request-general-details>
                        </div>
                    </div>
                    <div *ngIf="stepTitle[index] === stepProposalDetails">
                        <div class="step-content">
                            <app-ownership-restructuring-proposal-details (isLoader)="receiveLoaderEmitValue($event)"
                                [ownershipRestructuringProposalOverviewForm]="ownershipRestructuringProposalOverviewForm"
                                [entitySelectionTabClass]="entitySelectionTabClass"
                                [summaryTabClass]="summaryTabClass"
                                [attachmentTabClass]="attachmentTabClass"
                                [legalEntityOwnershipRestructuringForm]="legalEntityOwnershipRestructuringForm"
                                [selectedTabIndexValue]="selectedTabIndexValue"
                                (selectedTabIndexEmitValue)="receiveTabIndexEmitValue($event)">
                            </app-ownership-restructuring-proposal-details>
                        </div>
                    </div>
                    <div *ngIf="stepTitle[index] === stepAssignment">
                        <div class="step-content">
                            <app-assignments (isLoader)="receiveLoaderEmitValue($event)"
                                (isContactFormValid)="receiveContactFormEmitValue($event)" [contactsForm]="contactsForm"
                                [contactsTabClass]="contactsTabClass" (ifcReviewersPayload)="ifcReviewersPayload($event)"
                                (isIFCTabActive)="isIFCTabActive($event)" (isLIFCTabActive)="isLIFCTabActive($event)" (isApprovalSubmitted)="isApprovalSubmit($event)"
                                (isResetEmailTrigger)="receiveResetEmailTrigger($event)"
                                (isReminderEmailTrigger)="receiveReminderEmailTrigger($event)"
                                [ifcRevewerTabClass]="ifcRevewerTabClass"
                                (isLocalApprovalSubmitted)="isLocalApprovalSubmit($event)"
                                (localReviewersPayload)="localReviewersPayload($event)"
                                [localRevewerTabClass]="localRevewerTabClass">
                            </app-assignments>
                        </div>
                    </div>
                    <div *ngIf="stepTitle[index] === stepReview">
                        @if(submitRequestFailed){
                        <div class="step-content">
                            <ng-container>
                                <app-submit-failed (appError)="receiveFailErr($event)"></app-submit-failed>
                            </ng-container>
                        </div>
                        }@else if(!submitRequestFailed && displayReview){
                        <div class="step-content">
                            <app-ownership-restructuring-proposal-review
                                [ownershipRestructuringProposalOverviewForm]="ownershipRestructuringProposalOverviewForm"
                                [contactsForm]="contactsForm"
                                [legalEntityOwnershipRestructuringForm]="legalEntityOwnershipRestructuringForm"
                                ></app-ownership-restructuring-proposal-review>
                        </div>
                        }@else if(!displayReview && !submitRequestFailed){
                            <app-notes-submission [notes]="notes"
                            (enableReview)="receiveEnableReviewValue($event)"
                            (submitNote)="receiveSubmitNote($event)"
                            (ifcApprove)="receiveIFCApprove($event)"></app-notes-submission>
                        }
                    </div>
                    <div *ngIf="stepTitle[index] === stepSubmit">
                        <div class="step-content">
                            <app-submit-results [proposalId]="proposalId" [title]="proposalSubmitTitle"></app-submit-results>
                        </div>
                    </div>
                    <div class="steps-action">
                        <div nz-row nzGutter="8">
                            <div nz-col nzSpan="2">
                                @switch(userRoleTitle){
                                @case(requestor){
                                <button nz-button nzType="primary"
                                    *ngIf="(!stepDisable && stepTitle[index] !== stepSubmit && !enableClose) || (!submitRequestFailed && stepTitle[index] === stepReview && !enableClose)"
                                    (click)="updateProposal()" [disabled]="isEnable">
                                    <span>save for later</span>
                                </button>
                                <button nz-button nzType="primary"
                                    *ngIf="stepDisable && stepTitle[index] !== stepSubmit && stepTitle[index] !== stepReview"
                                    (click)="saveProposal()" [disabled]="isEnable">
                                    <span>save</span>
                                </button>
                                <button nz-button nzType="primary"
                                    *ngIf="stepTitle[index] !== stepSubmit && stepTitle[index] === stepReview && enableClose && displayReview"
                                    (click)="closeProposal()">
                                    <span>close</span>
                                </button>
                                }
                                @case(coordinator){
                                @if(currentStatusId+1 > 1 && stepTitle[index] === stepReview && displayReview){
                                <button nz-button nzType="primary"
                                    (click)="closeProposal()">
                                    <span>close</span>
                                </button>
                                }@else {
                                    @if(displayReview){
                                        <button nz-button nzType="primary"
                                        *ngIf="(!stepDisable && stepTitle[index] !== stepSubmit && !enableClose) || (!submitRequestFailed && stepTitle[index] === stepReview && !enableClose ) "
                                        (click)="updateProposal()" [disabled]="isEnable">
                                        <span>save for later</span>
                                    </button>
                                    }
                                <button nz-button nzType="primary"
                                    *ngIf="stepDisable && stepTitle[index] !== stepSubmit && stepTitle[index] !== stepReview"
                                    (click)="saveProposal()" [disabled]="isEnable">
                                    <span>save</span>
                                </button>
                                }


                                }
                                @case(ifcMember){
                                <button nz-button nzType="primary"
                                    *ngIf="(!stepDisable && stepTitle[index] !== stepSubmit && !enableClose) || (!submitRequestFailed && stepTitle[index] === stepReview && !enableClose)"
                                    (click)="updateProposal()" [disabled]="isEnable">
                                    <span>save for later</span>
                                </button>
                                <button nz-button nzType="primary"
                                    *ngIf="stepDisable && stepTitle[index] !== stepSubmit && stepTitle[index] !== stepReview"
                                    (click)="saveProposal()" [disabled]="isEnable">
                                    <span>save</span>
                                </button>
                                <button nz-button nzType="primary"
                                    *ngIf="stepTitle[index] !== stepSubmit && stepTitle[index] === stepReview && enableClose && displayReview"
                                    (click)="closeProposal()">
                                    <span>close</span>
                                </button>
                                }
                                @case(ifcSecretary){
                                    <button nz-button nzType="primary"
                                        *ngIf="(!stepDisable && stepTitle[index] !== stepSubmit && !enableClose) || (!submitRequestFailed && stepTitle[index] === stepReview && !enableClose)"
                                        (click)="updateProposal()" [disabled]="isEnable">
                                        <span>save for later</span>
                                    </button>
                                    <button nz-button nzType="primary"
                                        *ngIf="stepDisable && stepTitle[index] !== stepSubmit && stepTitle[index] !== stepReview"
                                        (click)="saveProposal()" [disabled]="isEnable">
                                        <span>save</span>
                                    </button>
                                    <button nz-button nzType="primary"
                                        *ngIf="stepTitle[index] !== stepSubmit && stepTitle[index] === stepReview && enableClose && displayReview"
                                        (click)="closeProposal()">
                                        <span>close</span>
                                    </button>
                                    }
                               @case(localIfcMember){
                                    <button nz-button nzType="primary"
                                    *ngIf="(!stepDisable && stepTitle[index] !== stepSubmit && !enableClose) || (!submitRequestFailed && stepTitle[index] === stepReview && !enableClose)"
                                    (click)="updateProposal()" [disabled]="isEnable">
                                    <span>save for later</span>
                                </button>
                                <button nz-button nzType="primary"
                                    *ngIf="stepDisable && stepTitle[index] !== stepSubmit && stepTitle[index] !== stepReview"
                                    (click)="saveProposal()" [disabled]="isEnable">
                                    <span>save</span>
                                </button>
                                <button nz-button nzType="primary"
                                    *ngIf="stepTitle[index] !== stepSubmit && stepTitle[index] === stepReview && enableClose && displayReview"
                                    (click)="closeProposal()">
                                    <span>close</span>
                                </button>
                                }
                                @default {
                                    <button nz-button nzType="primary"
                                    *ngIf="stepTitle[index] !== stepSubmit && stepTitle[index] === stepReview && enableClose && displayReview"
                                    (click)="closeProposal()">
                                    <span>close</span>
                                </button>
                                }
                                }

                            </div>
                            <div nz-col nzSpan="16" nzOffset="6">
                              <div class="nzButtonRight">
                                @switch(userRoleTitle){
                                @case(requestor) {
                                <button nz-button nzType="default" (click)="pre()"
                                        *ngIf="stepTitle[index] !== stepSubmit && !submitRequestSuccess  && !disableBack && !submitRequestFailed">
                                  <span>back</span>
                                </button>
                                <nz-divider nzType="vertical" *ngIf="!stepDisable"></nz-divider>
                                <button nz-button nzType="primary" (click)="next()" title="Save & Next"
                                        *ngIf="stepTitle[index] !== stepAssignment && stepTitle[index] !== stepReview && !stepDisable && stepTitle[index] !== stepSubmit"
                                        [disabled]="isEnable">
                                  <span>next</span>
                                </button>
                                <button nz-button nzType="primary"
                                        *ngIf="stepTitle[index] === stepAssignment && stepTitle[index] !== stepSubmit"
                                        (click)="reviewSubmit()">
                                  <span>review</span>
                                </button>
                                <button nz-button nzType="primary"
                                        *ngIf="stepTitle[index] === stepReview && stepTitle[index] !== stepSubmit &&  !submitRequestFailed && !disableSubmit"
                                        (click)="submitProposal()">
                                  <span>submit</span>
                                </button>
                                }
                                @case(coordinator){
                                <button nz-button nzType="default" (click)="pre()"
                                        *ngIf="stepTitle[index] !== stepSubmit && !submitRequestSuccess  && !disableBack && !submitRequestFailed && displayReview">
                                  <span>back</span>
                                </button>
                                <nz-divider nzType="vertical" *ngIf="!stepDisable"></nz-divider>
                                <button nz-button nzType="primary" (click)="next()" title="Save & Next"
                                        *ngIf="stepTitle[index] !== stepAssignment && stepTitle[index] !== stepReview && !stepDisable && stepTitle[index] !== stepSubmit"
                                        [disabled]="isEnable">
                                  <span>next</span>
                                </button>
                                <button nz-button nzType="primary"
                                        *ngIf="stepTitle[index] === stepAssignment && stepTitle[index] !== stepSubmit"
                                        (click)="reviewSubmit()">
                                  <span>review</span>
                                </button>
                                @if(stepTitle[index] === stepReview &&
                                displayReview && currentStatusId+1 != 1  && currentStatusId+1 != 2 && currentStatusId+1 != 6 ){
                                @if(currentStatusId+1 == 5 || currentStatusId+1 == 4){
                                <button nz-button nzType="default" class="concur-btn" (click)="ifcGovernanceComplete()">
                                  <span>Complete</span>
                                </button>
                                <nz-divider nzType="vertical"></nz-divider>
                                }
                                <button nz-button nzType="primary" class="withdraw-btn" (click)="ifcGovernanceWithdraw()">
                                  <span>withdraw</span>
                                </button>

                                }@else if(stepTitle[index] === stepReview &&
                                displayReview && currentStatusId+1 == 2 ){
                                <button nz-button nzType="default" class="concur-btn" (click)="ifcGovernanceConcur()">
                                  <span>concur</span>
                                </button>
                                <nz-divider nzType="vertical"></nz-divider>
                                <button nz-button nzType="default" class="decline-btn" (click)="returnToRequestor()">
                                  <span>return to requestor</span>
                                </button>
                                }@else {
                                <button nz-button nzType="primary"
                                        *ngIf="stepTitle[index] === stepReview && stepTitle[index] !== stepSubmit &&  !submitRequestFailed && !disableSubmit && displayReview"
                                        (click)="submitProposal()">
                                  <span>submit</span>
                                </button>
                                }
                                }
                                @case(ifcMember ){
                                @if(stepTitle[index] === stepReview &&
                                displayReview && currentStatusId+1 < 5 && !isApprovalConcurredOrDeclined){
                                <button nz-button nzType="default" class="concur-btn" (click)="concurProposal()">
                                  <span>concur</span>
                                </button>
                                <nz-divider nzType="vertical"></nz-divider>
                                <button nz-button nzType="default" class="decline-btn" (click)="declineProposal()">
                                  <span>decline</span>
                                </button>
                                }
                                @if(currentStatusId+1 == 1){
                                <button nz-button nzType="default" (click)="pre()"
                                        *ngIf="stepTitle[index] !== stepSubmit && !submitRequestSuccess  && !disableBack && !submitRequestFailed && displayReview">
                                  <span>back</span>
                                </button>
                                <nz-divider nzType="vertical" *ngIf="!stepDisable"></nz-divider>
                                <button nz-button nzType="primary" (click)="next()" title="Save & Next"
                                        *ngIf="stepTitle[index] !== stepAssignment && stepTitle[index] !== stepReview && !stepDisable && stepTitle[index] !== stepSubmit"
                                        [disabled]="isEnable">
                                  <span>next</span>
                                </button>
                                <button nz-button nzType="primary"
                                        *ngIf="stepTitle[index] === stepAssignment && stepTitle[index] !== stepSubmit"
                                        (click)="reviewSubmit()">
                                  <span>review</span>
                                </button>
                                }

                                }
                                @case(localIfcMember ){
                                @if(stepTitle[index] === stepReview &&
                                displayReview && currentStatusId+1 < 5 && !isApprovalConcurredOrDeclined){
                                <button nz-button nzType="default" class="concur-btn" (click)="concurProposal()">
                                  <span>concur</span>
                                </button>
                                <nz-divider nzType="vertical"></nz-divider>
                                <button nz-button nzType="default" class="decline-btn" (click)="declineProposal()">
                                  <span>decline</span>
                                </button>
                                }
                                @if(currentStatusId+1 == 1){
                                <button nz-button nzType="default" (click)="pre()"
                                        *ngIf="stepTitle[index] !== stepSubmit && !submitRequestSuccess  && !disableBack && !submitRequestFailed && displayReview">
                                  <span>back</span>
                                </button>
                                <nz-divider nzType="vertical" *ngIf="!stepDisable"></nz-divider>
                                <button nz-button nzType="primary" (click)="next()" title="Save & Next"
                                        *ngIf="stepTitle[index] !== stepAssignment && stepTitle[index] !== stepReview && !stepDisable && stepTitle[index] !== stepSubmit"
                                        [disabled]="isEnable">
                                  <span>next</span>
                                </button>
                                <button nz-button nzType="primary"
                                        *ngIf="stepTitle[index] === stepAssignment && stepTitle[index] !== stepSubmit"
                                        (click)="reviewSubmit()">
                                  <span>review</span>
                                </button>
                                }

                                }
                                @default {

                                }

                                }

                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
